import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'
import { database } from '../firebase'
import ConversationList from './ConversationList'
import Conversation from './Conversation'

class Conversations extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { user } = this.props
    const conversationRef = database.collection('questions')
    let conversationQuery = null
    if (user.isSuperAdmin()) {
      conversationQuery = conversationRef
    } else if (user.isSupplierAdmin()) {
      conversationQuery = conversationRef.where(
        'supplierUid',
        '==',
        user.supplierUid
      )
    }
    this.unsubscribe = conversationQuery
      .orderBy('editDateTime', 'desc')
      .onSnapshot(conversationsSnapshot => {
        const populatedConversationsPromises = conversationsSnapshot.docs.map(
          this.populateConversationDoc
        )
        Promise.all(populatedConversationsPromises).then(conversations => {
          this.setState({ conversations })
        })
      })
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  populateConversationDoc = conversationDoc => {
    const { productUid } = conversationDoc.data()
    const product = database
      .collection('products')
      .doc(productUid)
      .get()
    const conversation = {
      ...conversationDoc.data(),
      id: conversationDoc.id,
    }
    return product.then(
      productSnap => ({ ...conversation, product: productSnap.data() }),
      err => ({
        ...conversation,
        err,
      })
    )
  }

  render() {
    return (
      <div>
        <Typography variant="h4" color="primary">
          Produktfrågor
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Här kan du läsa och besvara frågor som inkommer från apotekspersonalen
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {this.state.conversations ? (
              <ConversationList
                conversations={this.state.conversations}
                onSelect={conversationUid => this.setState({ conversationUid })}
              />
            ) : (
              <LinearProgress />
            )}
          </Grid>
          <Grid item xs={8}>
            {this.state.conversationUid ? (
              <Conversation conversationId={this.state.conversationUid} />
            ) : null}
          </Grid>
        </Grid>
      </div>
    )
  }
}
export default connect(state => ({ user: state.user }))(Conversations)
