import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import ReactGA from 'react-ga'
import { LinearProgress } from '@material-ui/core'
import databaseManager from '../../database'
import TherapeuticInput from './TherapeuticInput'

import { serverTimestamp } from '../../firebase'

class TherapeuticAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
    }
    this.newProductDoc = databaseManager.queryTherapeutics().doc()
  }

  componentDidMount() {
    ReactGA.set({ dimension3: this.newProductDoc.id })
    ReactGA.event({
      category: 'Therapeutic',
      action: 'Created New',
      label: this.newProductDoc.id,
    })
  }

  componentWillUnmount() {
    ReactGA.set({ dimension3: null })
  }

  save = formValues => {
    const therapeuticData = {
      ...formValues,
      creationDateTime: serverTimestamp(),
      editDateTime: serverTimestamp(),
    }
    ReactGA.event({
      category: 'Therapeutic',
      action: 'Saved New',
      label: this.newProductDoc.id,
    })
    this.setState({ saving: true })
    databaseManager
      .queryTherapeutic(this.newProductDoc.id)
      .set(therapeuticData)
      .then(() => {
        this.setState({ saving: false })
        this.props.history.push(
          `/therapeutic/${this.newProductDoc.id}/add-part`
        )
      })
  }

  render() {
    const { handleSubmit, dirty, storagePath } = this.props
    const { saving } = this.state
    return (
      <div>
        <Typography variant="h4" color="primary" gutterBottom>
          Lägg till terapiområdesutbildning
        </Typography>
        <TherapeuticInput storagePath={storagePath} />
        {saving && <LinearProgress />}
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit(this.save)}
          disabled={saving || !dirty}
          style={{ marginTop: 16 }}
        >
          Spara och fortsätt
        </Button>
      </div>
    )
  }
}

const enhance = compose(
  withRouter,
  reduxForm({
    form: 'therapeutic',
  })
)

export default enhance(TherapeuticAdd)
