import React from 'react'
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  LinearProgress,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import { connect } from 'react-redux'
import databaseManager from '../database'

function PriceInformation(props) {
  const { supplier } = props

  const minTestsPriceLimit = (supplier && supplier.minTestsPriceLimit) || 10000
  const testsPriceLimit =
    supplier && Boolean(supplier.testsPriceLimit) && supplier.testsPriceLimit
  const [newTestsPriceLimit, setTestsPriceLimit] = React.useState(
    testsPriceLimit || minTestsPriceLimit
  )
  const [editing, setEditing] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const error =
    newTestsPriceLimit < minTestsPriceLimit
      ? `Minsta belopp är ${minTestsPriceLimit}`
      : false
  const savePriceLimit = () => {
    setSaving(true)
    databaseManager
      .getDatabase()
      .collection('suppliers')
      .doc(supplier.uid)
      .update({ testsPriceLimit: newTestsPriceLimit })
      .then(() => {
        setSaving(false)
        setEditing(false)
      })
  }

  if (!supplier) {
    return <LinearProgress />
  }

  return (
    <div style={{ maxWidth: 500 }}>
      <Typography variant="h4" color="primary">
        Priser
      </Typography>
      <Typography gutterBottom variant="h6">
        Betala endast för överförd kunskap
      </Typography>
      <Typography gutterBottom>
        Fakturering görs baserad på antal klick på utbildningar och godkänt
        genomförda kunskapstest.
      </Typography>
      <Paper style={{ marginTop: 8 }}>
        <Table>
          <caption>Alla priser och tak anges exkl. moms</caption>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">Apotekspersonal</TableCell>
              <TableCell align="right">Studenter</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Klick</TableCell>
              <TableCell align="right">10 kr</TableCell>
              <TableCell align="right">5 kr</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kunskapstest</TableCell>
              <TableCell align="right">60 kr</TableCell>
              <TableCell align="right">30 kr</TableCell>
            </TableRow>
          </TableBody>
          <TableRow>
            <TableCell>Maxtak per månad för kunskapstest </TableCell>
            <TableCell colSpan={2} align="right">
              {editing ? (
                <>
                  <TextField
                    value={newTestsPriceLimit}
                    onChange={event => setTestsPriceLimit(event.target.value)}
                    min={minTestsPriceLimit}
                    error={error}
                    helperText={error || ''}
                    disabled={saving}
                    type="number"
                    style={{ maxWidth: 100 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">kr</InputAdornment>
                      ),
                    }}
                  />
                  <IconButton
                    disabled={saving || Boolean(error)}
                    onClick={() => savePriceLimit()}
                  >
                    <SaveIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  {testsPriceLimit ? (
                    `${testsPriceLimit} kr`
                  ) : (
                    <i>Ej inställd</i>
                  )}{' '}
                  <IconButton onClick={() => setEditing(true)}>
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </TableCell>
          </TableRow>
        </Table>
      </Paper>
    </div>
  )
}
export default connect(state => ({
  supplier: state.supplier,
}))(PriceInformation)
