import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'
import ProductTable from './ProductTable'
import AddProductButton from './AddProductButton'

const columnsForFinishedSupplier = [
  'name',
  'qualityControlSummary',
  'published',
  'responsibleUserNames',
  'editDateTime',
]

const columnsForFinishedSupplierLegacy = [
  'name',
  'qualityControlSummary',
  'activeEducation',
  'activeEducationCount',
  'editDateTime',
]

const superAdminColumns = ['activeEducation', 'supplierName']

const columnsForPharmacyAdmin = ['name', 'editDateTime', 'supplierName']

const columnsForFinishedSuperAdmin = columnsForFinishedSupplier.concat(
  superAdminColumns
)

const columnsForUnfinishedSupplier = [
  'name',
  'progress',
  'editDateTime',
  'responsibleUserNames',
]

const columnsForUnfinishedSuperAdmin = columnsForUnfinishedSupplier.concat(
  superAdminColumns
)

function Products(props) {
  const [columns, setColumns] = React.useState({})
  const [enableGenerics, setEnableGenerics] = React.useState(false)

  const { user, supplier } = props
  React.useEffect(() => {
    if (user.isSupplierAdmin()) {
      if (supplier) {
        if (supplier.legacyPricing) {
          setColumns({
            finished: columnsForFinishedSupplierLegacy,
            unfinished: columnsForUnfinishedSupplier,
          })
        } else {
          setColumns({
            finished: columnsForFinishedSupplier,
            unfinished: columnsForUnfinishedSupplier,
          })
        }
        setEnableGenerics(Boolean(supplier.generics))
      }
    } else if (user.isSuperAdmin()) {
      setColumns({
        finished: columnsForFinishedSuperAdmin,
        unfinished: columnsForUnfinishedSuperAdmin,
      })
      setEnableGenerics(true)
    } else if (user.isPharmacyAdmin()) {
      setColumns({
        finished: columnsForPharmacyAdmin,
        unfinished: [],
      })
    }
  }, [user, supplier])

  const {
    initialized,
    productGroups: {
      trashedProducts,
      finishedProducts,
      unfinishedProducts,
      finishedGenerics,
      unfinishedGenerics,
    },
  } = props

  const writePermission = user ? user.isSupplierAdmin() : false
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h4" color="primary" gutterBottom>
            Produkter
          </Typography>
          {user && user.isPharmacyAdmin() && (
            <Typography variant="subtitle1" gutterBottom>
              Informationen här är den som granskats, godkänts och publicerats i
              appen.
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          {writePermission && <AddProductButton style={{ float: 'right' }} />}
        </Grid>
        {!initialized ? (
          <LinearProgress />
        ) : (
          <Fragment>
            {!(unfinishedProducts.length + finishedProducts.length) ? (
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  Det finns inga produkter ännu
                </Typography>
                {writePermission && <AddProductButton />}
              </Grid>
            ) : (
              <Fragment>
                {Boolean(unfinishedProducts.length) && columns.unfinished && (
                  <Grid item xs={12}>
                    <ProductTable
                      title="Utkast"
                      products={unfinishedProducts}
                      columnIds={columns.unfinished}
                      actionIds={writePermission ? ['edit'] : []}
                    />
                  </Grid>
                )}
                {Boolean(finishedProducts.length) && columns.finished && (
                  <Grid item xs={12}>
                    <ProductTable
                      title="Fullständiga"
                      products={finishedProducts}
                      columnIds={columns.finished}
                      actionIds={writePermission ? ['edit'] : []}
                    />
                  </Grid>
                )}
                {Boolean(trashedProducts.length) && columns.finished && (
                  <Grid item xs={12}>
                    <ProductTable
                      title="Papperskorgen"
                      products={trashedProducts}
                      columnIds={['name', 'published', 'editDateTime']}
                      actionIds={writePermission ? ['restore'] : []}
                    />
                  </Grid>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Grid>
      {enableGenerics && (
        <Grid container spacing={3} style={{ marginTop: 8 }}>
          <Grid item xs={6}>
            <Typography variant="h4" color="primary" gutterBottom>
              Generika
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {writePermission && (
              <Link to="generic-add" style={{ float: 'right' }}>
                <Button variant="contained" color="primary">
                  Lägg till generic
                </Button>
              </Link>
            )}
          </Grid>
          {unfinishedGenerics && (
            <Grid item xs={12}>
              {Boolean(unfinishedGenerics.length) && (
                <ProductTable
                  title="Utkast"
                  products={unfinishedGenerics}
                  writePermission={writePermission}
                  columnIds={columns.unfinished}
                  orderBy="editDateTime"
                />
              )}
            </Grid>
          )}
          {finishedGenerics && (
            <Grid item xs={12}>
              {Boolean(finishedGenerics.length) && (
                <ProductTable
                  title="Fullständiga"
                  products={finishedGenerics}
                  writePermission={writePermission}
                  columnIds={columns.finished}
                />
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  )
}
export default connect(state => ({
  productGroups: state.products.grouped,
  initialized: state.products.initialized,
  user: state.user,
  supplier: state.supplier,
}))(Products)
