import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  LinearProgress,
} from '@material-ui/core'
import {
  FormName,
  getFormValues,
  getFormSyncErrors,
  startSubmit,
  stopSubmit,
  setSubmitSucceeded,
} from 'redux-form'
import { connect } from 'react-redux'

const ErrorDialog = connect(
  (state, ownProps) => ({
    values: getFormValues(ownProps.form)(state),
    syncErrors: getFormSyncErrors(ownProps.form)(state),
  }),
  { startSubmit, stopSubmit, setSubmitSucceeded }
)(props => {
  const {
    submitting,
    submitFailed,
    description,
    errors,
    onSave,
    form,
    values,
    startSubmit,
    setSubmitSucceeded,
    syncErrors,
  } = props
  const handleClose = () => setSubmitSucceeded(form)
  return (
    <Dialog
      open={submitFailed}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Spara ofullständig information?
      </DialogTitle>
      <DialogContent>
        {submitting && <LinearProgress />}
        <DialogContentText id="alert-dialog-description">
          Det saknas information för att {description} ska bli komplett:
        </DialogContentText>
        {errors &&
          errors.map((error, index) => (
            <DialogContentText id={`alert-dialog-error-${index}`}>
              {error}
            </DialogContentText>
          ))}
      </DialogContent>
      <DialogActions>
        <Button disabled={submitting} onClick={handleClose}>
          Fortsätt redigera
        </Button>
        <Button
          onClick={() => {
            startSubmit(form)
            onSave(values, syncErrors).then(() => {
              setSubmitSucceeded(form)
            })
          }}
          disabled={submitting}
          color="primary"
          autoFocus
        >
          Spara ändå
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const WrappedErrorDialog = props => (
  <FormName>{({ form }) => <ErrorDialog form={form} {...props} />}</FormName>
)
export default WrappedErrorDialog
