import React, { Component, Fragment } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { connect } from 'react-redux'
import EditableFields, { getDataToUpdate } from './EditableFields'
import SummaryRecipients from './SummaryRecipients'
import Invites from './Invites'
import { loadInvites } from '../actions'

const contactPersonFields = [
  { label: 'Förnamn', prop: 'firstname' },
  { label: 'Efternamn', prop: 'lastname' },
  { label: 'Yrkestitel', prop: 'role' },
  { label: 'E-post', prop: 'email' },
  { label: 'Mobil', prop: 'mobile' },
  { label: 'Företagsadress', prop: 'address' },
]

class Organization extends Component {
  state = {
    contactPerson: {},
    name: '',
    allowCoins: false,
  }

  componentDidMount() {
    const { user, loadInvites } = this.props
    this.loadOrganizationData(user)
    if (user.isSupplierAdmin()) {
      loadInvites(user.supplierUid)
    }
  }

  getOrganizationDocRef = user => {
    if (user.isSupplierAdmin()) {
      return user.supplier
    }
    if (user.isPharmacyAdmin()) {
      return user.pharmacy
    }
    return null
  }

  loadOrganizationData = user => {
    const organizationDocRef = this.getOrganizationDocRef(user)
    if (organizationDocRef) {
      organizationDocRef.get().then(docSnap => {
        // const contactPerson = docSnap.get('contactPerson') || {}
        // const orgName = docSnap.get('name') || 'Okänd'
        this.setState(docSnap.data())
      })
    }
  }

  saveContactPerson = event => newData => {
    event.preventDefault()
    const { user } = this.props
    const organizationDocRef = this.getOrganizationDocRef(user)
    const userUpdateObj = getDataToUpdate(contactPersonFields, newData)
    return organizationDocRef
      .update({ contactPerson: userUpdateObj })
      .then(() => this.loadOrganizationData(user))
  }

  handleSwitchChange = name => event => {
    // this.setState({ [name]: event.target.checked });
    const { checked } = event.target
    const { user } = this.props
    const organizationDocRef = this.getOrganizationDocRef(user)
    return organizationDocRef
      .update({ [name]: checked })
      .then(() => this.loadOrganizationData(user))
  }

  render() {
    const { contactPerson, name, allowCoins } = this.state
    const { classifications, user } = this.props
    return (
      <Fragment>
        <Typography variant="h3" color="primary" gutterBottom>
          {name}
        </Typography>
        <Grid container direction="column" spacing={3}>
          {!contactPerson ? (
            <LinearProgress />
          ) : (
            <Grid item>
              <EditableFields
                title="Kontaktperson"
                fields={contactPersonFields}
                fieldsData={contactPerson}
                onSave={this.saveContactPerson}
              />
            </Grid>
          )}
          {user && user.isSupplierAdmin() && (
            <>
              <Grid item>
                <Invites />
              </Grid>
              <Grid item>
                <SummaryRecipients />
              </Grid>
              <Grid item>
                <Card>
                  <CardHeader title="Inställningar" />
                  <CardContent>
                    {allowCoins ? (
                      <Fragment>
                        <Typography>
                          Apostar kan dela ut mynt som inlöses mot presentkort
                          för produktutbildningar med följande klassificeringar:
                        </Typography>
                        <ul>
                          {classifications &&
                            classifications
                              .filter(
                                classification =>
                                  !classification.name.includes('läkemedel')
                              )
                              .map(classification => (
                                <li>{classification.name}</li>
                              ))}
                        </ul>
                      </Fragment>
                    ) : (
                      <Typography>
                        Apostar delar inte ut mynt för några av era utbildningar
                      </Typography>
                    )}
                    <Typography>
                      För att ändra på detta, kontakta oss på Apostar
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Fragment>
    )
  }
}
export default connect(
  state => ({
    classifications: state.options.classifications.list,
    user: state.user,
  }),
  { loadInvites }
)(Organization)
