import React from 'react'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import LinkIcon from '@material-ui/icons/Link'
import Paragraphs from './Paragraphs'
import Image from './Image'
import { itemIsDeleted, findDiffForProp } from '../diff'

const ItalicCaption = ({ children, ...props }) => (
  <Typography variant="caption" style={{ fontStyle: 'italic' }} {...props}>
    {children}
  </Typography>
)

const lineThrough = { textDecoration: 'line-through' }

export default function ProductSectionContent(props) {
  const { section, sectionDiffs = {}, parsedSectionDiffs = {} } = props
  return (
    <>
      {sectionDiffs.image && (
        <>
          {parsedSectionDiffs.previousImage && (
            <ItalicCaption>Borttagen bild</ItalicCaption>
          )}
          <Image
            style={{ opacity: 0.6 }}
            image={parsedSectionDiffs.previousImage}
          />
          {section.image && <ItalicCaption>Ny bild</ItalicCaption>}
        </>
      )}
      {section.image && <Image image={section.image} />}
      {sectionDiffs.text && (
        <>
          <ItalicCaption>Föregående text:</ItalicCaption>
          <Paragraphs style={lineThrough}>
            {sectionDiffs.text[0].lhs}
          </Paragraphs>
          <ItalicCaption>Ny text:</ItalicCaption>
        </>
      )}
      <Paragraphs>{section.text}</Paragraphs>
      {section.list && (
        <ul style={{ paddingLeft: 20 }}>
          {section.list.map((listText, index) =>
            listText ? <li key={index}>{listText}</li> : null
          )}
        </ul>
      )}
      {section.links &&
        section.links.map((link, index) => {
          const linkDiffs =
            parsedSectionDiffs.linkChanges &&
            parsedSectionDiffs.linkChanges[index]
          const labelDiff = findDiffForProp(linkDiffs, 'label', 4)
          return (
            <Typography key={index}>
              <Link color="inherit" href={link.url} target="_blank">
                <LinkIcon style={{ verticalAlign: 'middle' }} />{' '}
                {labelDiff && <span style={lineThrough}>{labelDiff.lhs}</span>}{' '}
                {link.label || link.url}
              </Link>
            </Typography>
          )
        })}
      {parsedSectionDiffs.linkGeneral &&
        parsedSectionDiffs.linkGeneral.map((diff, index) => {
          if (itemIsDeleted(diff.item)) {
            const link = diff.item.lhs
            return (
              <Typography key={index}>
                <Link
                  color="inherit"
                  href={link.url}
                  style={lineThrough}
                  target="_blank"
                >
                  <LinkIcon style={{ verticalAlign: 'middle' }} />{' '}
                  {link.label || link.url}
                </Link>
              </Typography>
            )
          }
          return null
        })}
    </>
  )
}
