import React from 'react'
import { Card, CardHeader, Typography } from '@material-ui/core'
import { getFormSyncErrors, FormName } from 'redux-form'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Error from '@material-ui/icons/Error'
import Star from '@material-ui/icons/Star'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  header: {
    backgroundColor: '#eee',
  },
  icon: {
    verticalAlign: 'middle',
  },
  status: {
    margin: 'auto 0',
  },
})

const CardStatus = withStyles(styles)(
  connect((state, ownProps) => ({
    errors: getFormSyncErrors(ownProps.form)(state),
  }))(({ optional, validate, classes, errors }) => {
    if (optional) {
      return (
        <Typography>
          Rekommederat <Star className={classes.icon} />
        </Typography>
      )
    }
    if (validate && validate(errors)) {
      return (
        <Typography>
          Komplett! <CheckCircle className={classes.icon} color="primary" />
        </Typography>
      )
    }
    return (
      <Typography>
        Ofullständig! <Error className={classes.icon} color="inherit" />
      </Typography>
    )
  })
)

function ProductInputCard(props) {
  const { title, children, classes, validate, optional } = props
  return (
    <FormName>
      {formProps => (
        <Card className={classes.root}>
          <CardHeader
            title={title}
            classes={{ root: classes.header, action: classes.status }}
            action={
              <CardStatus
                optional={optional}
                form={formProps.form}
                validate={validate}
              />
            }
          />
          {children}
        </Card>
      )}
    </FormName>
  )
}
export default withStyles(styles)(ProductInputCard)
