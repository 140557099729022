import React from 'react'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withUser } from '../actions'

const pharmacyQuestionEntries = [
  {
    question: 'Hur kvalitetsgranskar jag en produktutbildning?',
    answer:
      'En produktutbildning granskas genom att du kontrollerar om innehållet följer godkänd SPC, FASS eller annan godkänd produktbeskrivning. Off label-information får ej publiceras.',
  },
  {
    question: 'Hur skapar jag ytterligare administratörskonton?',
    answer:
      'För närvarande kan du ej skapa konton själv. Vänligen kontakta oss så hjälper vi dig!',
  },
  {
    question:
      'Hur lång tid har jag på mig för att kvalitetsgranska en produktutbildning?',
    answer: 'Du har 2 veckor på dig att kvalitetsgranska en produktutbildning.',
  },
  {
    question: 'Vad händer om jag inte kvalitetsgranskar en produktutbildning?',
    answer: 'Efter två veckor godkänns produktutbildningen automatiskt.',
  },
  {
    question:
      'Vad gör jag om jag har en anmärkning på innehållet i en produktutbildning?',
    answer:
      'Skriv en kommentar i kommentarsfältet när du kvalitetsgranskar en inkommen utbildning. Företaget som skapat produktutbildningen informeras om anmärkningen och får en möjlighet att korrigera. Därefter kan du kvalitetsgranska på nytt.',
  },
]

const supplierQuestionEntries = [
  {
    question:
      'Om en apotekskedja begär komplettering eller redigering i min utbildning, behöver jag skapa en ny separat utbildning för just den apotekskedjan?',
    answer:
      'Som leverantör behöver du enbart skapa en version av utbildningen. Om flera apotekskedjor godkänner en utbildning och en apotekskedja begär komplettering kommer kompletteringen att gälla för samtliga apotekskedjor. De övriga apotekskedjorna behöver godkänna det nya materialet på nytt.',
  },
  {
    question: 'Till vilka apotek får en produktutbildning skickas?',
    answer:
      'Produktutbildning får enbart skickas till de apotek som säljer produkten eller ska börja sälja inom en snart framtid (nyhetsvaror).',
  },
  {
    question:
      'Hur lång tid tar det innan en inlagd utbildning blir synlig i appen för apotekspersonalen?',
    answer:
      'Om en utbildning klarar samtliga kvalitetskontroller (inga off-label, överdriven effekt osv) godkänns innehållet och blir tillgänglig i appen för apotekspersonalen inom 4 veckor från att det skickats till granskning. Om innehållet ej godkänns kommer feedback att erhållas med möjlighet till korrigering.',
  },
  {
    question: 'Hur snabbt måste man svara på en fråga från apotekspersonalen?',
    answer:
      'Alla leverantörer förväntas svara apotekspersonalen i snabbast möjliga mån. Du som leverantör får själv bestämma hur snabbt ni vill svara. Kom ihåg att apotekspersonalen ivrigt väntar på dina svar för att kunna lära sig mer om just dina produkter för en lyckosam försäljning!',
  },
  {
    question: 'Vad händer när maxtak för kunskapstest överskrids?',
    answer:
      'Samtliga produktutbildningar inaktiveras så att användarna inte längre kan ta kunskapstest tillhörande era utbildningar tills nästa månad',
  },
  {
    question: 'Vad är skillnaden mellan aktiva och inaktiva utbildningar?',
    answer:
      'Aktiva utbildningar är utbildningar som apotekspersonalen kan läsa och genomföra en examination i form av kunskapsfrågor för att säkerställa deltagarens kunskapsinhämtning. Deltagaren kan även samla spelpoäng för att tävla mot andra och erhålla bonuspoäng för insamling av ett digitalt presentkort. Inaktiva utbildningar kan enbart läsas av apotekspersonal utan några avslutande kunskapsfrågor. Ingen ersättning ges här till deltagaren.',
  },
]
function FAQ(props) {
  const { user } = props
  const getQuestionEntries = () => {
    if (user.isSupplierAdmin()) {
      return supplierQuestionEntries
    }
    if (user.isPharmacyAdmin()) {
      return pharmacyQuestionEntries
    }
    return []
  }
  return (
    <div>
      <Typography variant="h6" color="primary" gutterBottom>
        Vanliga frågor och svar
      </Typography>
      {getQuestionEntries().map(({ question, answer }, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}
export default withUser(FAQ)
