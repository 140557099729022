/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import uuidv4 from 'uuid/v4'
import DeleteIcon from '@material-ui/icons/Delete'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Attachment from '@material-ui/icons/Attachment'
import Link from '@material-ui/core/Link'
import {
  uploadFile,
  monitorUpload,
  getUploadedFileMetadata,
} from '../StorageUtils'

class FileUpload extends Component {
  state = {
    uploads: {},
  }

  uuid = uuidv4()

  handleFileChange = e => {
    e.preventDefault()

    const { files } = e.target

    const tmpFiles = Array.from(files)

    tmpFiles.forEach(file => {
      const uploadTask = uploadFile(
        `${this.props.storagePath}/attachments`,
        file
      )
      monitorUpload(uploadTask, upload => {
        this.setState(state => ({
          uploads: {
            ...state.uploads,
            [file.name]: upload,
          },
        }))
      })
      getUploadedFileMetadata(uploadTask, file).then(uploadedFile => {
        this.setState(state => {
          const uploads = Object.assign({}, state.uploads)
          delete uploads[uploadedFile.originalName]
          return {
            uploads,
          }
        })
        this.props.productRef.collection('attachments').add({
          index: this.props.fields.length,
          ...uploadedFile,
        })
        // .then(() => this.props.fields.unshift(file))
      })
    })
  }

  render() {
    const { fields } = this.props
    const { uploads } = this.state
    const files = fields.getAll() || []
    const uploadNames = Object.keys(uploads)
    return (
      <div style={{ display: 'inline' }}>
        <input
          id={this.uuid}
          style={{ display: 'none' }}
          type="file"
          onChange={this.handleFileChange}
          multiple
        />
        <label htmlFor={this.uuid}>
          <Button variant="outlined" component="span">
            Ladda upp extramaterial
          </Button>
        </label>

        {Boolean(files.length) && (
          <List dense>
            {files.map(file => (
              <ListItem key={file.originalName}>
                <ListItemIcon>
                  <Attachment />
                </ListItemIcon>
                <Link target="_blank" href={file.url}>
                  <ListItemText
                    style={{ width: '100%' }}
                    primary={file.originalName || file.name}
                  />
                </Link>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="Delete"
                    onClick={() =>
                      this.props.productRef
                        .collection('attachments')
                        .doc(file.uid)
                        .delete()
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
        {Boolean(uploadNames.length) && (
          <List dense>
            {uploadNames.map(name => (
              <ListItem key={name}>
                <ListItemIcon>
                  <Attachment />
                </ListItemIcon>
                <ListItemText
                  primary={name}
                  secondary={
                    <LinearProgress
                      variant="determinate"
                      value={uploads[name].progress}
                    />
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </div>
    )
  }
}
export default FileUpload
