import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { Field, FieldArray, formValueSelector } from 'redux-form'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RemoveCircle from '@material-ui/icons/RemoveCircleOutline'
import AddCircle from '@material-ui/icons/AddCircleOutline'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { uploadImage } from '../../StorageUtils'
import ImageUpload from '../ImageUpload'
import { wordCountChange } from './actions'
import { FormRemovableImage, FormTextField } from './input-components'

const renderCheckbox = ({ input, label, ...custom }) => (
  <FormControlLabel
    control={
      <Checkbox
        onChange={input.onChange}
        value={input.name}
        checked={input.checked}
        {...custom}
      />
    }
    label={label}
  />
)

const renderTextList = ({ fields, placeholder }) => (
  <React.Fragment>
    {fields.map((listItem, index) => {
      // const isLastItem = index === sectionData.list.length - 1
      return (
        <Grid container key={listItem}>
          <Grid item xs={11}>
            <Field
              key={index}
              name={listItem}
              fullWidth
              // helperText={isLastItem ? helperText : ""}
              component={FormTextField}
              variant="outlined"
              placeholder={placeholder || ''}
            />
          </Grid>
          <Grid item xs={1}>
            <RemoveCircle onClick={() => fields.remove(index)} />
          </Grid>
        </Grid>
      )
    })}
    {fields.length < 5 && <AddCircle onClick={() => fields.push('')} />}
  </React.Fragment>
)

const renderLinkList = ({ fields }) => (
  <React.Fragment>
    {fields.map((listItem, index) => {
      // const isLastItem = index === sectionData.list.length - 1
      return (
        <Grid container key={listItem}>
          <Grid item xs={11}>
            <Field
              key={`${index}-url`}
              name={`${listItem}.url`}
              helperText="URL: tex https://www.fass.se"
              component={FormTextField}
              style={{ marginRight: 8 }}
            />
            <Field
              key={index}
              name={`${listItem}.label`}
              helperText="Länktext: tex Fass"
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={1}>
            <RemoveCircle onClick={() => fields.remove(index)} />
          </Grid>
        </Grid>
      )
    })}
    <Typography onClick={() => fields.push({ url: '', name: '' })}>
      <AddCircle style={{ verticalAlign: 'middle' }} /> Länk
    </Typography>
  </React.Fragment>
)
class ProductSectionInput extends Component {
  uploadSectionImage = imageFile =>
    uploadImage(`${this.props.storagePath}/images`, imageFile)

  renderImageUpload = ({ input: { onChange }, value, ...custom }) => (
    <ImageUpload
      onImageSelect={file => this.uploadSectionImage(file).then(onChange)}
      {...custom}
    />
  )

  render() {
    const { irrelevant, required, title, helperText, list } = this.props
    return (
      <Card style={{ marginBottom: 24 }}>
        <CardHeader
          title={
            required ? (
              title
            ) : (
              <Field name="title" label="Rubrik" component={FormTextField} />
            )
          }
        />
        <CardContent>
          <Field name="image" component={FormRemovableImage} />
          {list ? (
            <FieldArray
              name="list"
              placeholder="Ange ett säljargument"
              component={renderTextList}
            />
          ) : (
            <Field
              name="text"
              component={FormTextField}
              helperText={helperText}
              disabled={irrelevant}
              title={title}
              rows={3}
              rowsMax={20}
              multiline
              fullWidth
            />
          )}
          <FieldArray name="links" component={renderLinkList} />
          <Field
            name="irrelevant"
            label="Rubrik ej relevant"
            type="checkbox"
            component={renderCheckbox}
          />
        </CardContent>
        <CardActions>
          <Field
            name="image"
            storagePath={`${this.props.storagePath}/images`}
            disabled={irrelevant}
            component={this.renderImageUpload}
          />
          <Button variant="outlined" color="primary">
            Spara
          </Button>
          {!required && (
            <Button variant="outlined" onClick={this.props.onRemove}>
              Ta bort
            </Button>
          )}
        </CardActions>
      </Card>
    )
  }
}

const sectionSelector = formValueSelector('education')

export default connect(
  (state, props) => sectionSelector(state, props.fieldname),
  { wordCountChange }
)(ProductSectionInput)
