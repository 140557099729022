import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { firebaseAuth, EmailAuthProvider } from '../../firebase'

class ChangePassword extends Component {
  state = {
    password: {
      old: '',
      new: '',
      newConfirm: '',
    },
    saving: false,
    error: '',
    success: '',
  }

  changePassword = () => {
    const { password } = this.state
    if (password.new !== password.newConfirm) {
      this.setState({ error: 'Lösenorden matchar inte' })
      return
    }
    this.setState({ saving: true })
    const authUser = firebaseAuth.currentUser
    const credential = EmailAuthProvider.credential(
      authUser.email,
      password.old
    )
    authUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        return authUser.updatePassword(password.new)
      })
      .then(() => {
        this.setState({
          password: {
            old: '',
            new: '',
            newConfirm: '',
          },
          success: 'Ditt lösenord har uppdaterats',
          error: '',
          saving: false,
        })
      })
      .catch(error => {
        let errorMessage = error.message
        if (error.code === 'auth/wrong-password') {
          errorMessage = 'Fel nuvarande lösenord'
        }
        this.setState({ error: errorMessage, saving: false })
      })
  }

  handleInputChange = field => event => {
    const { value } = event.target
    this.setState(state => ({
      password: {
        ...state.password,
        [field]: value,
      },
    }))
  }

  render() {
    const { error, success, password, saving } = this.state
    return (
      <Card>
        <CardHeader title="Byt lösenord" />
        <CardContent>
          <TextField
            onChange={this.handleInputChange('old')}
            label="Nuvarande lösenord"
            type="password"
            value={password.old}
            style={{ display: 'block' }}
          />
          <TextField
            onChange={this.handleInputChange('new')}
            label="Nytt lösenord"
            type="password"
            value={password.new}
            style={{ display: 'block' }}
          />
          <TextField
            onChange={this.handleInputChange('newConfirm')}
            label="Bekräfta nytt lösenord"
            type="password"
            value={password.newConfirm}
            style={{ display: 'block' }}
          />
          {error && <Typography color="error">{error}</Typography>}
          {success && <Typography color="primary">{success}</Typography>}
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            disabled={saving}
            onClick={this.changePassword}
          >
            Byt lösenord
          </Button>
        </CardActions>
      </Card>
    )
  }
}
export default connect(state => ({
  auth: state.auth,
}))(ChangePassword)
