import React from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import { getPreviousImageFromDiffs, itemIsEdited } from '../diff'
import ProductQuestionContent from './ProductQuestionContent'

const parseQuestionDiffs = questionDiffs => {
  const parsedDiffs = {}
  if (!questionDiffs) {
    return parsedDiffs
  }
  if (questionDiffs.alternatives) {
    parsedDiffs.alternatives = _.groupBy(
      questionDiffs.alternatives,
      diff => diff.path[3]
    )
  }
  if (questionDiffs.image) {
    const imageDiff = questionDiffs.image[0]
    if (imageDiff.rhs === null) {
      parsedDiffs.previousImage = imageDiff.lhs
    } else if (itemIsEdited(imageDiff)) {
      parsedDiffs.previousImage = getPreviousImageFromDiffs(questionDiffs.image)
    }
  }
  return parsedDiffs
}

const Quiz = ({ quiz = [], diffs = {}, showChangesOnly = false }) => {
  if (_.isEmpty(diffs) && showChangesOnly) {
    return (
      <Typography variant="h6">
        Det finns inga ändringar i kunskapstestet
      </Typography>
    )
  }
  return quiz.map((question, index) => {
    const diff = diffs[question.uid] || {}
    const parsedDiffs = parseQuestionDiffs(diff)
    if (showChangesOnly && _.isEmpty(diff)) {
      return null
    }
    return (
      <Card key={`question${index}`} style={{ marginBottom: 8 }}>
        <CardHeader title={`Fråga ${index + 1}`} />
        <CardContent>
          <ProductQuestionContent
            question={question}
            diff={diff}
            parsedDiffs={parsedDiffs}
          />
        </CardContent>
      </Card>
    )
  })
}

export default connect((state, ownProps) => ({
  quiz: ownProps.quiz || state.product.quiz,
}))(Quiz)
