/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import uuidv4 from 'uuid/v4'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  wrapper: {
    position: 'relative',
    display: 'inline',
  },
  buttonSuccess: {
    // backgroundColor: green[500],
    // '&:hover': {
    //   backgroundColor: green[700],
    // },
  },
  buttonProgress: {
    // color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

class ImageUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: '',
      loading: false,
      success: false,
      imagePreviewUrl: props.currentImageSrc ? props.currentImageSrc : '',
    }
    this.uuid = uuidv4()
    this.handleImageChange = this.handleImageChange.bind(this)
    this.removeImage = this.removeImage.bind(this)
  }

  handleImageChange(e) {
    e.preventDefault()

    const reader = new FileReader()
    const file = e.target.files[0]

    this.setState({ loading: true, success: false })
    reader.onloadend = () => {
      this.setState({
        file,
        imagePreviewUrl: reader.result,
      })
    }

    reader.readAsDataURL(file)
    if (this.props.onImageSelect) {
      Promise.resolve(this.props.onImageSelect(file)).then(
        () => {
          this.setState({
            loading: false,
            success: true,
            error: null,
          })
        },
        err => {
          this.setState({
            loading: false,
            success: false,
            error: err,
          })
        }
      )
    }
  }

  removeImage() {
    this.setState({
      imagePreviewUrl: null,
      file: null,
    })
    if (this.props.onImageRemove) {
      this.props.onImageRemove()
    }
  }

  render() {
    const {
      disabled,
      buttonText,
      classes,
      color,
      onImageSelect,
      icon,
      ...rest
    } = this.props
    const { error, loading, success } = this.state
    return (
      <div className={classes.wrapper}>
        <input
          accept="image/*"
          id={this.uuid}
          style={{ display: 'none' }}
          type="file"
          onChange={this.handleImageChange}
        />
        <label htmlFor={this.uuid}>
          <Button
            variant="outlined"
            component="span"
            color={color || 'default'}
            className={success ? classes.buttonSuccess : ''}
            disabled={disabled || loading}
            endIcon={icon}
            {...rest}
          >
            {buttonText || 'Lägg till bild'}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          {error && (
            <Typography color="error">
              Kunde inte ladda upp bild. Försök med mindre filstorlek eller
              annat format
            </Typography>
          )}
        </label>
      </div>
    )
  }
}
export default withStyles(styles)(ImageUpload)
