import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from '@material-ui/core'
import { FormTextField, FormCheckbox } from './product-input/input-components'
import { functionsEurope, firebaseAuth, analytics } from '../firebase'

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  input: {
    marginTop: theme.spacing(2),
  },
})
const minLength = value =>
  value && value.length < 8 ? `Måste vara 8 tecken eller fler` : undefined

const required = value =>
  value || typeof value === 'number' ? undefined : 'Obligatorisk'

const identical = (value, allValues) =>
  value === allValues.password ? undefined : 'Lösenorden matchar inte'

const signup = functionsEurope.httpsCallable('supplierSignup')
const privacyPolicyUrl = 'https://apostar.se/personuppgiftspolicy.html'
const termsUrl = 'https://apostar.se/anvandarvillkor.html'

function Signup(props) {
  const {
    classes,
    location,
    handleSubmit,
    submitting,
    invalid,
    error,
    history,
    auth,
  } = props
  useEffect(() => {
    if (auth.authenticated) {
      history.push('/')
    }
  }, [auth.authenticated])
  const submit = values => {
    return signup(values)
      .then(() => {
        analytics.logEvent('sign_up', { method: 'invite' })
        return firebaseAuth.signInWithEmailAndPassword(
          values.email,
          values.password
        )
      })
      .catch(err => {
        throw new SubmissionError({
          _error: err.message,
        })
      })
  }
  const params = new URLSearchParams(location.search)
  const logoUrl = params.get('logoUrl')
  const supplierName = params.get('supplierName')
  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography component="h3" variant="h4" gutterBottom>
          Skapa konto
        </Typography>
        <Typography component="h4" variant="h5">
          {supplierName || ''}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(submit)}>
          {logoUrl && <img src={logoUrl} alt="logga" />}
          <Field
            id="firstname"
            name="firstname"
            label="Förnamn"
            component={FormTextField}
            disabled={submitting}
            validate={[required]}
            fullWidth
            className={classes.input}
          />
          <Field
            id="lastname"
            name="lastname"
            label="Efternamn"
            component={FormTextField}
            disabled={submitting}
            validate={[required]}
            fullWidth
            className={classes.input}
          />
          <Field
            id="role"
            name="role"
            label="Roll"
            component={FormTextField}
            disabled={submitting}
            fullWidth
            className={classes.input}
          />
          <Field
            id="email"
            name="email"
            type="email"
            label="Epost"
            component={FormTextField}
            validate={[required]}
            disabled
            fullWidth
            className={classes.input}
          />
          <Field
            id="password"
            name="password"
            type="password"
            label="Lösenord"
            component={FormTextField}
            disabled={submitting}
            validate={[required, minLength]}
            fullWidth
            autoFocus
            className={classes.input}
          />
          <Field
            name="passwordConfirm"
            type="password"
            id="password-confirm"
            label="Bekräfta lösenord"
            component={FormTextField}
            validate={[required, minLength, identical]}
            disabled={submitting}
            fullWidth
            className={classes.input}
          />
          <Field
            name="consent"
            id="consent"
            label={
              <Typography variant="caption">
                Jag har läst och godkänner{' '}
                <Link target="_blank" href={termsUrl}>
                  användarvillkoren
                </Link>{' '}
                och{' '}
                <Link target="_blank" href={privacyPolicyUrl}>
                  personuppgiftspolicyn
                </Link>
              </Typography>
            }
            component={FormCheckbox}
            disabled={submitting}
            className={classes.input}
            validate={required}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={submitting || invalid}
          >
            Skapa konto
          </Button>
          {submitting && <LinearProgress />}
          {error && <Typography color="error">Error: {error}</Typography>}
        </form>
      </Paper>
    </main>
  )
}

export default compose(
  connect((state, ownProps) => {
    const params = new URLSearchParams(ownProps.location.search)
    const invite = ['firstname', 'lastname', 'email', 'token'].reduce(
      (values, key) => ({ ...values, [key]: params.get(key) }),
      {}
    )
    return {
      initialValues: invite,
      invite,
      auth: state.auth,
    }
  }),
  reduxForm({
    form: 'signup',
  }),
  withStyles(styles)
)(Signup)
