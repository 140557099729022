import _ from 'lodash'
import { serverTimestamp } from '../../firebase'
import database from '../../database'
import { PRODUCT_TYPES } from '../../constants'

const emptyQuestion = {
  question: '',
  alternatives: [
    { text: '', correct: true },
    { text: '', correct: false },
    { text: '', correct: false },
  ],
}

const indicationTitle = 'Indikation'
const indicationHelperText = 'Enbart godkända indikationer. Ingen off-label'

const defaultSections = [
  {
    title: 'Sammanfattning',
  },
  {
    title: 'Säljargument',
    list: ['', '', '', '', ''],
  },
  {
    title: indicationTitle,
  },
  {
    title: 'Dosering',
  },
  {
    title: 'Graviditet och amning',
  },
  {
    title: 'Verkningsmekanism',
  },
  {
    title: 'Interaktioner och kontraindikationer',
  },
  {
    title: 'Barn',
  },
  {
    title: 'Innehåll',
  },
].map(section => ({ ...section, text: '', required: true }))
const sectionGuides = {
  Sammanfattning: {
    long: 'Sammanfatta allt det viktigaste snabbt och enkelt bl bla',
    helperText: 'Skriv det viktigaste',
  },
  Verkningsmekanism: {
    long: 'Den verkar funka',
    helperText:
      'Hur fungerar produkten? Illustrera gärna med en bild och förklarande text',
  },
  Indikation: {
    long: 'Du borde veta',
    helperText: indicationHelperText,
  },
  Målgrupp: {
    long: 'Alla som köper',
    helperText:
      'Vilka vänder sig produkten till? Skriv tex åldrar 23-24 med hög riskbenägenhet',
  },
  Säljargument: {
    long:
      'Skriv gärna 5 korta säljargument som apotekspersonal lätt kan använda sig av vid rekommendation',
    helperText: 'Max 5 säljargument. Kort och koncist',
  },
  Dosering: {
    long: 'Dosen är giftet. Dosera mera',
    helperText:
      'Beskriv kortfattat. Vid behov hänvisa till fass för mer utförlig beskrivning',
  },
  Bruksanvisning: {
    long: 'Använd säkert',
    helperText: 'Beskriv kortfattat hur produkten ska användas',
  },
  'Graviditet och amning': {
    long: 'Säkra barnen',
    helperText: '"Ej aktuell", "Saknar data", text',
  },
  'Interaktioner och kontraindikationer': {
    long: 'Interagera och kontrahera',
    helperText: 'Bör undvikas med andra läkemedel, sjukdomar, äldre osv',
  },
  Barn: {
    long: 'Framtidens hopp',
    helperText: 'Åldersgräns',
  },
  Innehåll: {
    long: 'Många ingredienser blir det',
    helperText:
      'Många frågor om produkter handlar om ingredienser. Laktos, gluten, vegan, gelatin, parabener, mineralolja, sötningsmedel, spår av nötter…',
  },
}

const getGuidePart = (state, part) =>
  Boolean(
    (state.user && state.user.guide && state.user.guide[part]) ||
      state.guide.parts[part]
  )

const withWordCounts = sections =>
  sections.map(section => ({
    ...section,
    wordCount: section.text ? section.text.split(' ').length : 0,
  }))

const productDataToForm = productData => {
  const { sections, quiz, attachments, ...baseInfo } = productData
  const sectionsWithCounts = withWordCounts(sections) || []
  return {
    ...baseInfo,
    sections: sectionsWithCounts || [],
    quiz: quiz || [],
    attachments: attachments || [],
    totalWordCount: sectionsWithCounts.reduce(
      (totalWordCount, section) => totalWordCount + section.wordCount,
      0
    ),
  }
}
const getPostSaveUrl = (productUid, product) => {
  const baseUrl = `/product/${productUid}`
  if (product.isComplete) {
    return `${baseUrl}/quality-control`
  }
  return baseUrl
}

const makeMetadata = (user, supplierSnap) => ({
  creationDateTime: serverTimestamp(),
  editDateTime: serverTimestamp(),
  createdByUid: user.uid,
  createdByName: user.getFullName(),
  responsibleUserUids: [user.uid],
  responsibleUserNames: [user.getFullName()],
  supplierName: supplierSnap.get('name'),
  supplierUid: supplierSnap.id,
  supplier: user.supplier,
  activeEducation: Boolean(!supplierSnap.get('legacyPricing')),
  legacyPricing: Boolean(supplierSnap.get('legacyPricing')),
  approvedPharmacyUids: [],
  dirty: true,
  completion: {
    base: 0,
    media: 0,
    content: 0,
    quiz: 0,
  },
})

const batchCreateOrderedCollection = (batch, baseRef) => (
  docs,
  collectionName
) => {
  docs.forEach((doc, index) =>
    batch.set(baseRef.collection(collectionName).doc(), {
      index,
      ...doc,
      creationDateTime: serverTimestamp(),
    })
  )
}

const batchUpdateOrderedCollection = (collectionRef, items, inputBatch) => {
  const batch = inputBatch || database.batch()
  items.forEach(({ uid, ...section }, index) => {
    const docRef = collectionRef.doc(uid)
    batch.set(docRef, {
      ...section,
      index,
    })
  })
  return batch
}

const createUpdateAndDeleteDocs = (batch, ref, docs, originalDocs) => {
  const [existingDocs, newDocs] = _.partition(docs, 'uid')
  existingDocs.forEach(({ uid, ...doc }) => {
    const docRef = ref.doc(uid)
    batch.set(docRef, doc)
  })
  newDocs.forEach((doc, index) => {
    const fullDoc = {
      ...doc,
      index: existingDocs.length + index,
      creationDateTime: serverTimestamp(),
    }
    batch.set(ref.doc(), fullDoc)
  })
  const existingDocUids = existingDocs.map(doc => doc.uid)
  const originalDocUids = originalDocs.map(doc => doc.uid)
  const docUidsToDelete = _.difference(originalDocUids, existingDocUids)
  docUidsToDelete.forEach(docUidToDelete =>
    batch.delete(ref.doc(docUidToDelete))
  )
}

const batchCreateProduct = (
  newProductRef,
  fullProductData,
  user,
  additionalBatching
) => {
  const { sections, quiz, attachments, ...baseInfo } = fullProductData
  return user.supplier.get().then(supplierSnap => {
    const productToSave = {
      ...baseInfo,
      ...makeMetadata(user, supplierSnap),
    }

    const batch = database.batch()

    batch.set(newProductRef, productToSave)

    const createCollection = batchCreateOrderedCollection(batch, newProductRef)
    createCollection(sections, 'sections')
    createCollection(quiz, 'quiz')
    if (attachments) {
      createCollection(attachments, 'attachments')
    }
    if (additionalBatching) {
      additionalBatching(batch, productToSave)
    }
    return batch.commit()
  })
}

const validate = (validators, data) => {
  const errors = validators
    .map(validator => validator(data))
    .filter(error => Boolean(error))
  return errors
}

const createEmptyProduct = user => {
  const productRef = database
    .getDatabase()
    .collection('products')
    .doc()
  const emptyProduct = {
    name: '',
    classificationUid: '',
    categoryUid: '',
    eanNumber: '',
    minimiText: '',
    tags: [],
    draft: true,
    isComplete: false,
    quiz: new Array(3).fill(emptyQuestion),
    sections: defaultSections,
    type: PRODUCT_TYPES.PRODUCT,
  }
  return batchCreateProduct(productRef, emptyProduct, user).then(
    () => productRef.id
  )
}

const isClassificationWhere = (
  classifications,
  classificationUid,
  predicate
) => {
  if (!(classifications && classificationUid)) return false
  const pharmaceuticalUids = classifications
    .filter(predicate)
    .map(classification => classification.value)
  return pharmaceuticalUids.includes(classificationUid)
}
const isPharmaceuticalClassification = (classifications, classificationUid) =>
  isClassificationWhere(classifications, classificationUid, classification =>
    classification.name.includes('läkemedel')
  )

const isClassificationWithIndication = (classifications, classificationUid) =>
  isPharmaceuticalClassification(classifications, classificationUid) ||
  isClassificationWhere(
    classifications,
    classificationUid,
    classification => classification.name === 'Medicinteknisk produkt'
  )

const required = value =>
  value || typeof value === 'number' ? undefined : 'Obligatorisk'

const isUrl = value => {
  // const weakExpression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
  const expression = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/
  const regex = new RegExp(expression)
  return value.match(regex) ? undefined : 'Felaktig URL'
}

const arrayErrorCount = errors => {
  if (Array.isArray(errors)) {
    return errors.filter(error => error).length
  }
  return 0
}

const makeInitData = (form, product) => {
  if (!product) return {}
  switch (form) {
    case 'base': {
      return {
        name: product.name || '',
        tags: product.tags || [],
        eanNumber: product.eanNumber || '',
        classificationUid: product.classificationUid || '',
        categoryUid: product.categoryUid || '',
        minimiText: product.minimiText || '',
      }
    }
    case 'media':
      return {
        image: product.image || null,
        video: product.video || null,
        attachments: product.attachments || [],
      }
    case 'content':
      return {
        sections: product.sections || [],
      }
    case 'quiz':
      return {
        quiz: product.quiz || [],
      }
    default:
      return {}
  }
}

const calcProgress = (form, errors, values) => {
  const percentage = (errorCount, fieldsCount) =>
    100 * (1 - errorCount / fieldsCount)
  switch (form) {
    case 'base': {
      const errorCount = Object.keys(errors).length
      return percentage(errorCount, 4)
    }
    case 'media':
      return errors.image ? 0 : 100
    case 'content':
      return percentage(
        arrayErrorCount(errors.sections),
        values.sections.length
      )
    case 'quiz':
      return percentage(arrayErrorCount(errors.quiz), values.quiz.length)
    default:
      return 0
  }
}

export {
  makeMetadata,
  batchCreateOrderedCollection,
  batchUpdateOrderedCollection,
  createUpdateAndDeleteDocs,
  emptyQuestion,
  batchCreateProduct,
  createEmptyProduct,
  getPostSaveUrl,
  productDataToForm,
  isPharmaceuticalClassification,
  isClassificationWithIndication,
  required,
  isUrl,
  sectionGuides,
  defaultSections,
  validate,
  calcProgress,
  makeInitData,
  getGuidePart,
}
