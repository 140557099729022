import React from 'react'
import { Redirect, Switch, withRouter } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PrivateRoute from '../PrivateRoute'
import ProductReadOnly from './ProductReadOnly'
import ProductCreate from './product-input/ProductCreate'
import ProductView from './ProductView'

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  navigation: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})

const ProductOverview = compose(
  withRouter,
  connect(state => ({
    user: state.user,
  })),
  withStyles(styles)
)(props => {
  const {
    user,
    match: {
      params: { productUid },
    },
  } = props
  if (user.uid) {
    return user.isPharmacyAdmin() ? (
      <ProductReadOnly productUid={productUid} {...props} />
    ) : (
      // <ProductWithStepper productUid={productUid} {...props} />
      <ProductView productUid={productUid} />
    )
  }
  return <LinearProgress />
})

function Product(props) {
  const {
    match: {
      params: { productUid },
    },
  } = props
  return (
    <Switch>
      <Redirect
        exact
        from="/product/:productUid"
        to={`/product/${productUid}/overview`}
      />
      <PrivateRoute
        path="/product/:productUid/overview"
        component={ProductOverview}
      />
      <PrivateRoute
        path="/product/:productUid/create"
        component={ProductCreate}
      />
    </Switch>
  )
}

export default withRouter(Product)
