import React, { Component, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import SubproductInformation from './SubproductInformation'
import ProductInformation from './ProductInformation'
import { PRODUCT_TYPES } from '../constants'
import database from '../database'
import { serverTimestamp } from '../firebase'
import RequestResponseInput from './quality-control/RequestResponseInput'

class ProductReadOnly extends Component {
  state = {
    comment: '',
    sending: false,
  }

  componentDidMount() {
    const { user, productUid } = this.props
    database.getFullProductForUser(productUid, user).then(product => {
      this.setState({ product })
    })
    database
      .getDatabase()
      .collection('pharmacies')
      .doc(this.props.user.pharmacyUid)
      .collection('publicationRequests')
      .where('productUid', '==', productUid)
      .orderBy('creationDateTime', 'desc')
      .limit(1)
      .onSnapshot(publicationRequestsSnap => {
        const latestRequestDoc = publicationRequestsSnap.docs[0]
        if (latestRequestDoc) {
          this.setState({
            latestRequest: latestRequestDoc.data(),
            latestRequestRef: latestRequestDoc.ref,
            comment: latestRequestDoc.get('comment'),
          })
        }
      })
  }

  requestChange = comment => {
    this.setState({ sending: true })
    this.state.latestRequestRef
      .update({
        pharmacyApproval: {
          approved: false,
          timeout: false,
          autoAccept: false,
          dateTime: serverTimestamp(),
          comment,
        },
      })
      .then(() => {
        this.setState({ sending: false })
      })
  }

  render() {
    const { product, sending, latestRequest } = this.state
    const isSeries = product && product.type === PRODUCT_TYPES.SERIES
    const denied =
      latestRequest && latestRequest.pharmacyApproval.approved === false
    const approved = latestRequest && latestRequest.pharmacyApproval.approved
    const autoAccept =
      latestRequest && latestRequest.pharmacyApproval.autoAccept
    return !product ? (
      <LinearProgress />
    ) : (
      <Fragment>
        <ProductInformation
          variant={isSeries ? 'series' : 'product'}
          product={product}
        />
        {product.subproducts && (
          <Grid container spacing={3}>
            {product.subproducts.map(subproduct => (
              <Grid item xs={12} lg={6} key={subproduct.id}>
                <SubproductInformation subproduct={subproduct} />
              </Grid>
            ))}
          </Grid>
        )}
        {denied && (
          <Fragment>
            <Typography>Komplettering begärd med kommentar:</Typography>
            {latestRequest.pharmacyApproval.comment.split('\n').map(row => (
              <Typography>{row}</Typography>
            ))}
          </Fragment>
        )}
        {latestRequest && autoAccept && approved && (
          <RequestResponseInput
            sending={sending}
            disabled={denied}
            showComment={latestRequest.pharmacyApproval.approved}
            onDeny={this.requestChange}
          />
        )}
      </Fragment>
    )
  }
}
export default ProductReadOnly
