import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import database from '../../database'
import { FormSelect, FormSwitch } from '../product-input/input-components'

const updateUserSummarySettings = (userUid, summary) => {
  const updateData = {
    'settings.summary': summary,
  }
  return database
    .getDatabase()
    .collection('users')
    .doc(userUid)
    .update(updateData)
}

const periodOptions = [
  {
    name: 'Veckovis',
    value: '1 week',
  },
  {
    name: 'Varannan vecka',
    value: '2 weeks',
  },
  {
    name: 'Månadsvis',
    value: '1 month',
  },
]

const productOptions = [
  {
    name: 'Mina produkter',
    value: 'userResponsible',
  },
  {
    name: 'Alla produkter',
    value: 'all',
  },
]

const styles = theme => ({
  setting: {
    marginBottom: theme.spacing(2),
  },
})

function SummarySettings(props) {
  const { classes, active } = props
  return (
    <Card>
      <CardHeader title="Summering" />
      <CardContent>
        <form>
          <div className={classes.setting}>
            <Field
              component={FormSwitch}
              name="active"
              label="Skicka summering via e-post"
              type="checkbox"
            />
          </div>
          <div className={classes.setting}>
            <Field
              disabled={!active}
              label="Frekvens"
              component={FormSelect}
              name="period"
              options={periodOptions}
              fullWidth={false}
            />
          </div>
          <div className={classes.setting}>
            <Field
              disabled={!active}
              label="Produkter"
              component={FormSelect}
              name="products"
              options={productOptions}
              fullWidth={false}
            />
          </div>
        </form>
      </CardContent>
    </Card>
  )
}
const selector = formValueSelector('summary')
export default compose(
  withStyles(styles),
  connect(state => ({
    initialValues: state.user.settings.summary,
    active: selector(state, 'active'),
    user: state.user,
  })),
  reduxForm({
    form: 'summary',
    enableReinitialize: true,
    onChange: (values, dispatch, { user }) => {
      if (Object.keys(values).length && user) {
        updateUserSummarySettings(user.uid, values)
      }
    },
  })
)(SummarySettings)
