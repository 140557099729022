import React from 'react'
import { connect } from 'react-redux'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import MobileDevice from './MobileDevice'
import MobileEducation from './MobileEducation'

function MobilePreview({ product }) {
  return (
    <Card>
      <CardHeader title="Förhandsgranskning" />
      <CardContent>
        <MobileDevice>
          <MobileEducation product={product} />
        </MobileDevice>
      </CardContent>
    </Card>
  )
}
export default connect(state => ({
  product: state.product,
}))(MobilePreview)
