import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'

function UpdateSnackbar({ updateAvailable }) {
  const [dismissed, setDismissed] = React.useState(false)

  const update = () => window.location.reload(true)

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setDismissed(true)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      key="update-snackbar"
      open={updateAvailable && !dismissed}
      autoHideDuration={null}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={
        <span id="message-id">
          Uppdatering tillgänglig. Ladda om för att hämta uppdatering
        </span>
      }
      action={[
        <Button key="update" color="primary" onClick={update}>
          UPPDATERA
        </Button>,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  )
}
export default UpdateSnackbar
