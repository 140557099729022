/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import uuidv4 from 'uuid/v4'
import Typography from '@material-ui/core/Typography'
import {
  uploadFile,
  monitorUpload,
  getUploadedFileMetadata,
} from '../StorageUtils'

const bytesToMb = bytes => bytes / (1024 * 1024)
class VideoUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: '',
      loading: false,
      imagePreviewUrl: props.currentImageSrc ? props.currentImageSrc : '',
    }
    this.uuid = uuidv4()
  }

  cancelUpload = () => {
    if (this.uploadTask) {
      this.uploadTask.cancel()
      this.setState({
        upload: null,
        file: null,
        error: null,
      })
    }
  }

  remove = () => {
    this.setState({
      file: null,
    })
    if (this.props.onRemove) {
      this.props.onRemove()
    }
  }

  handleImageChange = e => {
    e.preventDefault()

    const file = e.target.files[0]
    if (!file) return
    const size = bytesToMb(file.size)
    const mbLimit = 200
    if (size > mbLimit) {
      this.setState({
        error: {
          message: `Filen är ${size.toFixed(
            1
          )} Mb stor. Den får max vara ${mbLimit} Mb.`,
        },
      })
      return
    }

    this.uploadTask = uploadFile(this.props.storagePath, file)
    monitorUpload(this.uploadTask, ({ progress }) => {
      this.setState({
        upload: {
          name: file.name,
          progress,
        },
      })
    })
    getUploadedFileMetadata(this.uploadTask, file).then(file => {
      this.setState({
        upload: null,
        file,
      })
      if (this.props.onUpload) {
        Promise.resolve(this.props.onUpload(file)).then(
          () => {
            this.setState({
              error: null,
            })
          },
          err => {
            console.error('uploading error', err)
            this.setState({
              error: err,
            })
          }
        )
      }
    })
  }

  render() {
    const { disabled, buttonText, style } = this.props
    const { error, upload } = this.state
    return (
      <div style={{ display: 'inline', ...style }}>
        <input
          accept="video/*"
          id={this.uuid}
          style={{ display: 'none' }}
          type="file"
          onChange={this.handleImageChange}
        />
        {upload ? (
          <div key={upload.name}>
            <p>Laddar upp {upload.name}</p>
            <Grid container alignItems="center" justify="space-between">
              <Grid item xs={10}>
                <LinearProgress variant="determinate" value={upload.progress} />
              </Grid>
              <Grid item>
                <Button onClick={this.cancelUpload}>Avbryt</Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <label htmlFor={this.uuid}>
            <Button
              disabled={disabled}
              variant="outlined"
              color="primary"
              component="span"
            >
              {buttonText || 'Ladda upp video'}
            </Button>
            {error && (
              <Typography color="error">
                {error.message ||
                  'Kunde inte ladda upp video. Försök med mindre filstorlek eller annat format'}
              </Typography>
            )}
          </label>
        )}
      </div>
    )
  }
}
export default VideoUpload
