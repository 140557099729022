import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Login from './Login'
import Product from './Product'
import Therapeutic from './therapeutics/Therapeutic'
import TherapeuticAdd from './product-input/TherapeuticAdd'
import Home from './dashboard/Home'
import Request from './quality-control/RequestReview'
import PrivateRoute from '../PrivateRoute'
import Conversation from './Conversation'
import Conversations from './Conversations'
import Products from './Products'
import Therapeutics from './therapeutics/Therapeutics'
import Help from './Help'
import User from './user/User'
import PriceInformation from './PriceInformation'
import PublicationRequestProduct from './quality-control/PublicationRequestProduct'
import ProductActivation from './ProductActivation'
import QualityControl from './quality-control/QualityControl'
import UsersManagement from './UsersManagement'
import Organization from './Organization'
import Statistics from './statistics/Statistics'
import RequestGroup from './quality-control/RequestGroup'
import ErrorBoundary from './ErrorBoundry'
import Signup from './Signup'

function MainContent() {
  return (
    <div className="site">
      <Container xs={12} md={11} lg={10} xl={9}>
        <ErrorBoundary>
          <Switch>
            <Route path="/login" component={props => <Login {...props} />} />
            <Route path="/signup" component={Signup} />
            <PrivateRoute component={Home} exact path="/" title="Hem" />
            <PrivateRoute
              component={Product}
              path="/product/:productUid/activation"
              render={() => <Product activeStep={2} />}
            />
            <PrivateRoute
              component={Product}
              path="/product/:productUid/quality-control"
              render={({ match }) => (
                <Product activeStep={1} productUid={match.params.productUid} />
              )}
            />
            <PrivateRoute
              component={Product}
              path="/product/:productUid"
              render={Product}
              title="Produkt"
            />
            <PrivateRoute
              component={Therapeutics}
              path="/therapeutics"
              title="Terapiområde"
            />
            <PrivateRoute
              component={Therapeutic}
              path="/therapeutic/:therapeuticUid"
              title="Terapiområde"
            />
            <PrivateRoute component={TherapeuticAdd} path="/therapeutic-add" />
            <PrivateRoute
              component={Request}
              path="/request/:requestId/:pharmacyUid"
              render={({ match }) => (
                <Request
                  pharmacyUid={match.params.pharmacyUid}
                  requestId={match.params.requestId}
                />
              )}
              title="Kvalitetsgranskning produkt"
            />
            <PrivateRoute
              component={RequestGroup}
              path="/request-group/:requestGroupUid"
              render={({ match }) => (
                <RequestGroup requestGroupUid={match.params.requestGroupUid} />
              )}
              title="Kvalitetsgranskning produkt"
            />
            <PrivateRoute
              component={PublicationRequestProduct}
              path="/product-request/:pharmacyId/:requestId"
              render={({ match }) => (
                <PublicationRequestProduct
                  requestUid={match.params.requestId}
                  pharmacyUid={match.params.pharmacyId}
                />
              )}
              title="Kvalitetsgranskning produkt"
            />
            <PrivateRoute
              component={Conversation}
              path="/conversation/:conversationId"
              render={({ match }) => (
                <Conversation conversationId={match.params.conversationId} />
              )}
              title="Produktfråga"
            />
            <PrivateRoute
              component={Conversations}
              path="/conversations"
              title="Produktfrågor"
            />
            <PrivateRoute
              component={Products}
              path="/products"
              title="Produkter"
            />
            <PrivateRoute
              component={User}
              path="/my-account"
              title="Mitt konto"
            />
            <PrivateRoute
              component={Organization}
              path="/my-organization"
              title="Min organisation"
            />
            <PrivateRoute component={Help} path="/help" title="Hjälp" />
            <PrivateRoute
              component={PriceInformation}
              path="/pricing"
              title="Priser"
            />
            <PrivateRoute component={ProductActivation} path="/activation" />
            <PrivateRoute
              component={QualityControl}
              path="/quality-control"
              title="Kvalitetsgranskning"
            />
            <PrivateRoute component={UsersManagement} path="/accounts" />
            <PrivateRoute
              component={Statistics}
              path="/statistics"
              title="Statistik"
            />
          </Switch>
        </ErrorBoundary>
      </Container>
    </div>
  )
}

export default withRouter(MainContent)
