import React, { Fragment } from 'react'
import { Grid, Typography } from '@material-ui/core'
import Paragraphs from './Paragraphs'
import Image from './Image'
import { findDiffForProp } from '../diff'

const ItalicCaption = ({ children, ...props }) => (
  <Typography variant="caption" style={{ fontStyle: 'italic' }} {...props}>
    {children}
  </Typography>
)

const lineThrough = { textDecoration: 'line-through' }

export default function ProductQuestionContent(props) {
  const { diff = {}, parsedDiffs = {}, question } = props
  return (
    <>
      {diff.question && (
        <Typography variant="h6" style={lineThrough}>
          {diff.question[0].lhs}
        </Typography>
      )}
      <Paragraphs variant="h6" gutterBottom>
        {question.question}
      </Paragraphs>
      {diff.image && (
        <>
          {parsedDiffs.previousImage && (
            <ItalicCaption>Borttagen bild</ItalicCaption>
          )}
          <Image style={{ opacity: 0.6 }} image={parsedDiffs.previousImage} />
          {question.image && <ItalicCaption>Ny bild</ItalicCaption>}
        </>
      )}
      {question.image && <Image image={question.image} />}
      <Grid container spacing={2}>
        {question.alternatives.map((alt, index) => {
          const diffs =
            parsedDiffs.alternatives && parsedDiffs.alternatives[index]
          const textDiff = findDiffForProp(diffs, 'text', 4)
          const correctDiff = findDiffForProp(diffs, 'correct', 4)
          return (
            <Fragment key={index}>
              <Grid item xs={9}>
                <Typography>
                  Alternativ {index + 1}:{' '}
                  {textDiff && (
                    <span style={lineThrough}>{textDiff.lhs || ''}</span>
                  )}{' '}
                  {alt.text || ''}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {correctDiff && correctDiff.lhs === true && (
                  <Typography style={lineThrough}>(Rätt svar)</Typography>
                )}
                {alt.correct && <Typography>(Rätt svar)</Typography>}
              </Grid>
            </Fragment>
          )
        })}
      </Grid>
    </>
  )
}
