import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { compose } from 'redux'
import ReactGA from 'react-ga'
import TherapeuticInput from './TherapeuticInput'
import database from '../../database'
import { serverTimestamp } from '../../firebase'

class EditProduct extends Component {
  state = {
    saving: false,
  }

  componentDidMount() {
    const {
      match: {
        params: { therapeuticUid },
      },
    } = this.props
    ReactGA.set({ dimension3: therapeuticUid })
    ReactGA.event({
      category: 'Therapeutic',
      action: 'Edit Start',
      label: therapeuticUid,
    })
  }

  componentWillUnmount() {
    ReactGA.set({ dimension3: null })
  }

  saveTherapeutic = fullProductInfo => {
    const {
      match: {
        params: { therapeuticUid },
      },
    } = this.props
    const therapeuticRef = database.queryTherapeutic(therapeuticUid)
    therapeuticRef
      .update({ ...fullProductInfo, editDateTime: serverTimestamp() })
      .then(() => {
        // const url = getPostSaveUrl(productUid, baseInfo)
        const url = `/therapeutic/${therapeuticUid}/overview`
        ReactGA.event({
          category: 'Therapeutic',
          action: 'Edit Save',
          label: therapeuticUid,
        })
        this.props.history.push(url)
      })
  }

  render() {
    const {
      dirty,
      handleSubmit,
      match: {
        params: { therapeuticUid },
      },
    } = this.props
    const { saving } = this.state
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" color="primary" gutterBottom>
            Redigera terapiområde
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TherapeuticInput storagePath={`/therapeutics/${therapeuticUid}`} />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleSubmit(this.saveTherapeutic)}
            variant="contained"
            color="primary"
            disabled={saving || !dirty}
          >
            Spara
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const enhance = compose(
  withRouter,
  connect((state, ownProps) => ({
    initialValues: state.therapeutics.list.find(
      therapeutic => therapeutic.uid === ownProps.match.params.therapeuticUid
    ),
  })),
  reduxForm({
    // a unique name for the form
    form: 'therapeutic',
    enableReinitialize: true,
  })
)

export default enhance(EditProduct)
