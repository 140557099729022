import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import LockIcon from '@material-ui/icons/LockOutlined'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import { firebaseAuth } from '../firebase'

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
})

function LoginForm(props) {
  const { classes } = props
  return (
    <React.Fragment>
      <Avatar className={classes.avatar}>
        <LockIcon />
      </Avatar>
      <Typography component="h4" variant="h5">
        Logga in
      </Typography>
      <form className={classes.form} onSubmit={props.onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">E-post</InputLabel>
          <Input
            id="email"
            name="email"
            autoComplete="email"
            value={props.email}
            onChange={event => props.onEmailChange(event.target.value)}
            error={!!props.error}
            autoFocus
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Lösenord</InputLabel>
          <Input
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={props.password}
            onChange={event => props.onPasswordChange(event.target.value)}
            error={!!props.error}
          />
        </FormControl>
        <Button onClick={props.onForgotPassword}>Glömt lösenord?</Button>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={props.loading}
        >
          Logga in
        </Button>
      </form>
    </React.Fragment>
  )
}

function ForgotPasswordForm(props) {
  const { classes } = props
  return (
    <React.Fragment>
      <Avatar className={classes.avatar}>
        <LockIcon />
      </Avatar>
      <Typography component="h4" variant="h5">
        Återställ lösenord
      </Typography>
      <form className={classes.form} onSubmit={props.onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">E-post</InputLabel>
          <Input
            id="email"
            name="email"
            autoComplete="email"
            value={props.email}
            onChange={event => props.onEmailChange(event.target.value)}
            error={!!props.error}
            autoFocus
          />
        </FormControl>
        <Button onClick={props.onBack}>Tillbaka</Button>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={props.loading || props.linkSent}
        >
          {props.linkSent ? 'Länk skickad' : 'Skicka länk'}
        </Button>
      </form>
    </React.Fragment>
  )
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      login: true,
      linkSent: false,
      loading: false,
    }
  }

  componentDidUpdate() {
    const { auth, history, location } = this.props
    if (auth.authenticated) {
      if (location.state && location.state.from) {
        history.push(location.state.from.pathname)
      } else {
        history.push('/')
      }
    }
  }

  login = event => {
    event.preventDefault()
    const { email, password } = this.state
    return firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        const getMessageTranslation = errorCode => {
          switch (errorCode) {
            case 'auth/user-not-found':
              return 'Ingen användare hittades'
            case 'auth/wrong-password':
              return 'Fel lösenord'
            case 'auth/invalid-email':
              return 'Ogiltig epost'
            case 'auth/user-disabled':
              return 'Användaren är inaktiverad. Kontakta support på support@apostar.se'
            default:
              return `Kunde inte logga in. Felmeddelande: ${errorMessage}`
          }
        }
        this.setState({
          error: getMessageTranslation(errorCode),
          loading: false,
        })
      })
  }

  resetPassword = event => {
    event.preventDefault()
    const { email } = this.state
    return firebaseAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.setState({ loading: false, linkSent: true })
      })
      .catch(error => this.setState({ loading: false, error: error.message }))
  }

  render() {
    const { error, login } = this.state
    const { classes } = this.props
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {login ? (
            <LoginForm
              onEmailChange={email => this.setState({ email })}
              onPasswordChange={password => this.setState({ password })}
              onSubmit={this.login}
              onForgotPassword={() => this.setState({ login: false })}
              classes={classes}
              {...this.state}
            />
          ) : (
            <ForgotPasswordForm
              onEmailChange={email => this.setState({ email })}
              onSubmit={this.resetPassword}
              onBack={() => this.setState({ login: true })}
              classes={classes}
              {...this.state}
            />
          )}
          {error && <Typography color="error">{error}</Typography>}
        </Paper>
      </main>
    )
  }
}

export default connect(state => ({
  auth: state.auth,
  user: state.user,
}))(withStyles(styles)(Login))
