import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { compose } from 'redux'
import { FormCheckbox } from '../product-input/input-components'
import database from '../../database'

const notificationTypes = [
  {
    field: 'productQuestion',
    label: 'Nya produktfrågor',
  },
  {
    field: 'productEducation',
    label: 'Antal kunskapstest',
  },
  {
    field: 'productQualityControl',
    label: 'Kvalitetsgranskning',
  },
]

const updateUserNotifications = (userUid, notifications) => {
  const updateData = {
    'settings.notifications': notifications,
  }
  return database
    .getDatabase()
    .collection('users')
    .doc(userUid)
    .update(updateData)
}

function NotificationSettings() {
  return (
    <Card>
      <CardHeader title="Notifikationer" />
      <CardContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Skicka e-post för</FormLabel>
          <FormGroup>
            {notificationTypes.map(({ field, label }) => (
              <Field
                key={field}
                name={field}
                label={label}
                type="checkbox"
                component={FormCheckbox}
              />
            ))}
          </FormGroup>
          <FormHelperText>
            För att inte missa viktig information rekommenderar vi att alla
            notifikationer är påslagna
          </FormHelperText>
        </FormControl>
      </CardContent>
    </Card>
  )
}
export default compose(
  connect(state => ({
    initialValues: state.user.settings.notifications,
    user: state.user,
  })),
  reduxForm({
    form: 'notifications',
    enableReinitialize: true,
    onChange: (values, dispatch, { user }) => {
      if (Object.keys(values).length && user) {
        updateUserNotifications(user.uid, values)
      }
    },
  })
)(NotificationSettings)
