import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { database, serverTimestamp } from '../firebase'
import { withUser } from '../actions'

class Conversation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      messages: [],
    }
    this.sendMessage = this.sendMessage.bind(this)
    this.loadMessages = this.loadMessages.bind(this)
  }

  componentDidMount() {
    this.loadMessages()
  }

  componentDidUpdate(prevProps) {
    // only update messages if the conversation has changed
    if (prevProps.conversationId !== this.props.conversationId) {
      if (this.unsubscribe) {
        this.unsubscribe()
      }
      this.loadMessages()
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  loadMessages() {
    this.conversationRef = database
      .collection('questions')
      .doc(this.props.conversationId)
    this.messagesRef = this.conversationRef.collection('messages')
    this.setState({ loading: true })
    this.unsubscribe = this.messagesRef
      .orderBy('creationDateTime', 'asc')
      .onSnapshot(messageSnapshot => {
        this.setState({
          messages: messageSnapshot.docs,
          loading: false,
        })
        this.conversationRef.update({
          supplierLastReadDateTime: serverTimestamp(),
        })
      })
  }

  sendMessage() {
    const message = {
      senderUid: this.props.user.uid,
      message: this.state.message,
      creationDateTime: serverTimestamp(),
    }
    this.messagesRef.add(message)
    this.conversationRef.update({ editDateTime: serverTimestamp() })
    this.setState({ message: '' })
  }

  render() {
    const isMyMessage = message =>
      message.data().senderUid === this.props.user.uid
    const getJustification = message =>
      isMyMessage(message) ? 'flex-end' : 'flex-start'
    const makeMessageStyles = message => ({
      borderRadius: 4,
      padding: 6,
      margin: 6,
      color: '#fff',
      whiteSpace: 'pre-line',
      backgroundColor: isMyMessage(message) ? '#579B63' : '#7f7f7f',
    })
    return (
      <Paper>
        <Grid container direction="column">
          {!this.state.loading ? (
            this.state.messages.map(message => (
              <Grid item key={message.id}>
                <Grid container justify={getJustification(message)}>
                  <Grid item style={makeMessageStyles(message)}>
                    <span>{message.data().message}</span>
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item style={{ padding: 10 }}>
              <CircularProgress />
            </Grid>
          )}
          <Grid container justify="flex-end">
            <Grid item xl={10} xs={9} style={{ padding: 10 }}>
              <TextField
                style={{ border: '1px solid #dfdfdf' }}
                multiline
                rows={2}
                rowsMax={15}
                fullWidth
                value={this.state.message}
                onChange={event =>
                  this.setState({ message: event.target.value })
                }
              />
            </Grid>
            <Grid item xl={2} xs={3} style={{ padding: 10 }}>
              <Button variant="contained" onClick={this.sendMessage}>
                Skicka
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withUser(Conversation)
