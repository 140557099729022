import React, { forwardRef } from 'react'
import MaterialTable from 'material-table'
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  ChevronRight,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from '@material-ui/icons'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const localization = {
  pagination: {
    labelDisplayedRows: '{from}-{to} av {count}',
    labelRowsSelect: 'rader',
    firstTooltip: 'Första sidan',
    lastTooltip: 'Sista sidan',
    nextTooltip: 'Nästa sida',
    previousTooltip: 'Föregående sida',
  },
  toolbar: {
    nRowsSelected: '{0} rader valda',
    searchTooltip: 'Sök',
    searchPlaceholder: 'Sök',
  },
  header: {
    actions: '',
  },
  body: {
    emptyDataSourceMessage: 'Inga rader att visa',
    filterRow: {
      filterTooltip: 'Filter',
    },
  },
}
export default function DataTable(props) {
  return (
    <MaterialTable localization={localization} icons={tableIcons} {...props} />
  )
}
