import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { serverTimestamp } from '../../firebase'
import database from '../../database'
import Paragraphs from '../Paragraphs'

class Discussion extends Component {
  state = {
    message: '',
    opened: false,
    loadingMessages: true,
    messages: [],
  }

  componentDidMount() {
    this.messagesRef = this.props.requestRef.collection('messages')
    this.unsubscribe = this.messagesRef
      .orderBy('creationDateTime', 'asc')
      .onSnapshot(messageSnapshot => {
        const messages = database.getCollectionDocsDataWithUid(messageSnapshot)
        this.setState({
          messages,
          loadingMessages: false,
        })
      })
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  setText = event => {
    this.setState({ message: event.target.value })
  }

  openDiscussion = () => {
    this.setState({ opened: true })
  }

  sendMessage = () => {
    this.setState({ sending: true })
    return this.messagesRef
      .add({
        creationDateTime: serverTimestamp(),
        text: this.state.message,
        senderUid: this.props.user.uid,
      })
      .then(() => {
        this.setState({
          message: '',
          sending: false,
        })
      })
  }

  accept = () => {
    this.setState({ accepting: true })
    const { onAccept } = this.props
    if (onAccept) {
      new Promise(resolve => {
        if (this.state.message) {
          const { message } = this.state
          this.sendMessage().then(() => {
            resolve(message)
          })
        } else {
          resolve('Se diskussion')
        }
      }).then(message => {
        onAccept(message)
      })
    }
  }

  render() {
    const {
      message,
      messages,
      sending,
      accepting,
      opened,
      loadingMessages,
    } = this.state
    const { user, pharmacy, onAccept, approved, comment } = this.props
    const hasDiscussion = Boolean(messages.length) || opened
    const loading =
      loadingMessages || !user.uid || (user.isSupplierAdmin() && !pharmacy)
    if (loading) {
      return <CircularProgress />
    }
    if (!hasDiscussion && comment) {
      return (
        <Button variant="contained" onClick={this.openDiscussion}>
          Öppna diskussion
        </Button>
      )
    }
    if (hasDiscussion) {
      const getUserDisplayName = message => {
        const isMyMessage = message.senderUid === user.uid
        if (isMyMessage) {
          return user.firstname
        }
        if (user.isSupplierAdmin()) {
          return pharmacy.name
        }
        return 'Leverantör'
      }
      const isQualityController = user.isSuperAdmin() || user.isPharmacyAdmin()
      return (
        <div style={{ maxWidth: 500 }}>
          <Typography variant="h6">Diskussion</Typography>
          {messages.map((message, index) => {
            const isMyMessage = message.senderUid === user.uid
            return (
              <div key={index}>
                <Typography variant="body1" color="primary">
                  {getUserDisplayName(message)}
                </Typography>
                <Paragraphs
                  style={{ color: isMyMessage ? 'inherit' : '#333333' }}
                >
                  {message.text}
                </Paragraphs>
              </div>
            )
          })}
          <div>
            <TextField
              multiline
              rows={2}
              rowsMax={15}
              value={message}
              onChange={this.setText}
              disabled={sending}
              variant="outlined"
              fullWidth
              style={{ marginBottom: 8 }}
            />
            <Button
              variant="contained"
              diabled={sending || accepting}
              style={{ marginRight: 8 }}
              onClick={this.sendMessage}
            >
              Skicka
            </Button>
            {isQualityController && onAccept && !approved && (
              <Button
                disabled={sending || accepting}
                onClick={this.accept}
                variant="contained"
                color="primary"
              >
                Godkänn
              </Button>
            )}
            {(sending || accepting) && <CircularProgress />}
          </div>
        </div>
      )
    }
    return null
  }
}
export default connect(state => ({
  user: state.user,
}))(Discussion)
