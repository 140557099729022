import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'

const PrivateRoute = ({
  component: Component,
  render: actualRender,
  authenticated,
  title,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const { location, match } = props
      const { productId, productUid } = match.params
      if (productId || productUid) {
        ReactGA.set({ dimension3: productId || productUid })
      } else {
        ReactGA.set({ dimension3: null })
      }
      ReactGA.set({ page: location.pathname + location.search })
      ReactGA.pageview(location.pathname + location.search)
      document.title = title ? `Apostar - ${title}` : 'Apostar'
      if (authenticated) {
        return actualRender ? (
          actualRender(props)
        ) : (
          <React.Fragment>
            <Component {...props} />
          </React.Fragment>
        )
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )
    }}
  />
)
export default connect(state => ({
  authenticated: state.auth.authenticated,
}))(PrivateRoute)
