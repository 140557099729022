import React from 'react'
import { Redirect, Switch, withRouter } from 'react-router-dom'
import PrivateRoute from '../../PrivateRoute'
import TherapeuticOverview from './TherapeuticOverview'
import TherapeuticEdit from '../product-input/TherapeuticEdit'
import TherapeuticPartAdd from '../product-input/TherapeuticPartAdd'
import TherapeuticPartEdit from '../product-input/TherapeuticPartEdit'

function Therapeutic(props) {
  const {
    match: {
      params: { therapeuticUid },
    },
  } = props
  return (
    <Switch>
      <Redirect
        exact
        from="/therapeutic/:therapeuticUid"
        to={`/therapeutic/${therapeuticUid}/overview`}
      />
      <PrivateRoute
        path="/therapeutic/:therapeuticUid/overview"
        component={TherapeuticOverview}
      />
      <PrivateRoute
        path="/therapeutic/:therapeuticUid/edit"
        component={TherapeuticEdit}
      />
      <PrivateRoute
        path="/therapeutic/:therapeuticUid/add-part"
        component={TherapeuticPartAdd}
      />
      <PrivateRoute
        path="/therapeutic/:therapeuticUid/part/:partUid/edit"
        component={TherapeuticPartEdit}
      />
    </Switch>
  )
}

export default withRouter(Therapeutic)
