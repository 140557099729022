import React, { Component } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link, Redirect, Switch } from 'react-router-dom'
import PrivateRoute from '../../PrivateRoute'
import { loadTherapeuticParts } from '../../actions'
import TherapeuticPartOverview from './TherapeuticPartOverview'

const styles = theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  navigation: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
})

function a11yProps(partUid) {
  return {
    id: `scrollable-auto-tab-${partUid}`,
    'aria-controls': `scrollable-auto-tabpanel-${partUid}`,
  }
}

class TherapeuticPartsOverview extends Component {
  state = {}

  componentDidMount() {
    const {
      loadTherapeuticParts,
      initialized,
      loading,
      match: {
        params: { therapeuticUid },
      },
    } = this.props
    if (!(initialized || loading)) {
      loadTherapeuticParts(therapeuticUid)
    }
    if (!this.isBaseOverview()) {
      this.setPathUidFromUrl()
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    if (
      prevProps.location.pathname !== location.pathname &&
      !this.isBaseOverview()
    ) {
      this.setPathUidFromUrl()
    }
  }

  isBaseOverview = () => this.props.location.pathname.endsWith('overview')

  setPathUidFromUrl = () => {
    const urlParts = this.props.location.pathname.split('/')
    const partUid = urlParts[urlParts.length - 1]
    this.setState({ partUid })
  }

  render() {
    const {
      therapeuticParts,
      therapeutic,
      loading,
      initialized,
      match: {
        params: { therapeuticUid },
      },
      classes,
    } = this.props
    if (!initialized || loading) {
      return <LinearProgress />
    }
    const { partUid } = this.state
    const activePart = therapeuticParts.find(part => part.uid === partUid)
    const activePartIndex = activePart ? activePart.index : 0
    if (therapeuticParts.length === 0) {
      return null
    }
    return (
      <div className={classes.root}>
        {therapeutic && therapeutic.partsCount > 1 && (
          <Paper>
            <Tabs
              value={activePartIndex}
              aria-label="Vertical tabs example"
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {therapeuticParts.map(part => (
                <Tab
                  key={part.uid}
                  component={Link}
                  to={`/therapeutic/${therapeuticUid}/overview/part/${part.uid}`}
                  label={`Del ${part.index + 1}`}
                  {...a11yProps(part.uid)}
                />
              ))}
            </Tabs>
          </Paper>
        )}
        <Switch>
          <Redirect
            exact
            from={`/therapeutic/${therapeuticUid}/overview`}
            to={`/therapeutic/${therapeuticUid}/overview/part/${therapeuticParts[0].uid}`}
          />
          <PrivateRoute
            path="/therapeutic/:therapeuticUid/overview/part/:partUid"
            render={TherapeuticPartOverview}
          />
        </Switch>
      </div>
    )
  }
}

const enhance = compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const { therapeuticUid } = ownProps
      const parts = state.therapeuticParts[therapeuticUid]
      return {
        therapeutic: state.therapeutics.list.find(
          therapeutic => therapeuticUid === therapeutic.uid
        ),
        therapeuticParts: parts ? parts.list : [],
        initialized: parts && parts.initialized,
        loading: parts && parts.loading,
      }
    },
    { loadTherapeuticParts }
  ),
  withStyles(styles)
)
export default enhance(TherapeuticPartsOverview)
