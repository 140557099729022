import React from 'react'
import {
  LinearProgress,
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableFooter,
  TableBody,
  Typography,
  Button,
} from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import { functionsEurope } from '../../firebase'
import database from '../../database'

const getSupplierBillingReport = functionsEurope.httpsCallable(
  'analytics-getSupplierBillingReport'
)
function Billing(props) {
  const { supplierUid, dateRange, excludedBillingProducts } = props
  const [billingReports, setBillingReports] = React.useState([])
  const [suppliers, setSuppliers] = React.useState({})
  const [isLoading, setLoading] = React.useState(false)
  const excludedBillingProductUids = Object.keys(
    excludedBillingProducts
  ).filter(uid => excludedBillingProducts[uid])
  const updateBillingReports = () => {
    setLoading(true)
    getSupplierBillingReport({ dateRange, excludedBillingProductUids }).then(
      result => {
        const billingReports = supplierUid
          ? result.data.filter(report => report.supplierUid === supplierUid)
          : result.data
        setBillingReports(billingReports)
        setLoading(false)
      }
    )
    database
      .getDatabase()
      .collection('suppliers')
      .get()
      .then(database.getCollectionDocsDataWithUid)
      .then(suppliers => {
        const keyedByUid = _.keyBy(suppliers, 'uid')
        setSuppliers(keyedByUid)
      })
  }
  React.useEffect(updateBillingReports, [supplierUid, dateRange])

  if (isLoading) {
    return <LinearProgress />
  }
  const billingReportComponents = billingReports.map(supplierBilling => {
    const supplier = suppliers[supplierBilling.supplierUid] || {}
    return (
      <div style={{ marginBottom: 16 }}>
        <Typography gutterBottom>
          {supplier.name}
          {supplier.legacyPricing && ' - GAMMAL PRISMODELL'}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Typ</TableCell>
              <TableCell align="right">Antal</TableCell>
              <TableCell align="right">Styckpris</TableCell>
              <TableCell align="right">Summa</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supplierBilling.invoiceRows.map(row => (
              <TableRow>
                <TableCell>
                  {
                    // Hard coding everything like there's no tomorrow
                  }
                  {row.name} {[5, 30].includes(row.price) && 'studenter'}
                  {[10, 60].includes(row.price) && 'apotekspersonal'}
                  {row.price === 0 && 'övriga'}
                </TableCell>
                <TableCell align="right">{row.count}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
                <TableCell align="right">{row.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            {Object.entries(supplierBilling.totals).map(([name, total]) => (
              <TableRow>
                <TableCell>Totalt {name}</TableCell>
                <TableCell align="right" colSpan={3}>
                  {total}{' '}
                  {name === 'tests' &&
                    supplier.testsPriceLimit &&
                    `(tak ${supplier.testsPriceLimit})`}
                </TableCell>
              </TableRow>
            ))}
          </TableFooter>
        </Table>
      </div>
    )
  })
  return [
    <Button color="primary" variant="outlined" onClick={updateBillingReports}>
      Uppdatera
    </Button>,
    ...billingReportComponents,
  ]
}
export default connect(state => ({
  dateRange: state.statistics.dateRange,
  billing: state.statistics.billing,
  excludedBillingProducts: state.statistics.excludedBillingProducts,
}))(Billing)
