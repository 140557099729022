import firebase from 'firebase/app'
import { configDev } from './config'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/performance'
import 'firebase/analytics'

const config = configDev
const app = firebase.initializeApp(config)
const firebaseAuth = firebase.auth()
const database = firebase.firestore()
const storage = firebase.storage()
const performance = firebase.performance()
const functions = firebase.functions()
const analytics = firebase.analytics()
const functionsEurope = app.functions('europe-west1')
const functionsUrl = config.functionsURL
const { FieldValue } = firebase.firestore
const { serverTimestamp, delete: deleteField, increment } = FieldValue
const { EmailAuthProvider } = firebase.auth

const useEmulators = false
// if (location.hostname === 'localhost') {
if (useEmulators) {
  database.settings({
    host: 'localhost:8080',
    ssl: false,
  })
  functionsEurope.useFunctionsEmulator('http://localhost:5001')
}

export {
  firebaseAuth,
  database,
  storage,
  functionsUrl,
  FieldValue,
  serverTimestamp,
  deleteField,
  increment,
  functions,
  functionsEurope,
  performance,
  EmailAuthProvider,
  analytics,
}
