import React from 'react'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ImageIcon from '@material-ui/icons/Image'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/styles'
import { storage } from '../../firebase'
import ImageUpload from '../ImageUpload'
import Image from '../Image'
import VideoUpload from '../VideoUpload'
import { uploadImage } from '../../StorageUtils'
import SimpleSelect from '../SimpleSelect'

const imageStyles = theme => ({
  wrapper: {
    position: 'relative',
    display: 'inline-block',
    marginBottom: theme.spacing(2),
  },
  icon: { position: 'absolute', right: 0, top: 0 },
})

const deleteImageFile = image => storage.ref(image.storageRef).delete()

const FormRemovableImage = withStyles(imageStyles)(
  ({ input: { onChange, value }, classes }) =>
    value ? (
      <div className={classes.wrapper}>
        <Image
          image={value}
          alt="Beskrivande bild"
          className={classes.image}
          // src={value.url}
          // title={value.name}
        />
        <IconButton
          className={classes.icon}
          color="inherit"
          onClick={() => {
            onChange(null)
            deleteImageFile(value)
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    ) : null
)

const FormTextField = ({
  input,
  label,
  meta: { touched, error },
  helperText,
  ...custom
}) => (
  <TextField
    label={label}
    error={touched && Boolean(error)}
    variant="outlined"
    {...input}
    {...custom}
    helperText={helperText || (touched && error)}
  />
)

const FormImageUpload = ({ input: { onChange }, storagePath, ...custom }) => (
  <ImageUpload
    onImageSelect={imageFile =>
      uploadImage(`${storagePath}/images`, imageFile).then(onChange)
    }
    buttonText="Ladda upp bild"
    icon={<ImageIcon />}
    {...custom}
  />
)

const FormSelect = ({
  input: { value, onChange },
  options,
  label,
  field,
  meta: { touched, error },
  ...custom
}) => (
  <SimpleSelect
    name={label}
    selected={value}
    options={options}
    onSelect={onChange}
    field={field}
    error={touched && error}
    {...custom}
  />
)
const FormSwitch = ({ input, label, style, ...custom }) => (
  <FormControlLabel
    // style={{ marginRight: 0, paddingLeft: 4 }}
    style={style || {}}
    control={
      <Switch
        onChange={input.onChange}
        value={input.name}
        checked={input.checked}
        {...custom}
      />
    }
    label={label}
  />
)

const FormVideoUpload = ({ input: { onChange }, storagePath }) => (
  <VideoUpload
    storagePath={`${storagePath}/video`}
    onUpload={onChange}
    style={{ marginBottom: 16 }}
  />
)

const FormCheckbox = ({ input, label, style, ...custom }) => (
  <FormControlLabel
    // style={{ marginRight: 0, paddingLeft: 4 }}
    style={style || {}}
    control={
      <Checkbox
        onChange={input.onChange}
        value={input.name}
        checked={input.checked}
        {...custom}
      />
    }
    label={label}
  />
)

export {
  FormRemovableImage,
  FormTextField,
  FormSelect,
  FormSwitch,
  FormVideoUpload,
  FormImageUpload,
  FormCheckbox,
}
