import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'

class RequestResponseInput extends Component {
  state = {
    comment: '',
  }

  accept = () => {
    if (window.confirm('Vill du verkligen godkänna utbildningen?')) {
      this.props.onAccept(this.state.comment)
    }
  }

  deny = () => {
    if (this.state.comment) {
      this.setState({ error: null })
      if (window.confirm('Vill du verkligen begära komplettering?')) {
        this.props.onDeny(this.state.comment)
      }
    } else {
      this.setState({
        error: 'Du måste skriva en kommentar om varför komplettering behövs',
      })
    }
  }

  render() {
    const { sending, showComment, disabled, onAccept, onDeny } = this.props
    const { comment, error } = this.state
    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          {showComment && (
            <TextField
              style={{ marginTop: 16, marginBottom: 16 }}
              multiline
              variant="outlined"
              label="Kommentar"
              fullWidth
              helperText="Om du begär komplettering, motivera varför och skriv konkreta förslag på ändringar"
              disabled={disabled || sending}
              value={comment}
              onChange={event => this.setState({ comment: event.target.value })}
            />
          )}
          <br />
          {onAccept && (
            <Button
              style={{ marginRight: 16 }}
              variant="contained"
              color="primary"
              onClick={this.accept}
              disabled={disabled || sending}
            >
              Godkänn
            </Button>
          )}
          {onDeny && (
            <Button
              style={{ marginRight: 16 }}
              variant="contained"
              color="secondary"
              onClick={this.deny}
              disabled={disabled || sending}
            >
              Begär komplettering
            </Button>
          )}
          {sending && <CircularProgress />}
          {error && <Typography color="error">{error}</Typography>}
        </Grid>
      </Grid>
    )
  }
}
export default RequestResponseInput
