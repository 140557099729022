import React from 'react'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import moment from 'moment'

const PASSED = 'Passed'
const FAILED = 'Failed'
const STARTED = 'Started'
const CLICK = 'Selected'

const dateFormat = 'YYYY-MM-DD'

const durationMinutesAndSeconds = duration =>
  duration ? `${duration.minutes()}m ${duration.seconds()}s` : 'N/A'

const defaultDateRange = {
  from: moment()
    .subtract(1, 'month')
    .format(dateFormat),
  to: moment()
    .subtract(1, 'day')
    .format(dateFormat),
}

const styles = theme => ({
  section: {
    marginBottom: theme.spacing(5),
  },
})

const dateRangeOptions = [
  {
    value: 'month',
    name: 'Månad',
  },
  {
    value: 'quarter',
    name: 'Kvartal',
  },
  {
    value: 'total',
    name: 'Totalt',
  },
]

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

function parseWeeklyReportRows(rows) {
  return !rows
    ? []
    : rows.map(row => {
        const [week, category, action, pharmacyUid] = row.dimensions
        const count = parseInt(row.metrics[0].values[0], 10)
        const prevCount = row.metrics[1]
          ? parseInt(row.metrics[1].values[0], 10)
          : 0
        const delta = count / prevCount - 1
        return {
          week: parseInt(week, 10),
          category,
          action,
          pharmacyUid,
          count,
          prevCount,
          delta,
        }
      })
}

function countEducationTotalsFromWeekly(report, pharmacies = []) {
  const pharmacyCostMap = pharmacies.reduce(
    (res, pharmacy) => ({
      ...res,
      [pharmacy.uid]: _.isNumber(pharmacy.userEducationCost)
        ? pharmacy.userEducationCost
        : 1,
    }),
    {}
  )
  const rows = report.data.rows || []
  const parsedRows = parseWeeklyReportRows(rows)
  const rowsWithCost = parsedRows.map(row => ({
    ...row,
    cost: row.count * pharmacyCostMap[row.pharmacyUid],
  }))
  const groupedByAction = _.groupBy(rowsWithCost, 'action')
  const sumByAction = (action, field = 'count') =>
    _.sumBy(groupedByAction[action], field)

  const actions = {
    passed: PASSED,
    failed: FAILED,
    started: STARTED,
    clicks: CLICK,
  }
  const totals = _.mapValues(actions, action => sumByAction(action))
  const deltas = _.mapValues(
    actions,
    action => sumByAction(action) / sumByAction(action, 'prevCount') - 1
  )
  return {
    totals,
    deltas,
    certsUsed: sumByAction(PASSED, 'cost'),
  }
}

function makeTableData(report, products) {
  const rows = report.data.rows || []
  const parsedRows = rows.map(row => {
    const [productUid, category, action] = row.dimensions
    const count = parseInt(row.metrics[0].values[0], 10)
    return {
      productUid,
      category,
      action,
      count,
    }
  })
  const rowsForProducts = _.groupBy(parsedRows, 'productUid')
  const tableData = products.map(product => {
    const productRows = rowsForProducts[product.uid]
    const emptyStats = {
      passedCount: 0,
      failedCount: 0,
      startedCount: 0,
      clickCount: 0,
    }
    const counts = !productRows
      ? emptyStats
      : productRows.reduce((stats, row) => {
          switch (row.action) {
            case PASSED:
              return { ...stats, passedCount: row.count }
            case FAILED:
              return { ...stats, failedCount: row.count }
            case STARTED:
              return { ...stats, startedCount: row.count }
            case CLICK:
              return { ...stats, clickCount: row.count }
            default:
              return stats
          }
        }, emptyStats)
    const passFailRatio = (counts.passedCount / counts.startedCount) * 100 || 0
    return {
      ...product,
      ...counts,
      passFailRatio,
    }
  })
  return tableData
}

function makeWeeklyLineChartData(report, dateRange) {
  const from = moment(dateRange.from)
  const to = moment(dateRange.to)
  const weekDiff = to.diff(from, 'weeks') + 1
  const weekDates = _.times(weekDiff).map(weekOffset =>
    moment(dateRange.from).add(weekOffset, 'weeks')
  )
  if (_.last(weekDates).isoWeek() !== to.isoWeek()) {
    weekDates.push(to)
  }
  const emptyDataPoints = weekDates.map(weekDate => ({
    date: weekDate,
    week: weekDate.isoWeek(),
    weekLabel: `Vecka ${weekDate.isoWeek()}`,
    passedCount: 0,
    failedCount: 0,
    startedCount: 0,
    clickCount: 0,
  }))
  const rows = report.data.rows || []
  const parsedRows = parseWeeklyReportRows(rows)
  const groupedByWeek = _.groupBy(parsedRows, 'week')
  const chartData = emptyDataPoints.map(emptyDataPoint => {
    const weekPoints = groupedByWeek[emptyDataPoint.week]
    if (weekPoints) {
      const groupedByAction = _.groupBy(weekPoints, 'action')
      const sumByAction = action => _.sumBy(groupedByAction[action], 'count')
      return {
        ...emptyDataPoint,
        passedCount: sumByAction(PASSED),
        failedCount: sumByAction(FAILED),
        startedCount: sumByAction(STARTED),
        clickCount: sumByAction(CLICK),
      }
    }
    return emptyDataPoint
  })
  return chartData
}

function parsePharmacyRows(rows) {
  return !rows
    ? []
    : rows.map(row => {
        const [action, pharmacyUid] = row.dimensions
        const count = parseInt(row.metrics[0].values[0], 10)
        return {
          action,
          pharmacyUid,
          count,
        }
      })
}

function makePieChartData(report, pharmacies) {
  const rows = report.data.rows || []
  const parsedRows = parsePharmacyRows(rows)
  const groupedByPharmacy = _.groupBy(parsedRows, 'pharmacyUid')
  const emptyStats = {
    passedCount: 0,
    clickCount: 0,
  }
  const pieData = pharmacies.map(pharmacy => {
    const counts = !groupedByPharmacy[pharmacy.uid]
      ? emptyStats
      : groupedByPharmacy[pharmacy.uid].reduce((stats, row) => {
          switch (row.action) {
            case PASSED:
              return { ...stats, passedCount: row.count }
            case CLICK:
              return { ...stats, clickCount: row.count }
            default:
              return stats
          }
        }, emptyStats)
    return {
      ...pharmacy,
      ...counts,
    }
  })
  return pieData
}

function makeAvgReadTime(report) {
  const rows = report.data.rows || []
  const parsedRows = rows.map(row => {
    const avgReadTime = moment.duration(
      parseInt(row.metrics[0].values[0], 10),
      'seconds'
    )
    const productUid = row.dimensions[0]
    return {
      productUid,
      avgReadTime,
    }
  })
  const total = moment.duration(
    parseInt(report.data.totals[0].values[0], 10),
    'seconds'
  )
  const keyByProduct = _.keyBy(parsedRows, 'productUid')
  return {
    ...keyByProduct,
    total,
  }
}

function makeGeoData(report) {
  const rows = report.data.rows || []
  const parsedRows = rows.map(row => {
    const [regionIdStr] = row.dimensions
    const [events, users] = row.metrics[0].values
    return {
      regionId: regionIdStr,
      countyCode: parseInt(regionIdStr.substring(3, 5), 10) + 1,
      events: parseInt(events, 10),
      users: parseInt(users, 10),
    }
  })
  return _.keyBy(parsedRows, 'countyCode')
}

function makeQuestionsData(report) {
  const rows = report.data.rows || []
  const parsedRows = rows.map(row => {
    const [rawQuestion] = row.dimensions
    const question = decodeURIComponent(rawQuestion)
    const [questionCountStr, correctCountStr] = row.metrics[0].values
    const questionCount = parseInt(questionCountStr, 10)
    const correctCount = parseInt(correctCountStr, 10)
    return {
      question,
      questionCount,
      correctCount,
    }
  })
  const groupedByQuestion = _.groupBy(parsedRows, 'question')
  const averageCorrect = Object.keys(groupedByQuestion).reduce(
    (questions, question) => {
      const questionRows = groupedByQuestion[question]
      const questionCount = _.sumBy(questionRows, 'questionCount')
      const correctCount = _.sumBy(questionRows, 'correctCount')
      return {
        ...questions,
        [question]: {
          questionCount,
          correctCount,
          avgCorrect: correctCount / questionCount,
        },
      }
    },
    {}
  )
  return averageCorrect
}

function makeQuestionsDataArray(report) {
  const questionsObj = makeQuestionsData(report)
  return Object.entries(questionsObj).map(([question, data]) => ({
    ...data,
    title: question,
  }))
}

function withData(Component, predicate) {
  return ({ data, ...rest }) => {
    if (!data || _.isEmpty(data) || (predicate && !predicate(data))) {
      return (
        <Typography
          variant="h6"
          style={{ fontStyle: 'italic', textAlign: 'center' }}
        >
          Data saknas
        </Typography>
      )
    }
    return <Component data={data} {...rest} />
  }
}

export {
  makePieChartData,
  makeTableData,
  makeWeeklyLineChartData,
  makeQuestionsData,
  makeQuestionsDataArray,
  makeAvgReadTime,
  makeGeoData,
  countEducationTotalsFromWeekly,
  stableSort,
  getSorting,
  defaultDateRange,
  dateRangeOptions,
  dateFormat,
  styles,
  withData,
  durationMinutesAndSeconds,
}
