import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import TextField from '@material-ui/core/TextField'

const getDataToUpdate = (fields, data) =>
  fields.reduce((updateObj, { prop }) => {
    const newFieldData = data[prop]
    if (typeof newFieldData === 'string')
      return { ...updateObj, [prop]: newFieldData }
    return updateObj
  }, {})

const InfoSegment = ({ label, value }) => {
  const variant = value ? 'body2' : 'caption'
  const text = value || 'Information saknas'
  const styles = { fontStyle: value ? 'normal' : 'italic' }
  return (
    <div>
      <Typography variant="body1">{label}</Typography>
      <Typography style={styles} variant={variant}>
        {text}
      </Typography>
      <br />
    </div>
  )
}

class EditableFields extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editData: this.props.fieldsData,
      isEditing: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.fieldsData !== prevProps.fieldsData) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        editData: this.props.fieldsData,
      })
    }
  }

  updateField = prop => event => {
    const { value } = event.target
    this.setState(state => ({
      editData: { ...state.editData, [prop]: value },
    }))
  }

  setEditing = isEditing => () => {
    this.setState({
      isEditing,
    })
  }

  render() {
    const { fields, title, onSave } = this.props
    return (
      <Card>
        <CardHeader
          title={
            <span>
              {title}{' '}
              {!this.state.isEditing ? (
                <EditIcon onClick={this.setEditing(true)} />
              ) : null}
            </span>
          }
        />
        <CardContent>
          {this.state.isEditing ? (
            <form>
              {fields.map(field => (
                <div key={field.prop}>
                  <TextField
                    margin="normal"
                    label={field.label}
                    value={this.state.editData[field.prop]}
                    onChange={this.updateField(field.prop)}
                  />
                </div>
              ))}
              <Button
                type="submit"
                style={{ marginRight: 8 }}
                color="primary"
                variant="contained"
                onClick={event =>
                  onSave(event)(this.state.editData).then(
                    this.setEditing(false)
                  )
                }
              >
                Spara
              </Button>
              <Button variant="contained" onClick={this.setEditing(false)}>
                Avbryt
              </Button>
            </form>
          ) : (
            fields.map(field => (
              <InfoSegment
                key={field.prop}
                label={field.label}
                value={this.props.fieldsData[field.prop]}
              />
            ))
          )}
        </CardContent>
      </Card>
    )
  }
}
export default EditableFields
export { getDataToUpdate }
