import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grade from '@material-ui/icons/Grade'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import LinearProgress from '@material-ui/core/LinearProgress'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import moment from 'moment'
import databaseManager from '../../database'
import { withUser } from '../../actions'

class UnansweredRequests extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      approvalPeriodDays: 14,
      user: null,
    }
  }

  componentDidMount() {
    const { user } = this.props
    const database = databaseManager.getDatabase()
    if (user.isPharmacyAdmin()) {
      user.pharmacy.get().then(pharmacySnap => {
        this.setState({
          approvalPeriodDays: pharmacySnap.get('approvalPeriodDays') || 14,
        })
      })
      const requestsCollection = user.pharmacy.collection('publicationRequests')
      requestsCollection
        .where('internalApproval.approved', '==', true)
        .where('pharmacyApproval.approved', '==', null)
        .where('cancelled', '==', false)
        .orderBy('creationDateTime')
        .onSnapshot(requestsSnapshot => {
          const requests = databaseManager.getCollectionDocsDataWithUid(
            requestsSnapshot
          )
          this.setState({
            requests,
            loading: false,
          })
        })
    } else if (user.isSuperAdmin()) {
      database
        .collection('publicationRequests')
        .where('internalApproval.approved', '==', null)
        .where('cancelled', '==', false)
        .orderBy('creationDateTime')
        .get()
        .then(requestsSnapshot => {
          const requestGroups = databaseManager.getCollectionDocsDataWithUid(
            requestsSnapshot
          )
          this.setState({
            requests: requestGroups,
            loading: false,
          })
        })
    }
  }

  render() {
    const { requests, loading } = this.state
    const { user } = this.props
    return (
      <div>
        <Card>
          <CardHeader title="Att granska" />
          <CardContent>
            {loading ? (
              <LinearProgress />
            ) : (
              <div>
                {requests.length ? (
                  <List>
                    {requests.map(request => {
                      let requestUrl = ''
                      let creationDateTime = moment()
                      if (user.isSuperAdmin()) {
                        creationDateTime = moment(
                          request.creationDateTime.toDate()
                        )
                        requestUrl = `request-group/${request.uid}`
                      } else if (user.isPharmacyAdmin()) {
                        creationDateTime = moment(
                          request.internalApproval.dateTime.toDate()
                        )
                        requestUrl = `request/${request.uid}/${request.pharmacyUid}`
                      }
                      const expirationDate = creationDateTime.add(
                        this.state.approvalPeriodDays,
                        'days'
                      )
                      const timeToExpiration = expirationDate.diff(
                        moment(),
                        'days'
                      )
                      const secondary = (
                        <Fragment>
                          {request.requests && (
                            <span>
                              {request.requests
                                .map(request => request.pharmacyName)
                                .join(' ')}
                              <br />
                            </span>
                          )}
                          {request.diff && (
                            <span>
                              Komplettering
                              <br />
                            </span>
                          )}
                          <span>{`Godkänns automatiskt om ${timeToExpiration} dagar`}</span>
                        </Fragment>
                      )
                      return (
                        <Link to={requestUrl} key={request.uid}>
                          <ListItem className="productList">
                            <ListItemAvatar>
                              {request.product.image ? (
                                <Avatar>
                                  <img
                                    alt="Produktbild"
                                    src={request.product.image.url}
                                  />
                                </Avatar>
                              ) : (
                                <Grade />
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              primary={request.product.name}
                              secondary={secondary}
                            />
                          </ListItem>
                          <li>
                            <Divider variant="inset" />
                          </li>
                        </Link>
                      )
                    })}
                  </List>
                ) : (
                  <Typography>Det finns inget att granska</Typography>
                )}
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    )
  }
}
export default withUser(UnansweredRequests)
