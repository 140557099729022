import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'
import ProductTable from '../ProductTable'
import { Permission } from '../../constants'

const rows = [
  {
    id: 'name',
    disablePadding: false,
    label: 'Produkt',
  },
  {
    id: 'published',
    disablePadding: true,
    label: 'Publicerad',
  },
  {
    id: 'editDateTime',
    disablePadding: true,
    label: 'Senast ändrad',
  },
  {
    id: 'partsCount',
    disablePadding: true,
    label: 'Delar',
  },
]

function Therapeutics(props) {
  const { user, initialized, therapeutics } = props

  const writePermission = user
    ? user.isSupplierAdmin() && user.hasPermission(Permission.THERAPEUTICS)
    : false
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h4" color="primary" gutterBottom>
            Terapiområden
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {writePermission && (
            <Link to="therapeutic-add" style={{ float: 'right' }}>
              <Button variant="contained" color="primary">
                Lägg till terapiområde
              </Button>
            </Link>
          )}
        </Grid>
        {!initialized ? (
          <LinearProgress />
        ) : (
          <Fragment>
            {!therapeutics.length ? (
              <Typography>
                Det finns inga terapiområdesutbildningar. Skapa en nu!
              </Typography>
            ) : (
              <Fragment>
                <Grid item xs={12}>
                  <ProductTable
                    products={therapeutics}
                    // writePermission={writePermission}
                    rows={rows}
                    orderBy="editDateTime"
                    baseUrl="therapeutic"
                  />
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}
      </Grid>
    </Fragment>
  )
}
export default connect(state => ({
  therapeutics: state.therapeutics.list,
  therapeuticsGrouped: state.therapeutics.grouped,
  initialized: state.therapeutics.initialized,
  user: state.user,
}))(Therapeutics)
