const PRODUCT_TYPES = {
  PRODUCT: 10,
  SERIES_PRODUCT: 11,
  SERIES: 20,
  GENERIC: 30,
}

const CategoryGroup = {
  SELF_CARE: 'selfcare',
  HEALTH_CARE: 'healthcare',
}

const Permission = {
  THERAPEUTICS: 'therapeutics',
  ADMIN: 'admin',
  SUPERADMIN: 'superadmin',
}

export { PRODUCT_TYPES, CategoryGroup, Permission }
