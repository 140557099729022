import React, { Component } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { DesktopAccessDisabled } from '@material-ui/icons'
import DataTable from './DataTable'
import ImageUpload from './ImageUpload'
import database from '../database'
import Invites from './Invites'
import { uploadImage } from '../StorageUtils'
import { serverTimestamp } from '../firebase'
import { loadInvites } from '../actions'

const emptySupplier = {
  name: '',
  minTestsPriceLimit: 10000,
}

function SupplierForm() {
  const [newSupplier, setNewSupplier] = React.useState(
    Object.assign({}, emptySupplier)
  )
  const [saving, setSaving] = React.useState(false)
  const handleNewSupplierUpdate = (supplierField, value) =>
    setNewSupplier({ ...newSupplier, [supplierField]: value })

  const uploadLogo = imageFile =>
    uploadImage('logos/', imageFile).then(image =>
      handleNewSupplierUpdate('logoUrl', image.url)
    )

  const addSupplier = event => {
    event.preventDefault()
    const fullSupplier = {
      ...newSupplier,
      creationDateTime: serverTimestamp(),
      allowCoins: true,
    }
    if (fullSupplier.name) {
      setSaving(true)
      database
        .getDatabase()
        .collection('suppliers')
        .add(fullSupplier)
        .then(() => {
          setNewSupplier(Object.assign({}, emptySupplier))
          setSaving(false)
        })
    }
  }

  return (
    <form onSubmit={addSupplier} style={{ marginTop: 24 }}>
      <Grid container spacing={3}>
        <Grid item>
          <TextField
            value={newSupplier.name}
            onChange={event =>
              handleNewSupplierUpdate('name', event.target.value)
            }
            label="Namn"
          />
        </Grid>
        <Grid item>
          <TextField
            value={newSupplier.minTestsPriceLimit}
            type="number"
            onChange={event =>
              handleNewSupplierUpdate(
                'minTestsPriceLimit',
                parseInt(event.target.value, 10)
              )
            }
            label="Minsta valbara maxtak"
          />
        </Grid>
        <Grid item>
          <TextField
            value={newSupplier.testsPriceLimit}
            type="number"
            onChange={event =>
              handleNewSupplierUpdate(
                'testsPriceLimit',
                parseInt(event.target.value, 10)
              )
            }
            helperText="Ifall leverantör begärt tak"
            label="Maxtak kunskapstest"
          />
        </Grid>
        <Grid item>
          <ImageUpload onImageSelect={uploadLogo} />
          {newSupplier.logoUrl && (
            <img
              alt="Logo"
              src={newSupplier.logoUrl}
              style={{ maxHeight: 100, display: 'block' }}
            />
          )}
        </Grid>
        <Grid item>
          <Button
            disabled={saving || !newSupplier.name}
            variant="contained"
            color="primary"
            type="submit"
          >
            Lägg till
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

class UsersManagement extends Component {
  state = { users: null }

  componentDidMount() {
    const { user } = this.props
    if (user.isSuperAdmin()) {
      const unsubInvites = this.props.loadInvites()
      const unsubUsers = database
        .getDatabase()
        .collection('users')
        .where('type', '==', 'supplier')
        .orderBy('supplierUid')
        .onSnapshot(usersSnap => {
          const users = database.getCollectionDocsDataWithUid(usersSnap)
          this.setState({ users })
        })
      const unsubSuppliers = database
        .getDatabase()
        .collection('suppliers')
        .orderBy('name')
        .onSnapshot(suppliersSnap => {
          const suppliers = database.getCollectionDocsDataWithUid(suppliersSnap)
          this.setState({ suppliers })
        })
      this.unsubscribers = [unsubInvites, unsubUsers, unsubSuppliers]
    }
  }

  render() {
    const { users, suppliers } = this.state
    const supplierOptions = suppliers
      ? suppliers.map(supplier => ({
          value: supplier.uid,
          name: supplier.name,
        }))
      : []
    return (
      <React.Fragment>
        <Invites suppliers={supplierOptions} />

        {!(users && suppliers) ? (
          <LinearProgress />
        ) : (
          <DataTable
            style={{ marginTop: 24 }}
            title="Administratörer"
            options={{
              filtering: true,
            }}
            actions={[
              rowData => ({
                icon: DesktopAccessDisabled,
                tooltip: 'Inaktivera användare',
                disabled: rowData.disabled,
                onClick: (_, rowData) => {
                  if (
                    window.confirm(
                      `Vill du verkligen inaktivera användaren ${rowData.email}?`
                    )
                  ) {
                    const { uid } = rowData
                    return database
                      .getDatabase()
                      .collection('users')
                      .doc(uid)
                      .update({ disabled: true })
                  }
                  return Promise.resolve()
                },
              }),
            ]}
            columns={[
              {
                title: 'Förnamn',
                field: 'firstname',
              },
              {
                title: 'Efternamn',
                field: 'lastname',
              },
              {
                title: 'Epost',
                field: 'email',
              },
              {
                title: 'Leverantör',
                field: 'supplier.name',
              },
              {
                title: 'Inaktiverad',
                field: 'disabled',
                type: 'boolean',
              },
            ]}
            data={users.map(user => {
              const supplier = suppliers.find(
                supplier => supplier.uid === user.supplierUid
              )
              return {
                ...user,
                supplier,
              }
            })}
          />
        )}

        <SupplierForm />
        {!suppliers ? (
          <LinearProgress />
        ) : (
          <DataTable
            title="Leverantörer"
            editable={{
              onRowUpdate: newData => {
                const {
                  uid,
                  minTestsPriceLimit,
                  testsPriceLimit,
                  undistributedCertificates,
                  name,
                } = newData
                const maybeParseInt = (value, otherwise) =>
                  value ? parseInt(value, 10) : otherwise
                const updateData = {
                  minTestsPriceLimit: maybeParseInt(minTestsPriceLimit, 10000),
                  testsPriceLimit: maybeParseInt(testsPriceLimit, 10000),
                  undistributedCertificates: maybeParseInt(
                    undistributedCertificates,
                    0
                  ),
                  name,
                }
                return database
                  .getDatabase()
                  .collection('suppliers')
                  .doc(uid)
                  .update(updateData)
              },
            }}
            options={{
              filtering: true,
            }}
            columns={[
              {
                title: 'Namn',
                field: 'name',
              },
              {
                title: 'Logga',
                field: 'logoUrl',
                filtering: false,
                editable: 'never',
                render: supplier => (
                  <img
                    alt="logga"
                    style={{ maxWidth: 60 }}
                    src={supplier.logoUrl}
                  />
                ),
              },
              {
                title: 'Minsta maxtak kunskapstest',
                field: 'minTestsPriceLimit',
                editable: 'onUpdate',
                type: 'numeric',
              },
              {
                title: 'Maxtak kunskapstest',
                field: 'testsPriceLimit',
                editable: 'onUpdate',
                type: 'numeric',
              },
              {
                title: 'Ofördelade kunskapstest',
                field: 'undistributedCertificates',
                type: 'numeric',
              },
              {
                title: 'Gammal prismodell',
                field: 'legacyPricing',
                type: 'boolean',
              },
            ]}
            data={suppliers}
          />
        )}
      </React.Fragment>
    )
  }
}
export default connect(state => ({ user: state.user }), { loadInvites })(
  UsersManagement
)
