import React from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import _ from 'lodash'
import { getPreviousImageFromDiffs, itemIsEdited } from '../diff'
import ProductSectionContent from './ProductSectionContent'

const parseSectionDiffs = sectionDiffs => {
  const parsedDiffs = {}
  if (!sectionDiffs) {
    return parsedDiffs
  }
  const linkDiffs = _.groupBy(sectionDiffs.links, diff => diff.path[3])
  parsedDiffs.linkChanges = linkDiffs
  parsedDiffs.linkGeneral = linkDiffs ? linkDiffs.undefined : []
  if (sectionDiffs.image) {
    const imageDiff = sectionDiffs.image[0]
    if (imageDiff.rhs === null) {
      parsedDiffs.previousImage = imageDiff.lhs
    } else if (itemIsEdited(imageDiff)) {
      parsedDiffs.previousImage = getPreviousImageFromDiffs(sectionDiffs.image)
    }
  }
  return parsedDiffs
}

const ProductSections = ({ sections, diffs = {}, showChangesOnly = false }) => {
  return (
    <div>
      {_.isEmpty(diffs) && showChangesOnly && (
        <Typography variant="h6">
          Det finns inga ändringar i utbildningsmaterialet
        </Typography>
      )}
      {sections ? (
        sections.map((section, index) => {
          const sectionDiffs = diffs[section.uid] || {}
          const parsedSectionDiffs = parseSectionDiffs(sectionDiffs)
          if (showChangesOnly && _.isEmpty(sectionDiffs)) {
            return null
          }
          return (
            <Card key={index} style={{ marginBottom: 24 }}>
              <CardHeader
                title={section.title}
                subheader={section.irrelevant && 'Ej relevant'}
              />
              {!section.irrelevant && (
                <CardContent>
                  <ProductSectionContent
                    section={section}
                    parsedSectionDiffs={parsedSectionDiffs}
                    sectionDiffs={sectionDiffs}
                  />
                </CardContent>
              )}
            </Card>
          )
        })
      ) : (
        <LinearProgress />
      )}
    </div>
  )
}

export default connect((state, ownProps) => ({
  sections: ownProps.sections || state.product.sections,
}))(ProductSections)
