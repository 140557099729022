import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import Done from '@material-ui/icons/Done'
import { connect } from 'react-redux'
import { markEventComplete } from '../events'
import { timestampToDateString } from '../utils'

function EventList(props) {
  const [confirmOpen, setOpen] = React.useState(false)
  const [event, setEvent] = React.useState('')
  const { events, markEventComplete } = props
  const openConfirmation = (event, index) => {
    setEvent({
      ...event,
      index,
    })
    setOpen(true)
  }
  const closeDialog = () => setOpen(false)
  const confirmDismiss = () => {
    closeDialog()
    markEventComplete(event.uid, event.index)
  }
  return (
    <>
      <Dialog
        open={confirmOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Klarmarkera händelse?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vill du klarmarkera händelsen: {event.title}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Avbryt</Button>
          <Button onClick={confirmDismiss} color="primary" autoFocus>
            Klarmarkera
          </Button>
        </DialogActions>
      </Dialog>
      <List>
        {events.map((event, index) => (
          <ListItem button key={event.uid}>
            <ListItemIcon>
              <ErrorOutline color="error" />
            </ListItemIcon>
            <Link to={event.url || '/'}>
              <ListItemText
                primary={event.title}
                secondary={timestampToDateString(event.creationDateTime)}
              />
            </Link>
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => openConfirmation(event, index)}
              >
                <Done />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {events.length === 0 && (
          <Typography variant="body1">Det finns inget att åtgärda</Typography>
        )}
      </List>
    </>
  )
}
export default connect(null, { markEventComplete })(EventList)
