import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import moment from 'moment'
import SimpleLineChart from './SimpleLineChart'
import { loadOverview } from './actions'
import LineChartTotals from './LineChartTotals'
import ProductStatisticsTable from './ProductStatisticsTable'
import { styles } from './common'
import CountryMap from './CountryMap'
import PharmacyDistribution from './PharmacyDistribution'
import Billing from './Billing'

class StatisticsOverview extends Component {
  componentDidMount() {
    const { products, productsInit, dateRange, data, pharmacies } = this.props
    const hasPharmacies = Boolean(pharmacies.length)
    if (
      productsInit &&
      dateRange &&
      hasPharmacies &&
      !(data && data.products)
    ) {
      this.props.loadOverview(products, dateRange, pharmacies)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      products,
      productsInit,
      dateRange,
      data,
      pharmacies,
      supplierUid,
    } = this.props
    const dateRangeChanged = prevProps.dateRange !== dateRange
    const productsChanged = prevProps.products !== products
    const pharmaciesChanged = prevProps.pharmacies !== pharmacies
    const hasPharmacies = Boolean(pharmacies.length)
    const invalidDateRange = data && data.dateRange !== dateRange
    const supplierChanged = prevProps.supplierUid !== supplierUid
    const legalDateRange =
      dateRange.from && moment(dateRange.from).isBefore(dateRange.to)
    if (
      (dateRangeChanged ||
        productsChanged ||
        invalidDateRange ||
        pharmaciesChanged ||
        supplierChanged) &&
      productsInit &&
      legalDateRange &&
      hasPharmacies
    ) {
      if (
        supplierUid &&
        products.some(product => product.supplierUid !== supplierUid)
      ) {
        const supplierProducts = products.filter(
          product => product.supplierUid === supplierUid
        )
        this.props.loadOverview(supplierProducts, dateRange, pharmacies)
      } else {
        this.props.loadOverview(products, dateRange, pharmacies)
      }
    }
  }

  render() {
    const { data, classes, initialized, user, supplierUid } = this.props
    if (!(initialized && data)) {
      return null
    }
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <LineChartTotals
                  data={data.educationTotals}
                  // avgReadTime={data.avgReadTime.total}
                  distributedCount={data.distributedCount}
                  undistributedCount={data.undistributedCount}
                />
                <SimpleLineChart
                  data={data.chartData}
                  className={classes.section}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <PharmacyDistribution data={data} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Geografisk spridning" />
              <CardContent style={{ minHeight: 370 }}>
                <CountryMap data={data.geoData} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Produkter" />
              <CardContent>
                {!data.tableData ? (
                  <LinearProgress />
                ) : (
                  <ProductStatisticsTable data={data.tableData} />
                )}
              </CardContent>
            </Card>
          </Grid>
          {user.isSuperAdmin() && (
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Fakturering" />
                <CardContent>
                  <Billing supplierUid={supplierUid} />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.statistics,
    pharmacies: state.pharmacies,
    products: state.products.list,
    productsInit: state.products.initialized,
    user: state.user,
  }
}

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, { loadOverview })
)
export default enhance(StatisticsOverview)
