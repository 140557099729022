import React, { Component, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import ReactGA from 'react-ga'
import databaseManager from '../../database'
import { batchCreateProduct, emptyQuestion } from './common'
import TherapeuticPartInput from './TherapeuticPartInput'

class AddTherapeuticPart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
    }
    this.therapeuticRef = databaseManager.queryTherapeutic(
      props.match.params.therapeuticUid
    )
    this.newPartDoc = this.therapeuticRef.collection('parts').doc()
  }

  componentDidMount() {
    ReactGA.set({ dimension3: this.newPartDoc.id })
    ReactGA.event({
      category: 'Therapeutic',
      action: 'Created New Part',
      label: this.newPartDoc.id,
    })
  }

  componentWillUnmount() {
    ReactGA.set({ dimension3: null })
  }

  savePart = formValues => {
    this.setState({ saving: true })
    this.therapeuticRef
      .collection('parts')
      .get()
      .then(snaps => {
        const partsCount = snaps.size
        const fullPartData = {
          index: partsCount,
          ...formValues,
        }
        return batchCreateProduct(this.newPartDoc, fullPartData, batch => {
          batch.update(this.therapeuticRef, {
            partsCount: partsCount + 1,
            partUid: !partsCount ? this.newPartDoc.id : null,
          })
        })
      })
      .then(() => {
        this.setState({ saving: false })
        ReactGA.event({
          category: 'Therapeutic',
          action: 'Saved New Part',
          label: this.newPartDoc.id,
        })
        const {
          match: {
            params: { therapeuticUid },
          },
          history,
        } = this.props
        history.push(
          `/therapeutic/${therapeuticUid}/overview/part/${this.newPartDoc.id}`
        )
      })
  }

  render() {
    const {
      handleSubmit,
      dirty,
      match: {
        params: { therapeuticUid, partUid },
      },
    } = this.props
    const { saving } = this.state
    const storagePath = `therapeutics/${therapeuticUid}/parts/${partUid}`
    return (
      <Fragment>
        <Typography variant="h5" color="primary">
          Lägg till del
        </Typography>
        <TherapeuticPartInput
          storagePath={storagePath}
          onSave={handleSubmit(this.savePart)}
          disabled={!dirty || saving}
        />
      </Fragment>
    )
  }
}

const enhance = compose(
  withRouter,
  connect(() => ({
    initialValues: {
      quiz: new Array(5).fill(emptyQuestion),
      sections: [{ title: '' }],
    },
  })),
  reduxForm({
    // a unique name for the form
    form: 'therapeuticPart',
  })
)

export default enhance(AddTherapeuticPart)
