import React, { Fragment, Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import ChartIcon from '@material-ui/icons/ShowChart'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import Typography from '@material-ui/core/Typography'
import SimpleSelect from '../SimpleSelect'
import SimpleLineChart from '../statistics/SimpleLineChart'
import LineChartTotals from '../statistics/LineChartTotals'
import { updateDateRange, loadDashboard } from '../statistics/actions'
import { dateRangeOptions, dateFormat } from '../statistics/common'

class DashboardStatistics extends Component {
  state = {
    initialized: false,
    selected: 'month',
  }

  componentDidMount() {
    this.props.user.supplier.get().then(supplierSnap => {
      const minDate = supplierSnap.get('firstPublishedDateTime')
      this.setState({ minDate: minDate ? minDate.toDate() : '2019-02-01' })
    })
    this.selectDateRange(this.state.selected)
  }

  componentDidUpdate(prevProps) {
    const {
      products,
      productsInit,
      statistics: { dateRange },
      loadDashboard,
    } = this.props
    const dateRangeChanged = !_.isEqual(
      prevProps.statistics.dateRange,
      dateRange
    )
    const productsChanged = !_.isEqual(prevProps.products, products)
    const productsInitialized = !prevProps.productsInit && productsInit
    if (dateRangeChanged || productsChanged || productsInitialized) {
      loadDashboard(products, dateRange)
    }
  }

  selectDateRange = dateRange => {
    this.setState({ selected: dateRange })
    let from = moment()
    switch (dateRange) {
      case 'month':
        from.subtract(1, 'month')
        break
      case 'quarter':
        from.subtract(3, 'month')
        break
      case 'total':
        from = moment(this.state.minDate)
        break
      default:
        from.subtract(1, 'month')
    }
    const newDateRange = {
      to: moment().format(dateFormat),
      from: from.format(dateFormat),
    }
    this.props.updateDateRange(newDateRange)
  }

  render() {
    const {
      statistics: { data, loading },
    } = this.props
    return (
      <Card>
        <CardHeader
          title="Statistik"
          avatar={<ChartIcon />}
          action={
            <SimpleSelect
              options={dateRangeOptions}
              selected={this.state.selected}
              onSelect={this.selectDateRange}
            />
          }
        />
        <CardContent>
          {loading && <LinearProgress />}
          {data && (
            <Fragment>
              <LineChartTotals
                data={data.educationTotals}
                distributedCount={data.distributedCount}
                undistributedCount={data.undistributedCount}
              />
              {data.chartData ? (
                <SimpleLineChart data={data.chartData} />
              ) : (
                <Typography align="center">Statistik saknas</Typography>
              )}
            </Fragment>
          )}
        </CardContent>
        <CardActions>
          <Link to="/statistics" style={{ marginLeft: 'auto' }}>
            <Button>
              Statistik översikt <ChevronRight />
            </Button>
          </Link>
        </CardActions>
      </Card>
    )
  }
}
export default connect(
  state => ({
    statistics: state.statistics,
    products: state.products.list,
    productsInit: state.products.initialized,
    user: state.user,
  }),
  { loadDashboard, updateDateRange }
)(DashboardStatistics)
