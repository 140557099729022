import React from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Image from './Image'
import Video from './Video'

const styles = theme => ({
  card: {
    marginBottom: theme.spacing(3),
  },
  missing: {
    fontStyle: 'italic',
  },
})

function ProductMedia(props) {
  const {
    classes,
    product: { image, video, attachments = [] },
  } = props
  return [
    <Card className={classes.card}>
      <CardHeader title="Bild" />
      <CardContent>
        {image ? (
          <Image image={image} />
        ) : (
          <Typography className={classes.missing}>Bild saknas</Typography>
        )}
      </CardContent>
    </Card>,
    <Card className={classes.card}>
      <CardHeader title="Video" />
      <CardContent>
        {video ? (
          <Video video={video} />
        ) : (
          <Typography className={classes.missing}>Video saknas</Typography>
        )}
      </CardContent>
    </Card>,
    <Card className={classes.card}>
      <CardHeader title="Extramaterial" />
      <CardContent>
        {attachments.length === 0 && (
          <Typography className={classes.missing}>
            Extramaterial saknas
          </Typography>
        )}
        {attachments.map(attachment => (
          <div key={attachment.name}>
            <Link download={attachment.originalName} href={attachment.url}>
              {attachment.originalName}
            </Link>
          </div>
        ))}
      </CardContent>
    </Card>,
  ]
}
export default withStyles(styles)(
  connect(state => ({
    product: state.product,
  }))(ProductMedia)
)
