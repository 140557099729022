import React, { Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Video from '../Video'
import Image from '../Image'
import TherapeuticParts from './TherapeuticParts'
import { CategoryGroup } from '../../constants'

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  navigation: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
})

const educationTypeNames = {
  [CategoryGroup.HEALTH_CARE]: 'Sjukvård',
  [CategoryGroup.SELF_CARE]: 'Egenvård',
}

function TherapeuticOverview(props) {
  const {
    therapeutic,
    classes,
    categories,
    match: {
      params: { therapeuticUid },
    },
  } = props
  if (!therapeutic) {
    // TODO Change to generic 'couldn't load' component
    return <Typography>Kunde inte ladda terapiområde</Typography>
  }
  const { name, image, video, educationType, categoryUid, tags } = therapeutic
  const category = categories.find(category => category.value === categoryUid)
  const categoryName = category ? category.name : 'Okänd'
  const educationTypeName = educationTypeNames[educationType] || 'Okänd'
  return (
    <Fragment>
      <div className={classes.section}>
        <Button
          component={Link}
          to={`/therapeutic/${therapeuticUid}/edit`}
          variant="contained"
          className={classes.button}
        >
          Redigera
        </Button>
        <Button
          component={Link}
          to={`/therapeutic/${therapeuticUid}/add-part`}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Lägg till del
        </Button>
      </div>
      <Typography variant="h4" color="primary" className={classes.section}>
        Terapiområde {name}
      </Typography>
      <Grid container className={classes.section}>
        <Grid item xs={12} md={6}>
          {image ? <Image image={image} /> : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Utbildningstyp</Typography>
          <Typography variant="subtitle1">{educationTypeName}</Typography>
          <Typography variant="h6">Kategori</Typography>
          <Typography variant="subtitle1">{categoryName}</Typography>
          <Typography variant="h6">Taggar</Typography>
          {tags && tags.length > 0 ? (
            <Typography variant="subtitle1">
              {tags.map(tag => tag.trim()).join(', ')}
            </Typography>
          ) : (
            <Typography variant="subtitle1">Inga taggar</Typography>
          )}
        </Grid>
        {video && (
          <Grid item xs={12}>
            <Typography variant="h6">Video</Typography>
            <Video video={video} />
          </Grid>
        )}
      </Grid>
      <TherapeuticParts therapeuticUid={therapeuticUid} />
    </Fragment>
  )
}

const enhance = compose(
  withRouter,
  connect((state, ownProps) => ({
    user: state.user,
    therapeutic: state.therapeutics.list.find(
      therapeutic => therapeutic.uid === ownProps.match.params.therapeuticUid
    ),
    categories: state.options.categories.list,
  })),
  withStyles(styles)
)
export default enhance(TherapeuticOverview)
