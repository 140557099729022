import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import HourGlassEmpty from '@material-ui/icons/HourglassEmpty'
import HelpOutline from '@material-ui/icons/HelpOutline'
import ArrowRight from '@material-ui/icons/ArrowRight'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import _ from 'lodash'

const WAITING = 'waiting'
const ERROR = 'error'
const SUCCESS = 'success'
const UNKNOWN = 'unknown'

const status = (status, ...comments) => ({ status, comments })

const getPharmacyStatus = summary => {
  if (!summary) {
    return null
  }
  const { pharmacies } = summary
  const pharmaciesList = Object.values(pharmacies)
  const approvedGroups = _.groupBy(pharmaciesList, 'approved')
  const rejectedPharmacies = approvedGroups.false
  const waitingPharmacies = approvedGroups.null
  if (rejectedPharmacies) {
    return status(
      ERROR,
      'Komplettering begärts av:',
      ...rejectedPharmacies.map(({ name }) => name)
    )
  }
  if (waitingPharmacies) {
    return status(
      WAITING,
      'Inväntar granskning från:',
      ...waitingPharmacies.map(({ name }) => name)
    )
  }
  return status(SUCCESS, 'Alla apotek har godkänt senaste granskningsärendet')
}
const getInternalStatus = summary => {
  if (summary === undefined) {
    return status(UNKNOWN, 'Produkten har inte skickats för granskning än')
  }
  const { internal } = summary
  if (internal === null) {
    return status(WAITING, 'Inväntar granskning av Apostar')
  }
  if (internal === false) {
    return status(ERROR, 'Apostar har begärt komplettering')
  }
  if (internal === true) {
    return status(SUCCESS, 'Apostar har godkänt senaste granskningsärendet')
  }
  return status(UNKNOWN, 'Status okänd')
}

const StatusIcon = ({ status }) => {
  switch (status) {
    case WAITING:
      return <HourGlassEmpty />
    case ERROR:
      return <ErrorOutline color="error" />
    case SUCCESS:
      return <CheckCircleOutline color="primary" />
    default:
      return <HelpOutline />
  }
}

const QualityControlStatus = ({ summary }) => {
  const internalStatus = getInternalStatus(summary)
  const pharmacyStatus = getPharmacyStatus(summary)
  const comments =
    internalStatus.status === SUCCESS
      ? internalStatus.comments.concat(pharmacyStatus.comments)
      : internalStatus.comments
  return (
    <Fragment>
      <Tooltip
        title={
          <Fragment>
            {comments.map((comment, index) => (
              <Typography key={index} color="inherit">
                {comment}
              </Typography>
            ))}
          </Fragment>
        }
      >
        {/* <Link to={`/product/${productUid}/quality-control`}> */}
        <IconButton aria-label="">
          <StatusIcon status={internalStatus.status} />
          {internalStatus.status === SUCCESS && (
            <Fragment>
              <ArrowRight />
              <StatusIcon status={pharmacyStatus.status} />
            </Fragment>
          )}
        </IconButton>
        {/* </Link> */}
      </Tooltip>
    </Fragment>
  )
}
export default QualityControlStatus
