import React from 'react'
import { FieldArray, Field, reduxForm, formValues } from 'redux-form'
import { withRouter } from 'react-router-dom'
import {
  CardContent,
  Button,
  Grid,
  Typography,
  LinearProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import _ from 'lodash'
import FileUpload from '../FileUpload'
import { FormImageUpload, FormVideoUpload } from './input-components'
import ProductInputCard from './ProductInputCard'
import Image from '../Image'
import Video from '../Video'
import database from '../../database'
import { serverTimestamp, storage } from '../../firebase'
import { required } from './common'

const styles = theme => ({
  button: {
    marginLeft: theme.spacing(2),
  },
  helperText: {
    marginTop: theme.spacing(2),
  },
  video: {
    marginTop: theme.spacing(2),
  },
})

const ProductVideoInput = withStyles(styles)(
  formValues('video')(({ video, productRef, change, classes }) => (
    <ProductInputCard title="Video" optional>
      <CardContent>
        <Typography gutterBottom>
          Videon hamnar högst upp i utbildningen. Vi rekommenderar formatet MP4
          men även andra format stöds då de konverteras automatiskt efter
          uppladdning. Filen får max vara 200Mb stor
        </Typography>
        <Field
          storagePath={`products/${productRef.id}`}
          name="video"
          docRef={productRef}
          component={FormVideoUpload}
        />
        <Button
          disabled={!video}
          variant="outlined"
          className={classes.button}
          onClick={() => {
            storage.ref(video.storageRef).delete()
            change('video', null)
          }}
        >
          Ta bort
        </Button>
        {video && !video.formattedUrl && (
          <div>
            <Typography gutterBottom>Bearbetar video...</Typography>
            <LinearProgress />
          </div>
        )}
        {video && video.formattingError && (
          <Typography gutterBottom color="error">
            Videon kunde inte konverteras. Pröva igen med annat format.
          </Typography>
        )}
        <div className={classes.video}>
          {video ? (
            <Video video={video} />
          ) : (
            <div
              style={{
                height: 300,
                backgroundColor: '#dfdfdf',
                marginRight: 20,
              }}
            />
          )}
        </div>
      </CardContent>
    </ProductInputCard>
  ))
)
const ProductImageInput = withStyles(styles)(
  formValues('image')(({ image, productRef, change, classes }) => (
    <ProductInputCard title="Produktbild" validate={errors => !errors.image}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Field
              name="image"
              validate={required}
              storagePath={`products/${productRef.id}`}
              component={FormImageUpload}
              color="primary"
            />
            <Button
              disabled={!image}
              variant="outlined"
              className={classes.button}
              onClick={() => {
                storage.ref(image.storageRef).delete()
                change('image', null)
              }}
            >
              Ta bort
            </Button>
            <Typography className={classes.helperText}>
              Se till att bilden är högupplöst
            </Typography>
          </Grid>
          <Grid item>
            {image ? (
              <Image image={image} style={{ minHeight: 300 }} />
            ) : (
              <div
                style={{
                  minHeight: 300,
                  width: '100%',
                  backgroundColor: '#dfdfdf',
                }}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </ProductInputCard>
  ))
)

const ProductAttachmentsInput = withStyles(styles)(({ productRef }) => (
  <ProductInputCard title="Extramaterial" optional>
    <CardContent>
      <Typography gutterBottom>
        Extramaterial kan vara broschyrer, studier och liknande pdfer som kan
        komplettera utbildningsinnehållet men inte är nödvändiga för att klara
        kunskapstestet.
      </Typography>
      <FieldArray
        name="attachments"
        storagePath={`products/${productRef.id}`}
        productRef={productRef}
        component={FileUpload}
      />
    </CardContent>
  </ProductInputCard>
))
function ProductMediaInput({
  match: {
    params: { productUid },
  },
  history,
  change,
  submitting,
  handleSubmit,
}) {
  const productRef = database.queryProduct(productUid)
  const submit = ({ image, video }) => {
    // TODO save attachments?
    return productRef
      .update({
        image,
        video,
        'completion.media': image ? 100 : 0,
        editDateTime: serverTimestamp(),
      })
      .then(() => {
        history.push(`/product/${productUid}/create/content`)
        window.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
        })
      })
  }
  return (
    <form onSubmit={handleSubmit(submit)}>
      <ProductImageInput productRef={productRef} change={change} />
      <ProductVideoInput productRef={productRef} change={change} />
      <ProductAttachmentsInput productRef={productRef} />
      <Button
        disabled={submitting}
        variant="contained"
        color="primary"
        type="submit"
      >
        Gå vidare
      </Button>
    </form>
  )
}
const enhance = compose(
  withRouter,
  reduxForm({
    form: 'media',
    enableReinitialize: true,
    onChange: (
      { image, video },
      dispatch,
      {
        match: {
          params: { productUid },
        },
      },
      prevValues
    ) => {
      if (
        !_.isEqual(image, prevValues.image) ||
        !_.isEqual(video, prevValues.video)
      ) {
        return database.queryProduct(productUid).update({
          image,
          video,
          'completion.media': image ? 100 : 0,
          editDateTime: serverTimestamp(),
        })
      }

      return Promise.resolve()
    },
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
  })
)
export default enhance(ProductMediaInput)
