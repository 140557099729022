import _ from 'lodash'

const diffByProp = (diffs, prop) => {
  if (diffs) {
    const propDiffs = diffs.filter(diff => diff.path[0] === prop)
    const groupedDiffs = _.groupBy(propDiffs, diff => diff.path[1])
    _.forEach(groupedDiffs, (value, key) => {
      groupedDiffs[key] = _.groupBy(value, diff => diff.path[2])
    })
    return groupedDiffs
  }
  return {}
}

const getPreviousImageFromDiffs = (imageDiffs, pathIndex = 3) =>
  imageDiffs.reduce(
    (image, diff) => ({
      ...image,
      [diff.path[pathIndex]]: diff.lhs,
    }),
    {}
  )

const findDiffForProp = (diffs, prop, pathIndex = 0) =>
  diffs &&
  diffs.length > 0 &&
  diffs.find(
    diff => diff.path.length > pathIndex && diff.path[pathIndex] === prop
  )

const itemIsDeleted = diffItem => diffItem && diffItem.kind === 'D'
const itemIsNew = diffItem => diffItem && diffItem.kind === 'N'
const itemIsEdited = diffItem => diffItem && diffItem.kind === 'E'

export {
  diffByProp,
  itemIsDeleted,
  itemIsEdited,
  itemIsNew,
  getPreviousImageFromDiffs,
  findDiffForProp,
}
