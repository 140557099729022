import _ from 'lodash'
import { functionsEurope, database } from '../../firebase'
import {
  makeWeeklyLineChartData,
  makeTableData,
  makePieChartData,
  makeAvgReadTime,
  makeGeoData,
  countEducationTotalsFromWeekly,
  makeQuestionsDataArray,
} from './common'

const getSupplierStats = functionsEurope.httpsCallable(
  'analytics-getSupplierStats'
)
const getSupplierWeekly = functionsEurope.httpsCallable(
  'analytics-getSupplierWeekly'
)

const updateDateRange = dateRange => ({
  type: 'UPDATE_DATE_RANGE',
  dateRange,
})

const updateExludedBillingProductUids = excludedBillingProducts => ({
  type: 'UPDATE_EXCLUDED_BILLING_PRODUCTS',
  excludedBillingProducts,
})

const loadStatsStarted = () => ({
  type: 'LOAD_STATS_STARTED',
})

const getProductsStats = functionsEurope.httpsCallable(
  'analytics-getProductsStats'
)
const getProductsWeekly = functionsEurope.httpsCallable(
  'analytics-getProductsWeekly'
)

const emptyStatistics = {
  educationTotals: {
    totals: {
      passed: 0,
      clicks: 0,
      failed: 0,
    },
    deltas: {},
  },
  avgReadTime: 0,
  undistributedCount: 0,
  distributedCount: 0,
}

const loadDashboard = (products, dateRange) => (dispatch, getState) => {
  dispatch(loadStatsStarted())
  const { user } = getState()
  const queryReport = () => {
    if (user.isSupplierAdmin()) {
      const statsQuery = { dateRange }
      return getSupplierWeekly(statsQuery)
    }
    if (user.isSuperAdmin()) {
      const productUids = products.map(product => product.uid)
      const statsQuery = { dateRange, productUids, compare: true }
      return getProductsWeekly(statsQuery)
    }
    return Promise.resolve([])
  }
  const getSupplier = database
    .collection('suppliers')
    .doc(user.supplierUid)
    .get()

  if (!products || !products.length) {
    return dispatch({
      type: 'LOAD_OVERVIEW_STATS',
      data: {
        dateRange,
        ...emptyStatistics,
      },
    })
  }
  return Promise.all([queryReport(), getSupplier])
    .then(([reportResult, supplierSnap]) => {
      const [chartDataReport, avgReadTimeReport] = reportResult.data.reports
      const chartData = makeWeeklyLineChartData(chartDataReport, dateRange)
      const avgReadTime = makeAvgReadTime(avgReadTimeReport)
      const educationTotals = countEducationTotalsFromWeekly(chartDataReport)
      const distributedCount = _.sumBy(products, 'activeEducationCount')
      const undistributedCount = supplierSnap.get('undistributedCertificates')
      dispatch({
        type: 'LOAD_OVERVIEW_STATS',
        data: {
          dateRange,
          chartData,
          educationTotals,
          avgReadTime,
          distributedCount,
          undistributedCount,
        },
      })
    })
    .catch(error => {
      dispatch({
        type: 'LOAD_STATS_FAILED',
        error,
      })
    })
}

const loadOverview = (products, dateRange, pharmacies = []) => (
  dispatch,
  getState
) => {
  dispatch(loadStatsStarted())
  const { user } = getState()
  const queryReport = () => {
    if (user.isSupplierAdmin()) {
      const statsQuery = { dateRange, compare: true }
      return getSupplierStats(statsQuery)
    }
    if (user.isSuperAdmin()) {
      const productUids = products.map(product => product.uid)
      const statsQuery = { dateRange, compare: true, productUids }
      return getProductsStats(statsQuery)
    }
    return Promise.resolve([])
  }
  const getUndistributedCount = user.isSupplierAdmin()
    ? database
        .collection('suppliers')
        .doc(user.supplierUid)
        .get()
        .then(supplierSnap => supplierSnap.get('undistributedCertificates'))
    : 0
  if (!products || !products.length) {
    return dispatch({
      type: 'LOAD_OVERVIEW_STATS',
      data: {
        dateRange,
        ...emptyStatistics,
      },
    })
  }
  return Promise.all([queryReport(), getUndistributedCount])
    .then(([reportResult, undistributedCount]) => {
      const [
        chartDataReport,
        tableDataReport,
        pharmacyReport,
        readTimeReport,
        geoReport,
      ] = reportResult.data.reports
      const avgReadTime = makeAvgReadTime(readTimeReport)
      const chartData = makeWeeklyLineChartData(chartDataReport, dateRange)
      const tableData = makeTableData(tableDataReport, products).map(
        product => ({
          ...product,
          ...avgReadTime[product.uid],
        })
      )
      const pieChartData = makePieChartData(pharmacyReport, pharmacies)
      const geoData = makeGeoData(geoReport)
      const educationTotals = countEducationTotalsFromWeekly(
        chartDataReport,
        pharmacies
      )
      const distributedCount = _.sumBy(products, 'activeEducationCount')
      dispatch({
        type: 'LOAD_OVERVIEW_STATS',
        data: {
          dateRange,
          chartData,
          tableData,
          pieChartData,
          educationTotals,
          avgReadTime,
          geoData,
          distributedCount,
          undistributedCount,
        },
      })
    })
    .catch(error => {
      console.error('Got error', error)
      dispatch({
        type: 'LOAD_STATS_FAILED',
        error,
      })
    })
}
const getProductStats = functionsEurope.httpsCallable(
  'analytics-getProductStats'
)
const loadProduct = (productUid, dateRange, pharmacies) => dispatch => {
  const statsQuery = {
    productUid,
    dateRange,
    compare: true,
  }
  dispatch(loadStatsStarted())
  return getProductStats(statsQuery)
    .then(res => {
      const [
        weeklyDataReport,
        pharmacyReport,
        questionsReport,
      ] = res.data.reports
      const questionsData = makeQuestionsDataArray(questionsReport)
      const lineChartData = makeWeeklyLineChartData(weeklyDataReport, dateRange)
      const pieChartData = makePieChartData(pharmacyReport, pharmacies)
      const educationTotals = countEducationTotalsFromWeekly(
        weeklyDataReport,
        pharmacies
      )
      dispatch({
        type: 'LOAD_PRODUCT_STATS',
        productUid,
        data: {
          dateRange,
          lineChartData,
          pieChartData,
          educationTotals,
          questionsData,
        },
      })
    })
    .catch(error => {
      dispatch({
        type: 'LOAD_STATS_FAILED',
        error,
      })
    })
}

const statisticsProductReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_PRODUCT_STATS':
      return {
        ...state,
        [action.productUid]: action.data,
        loading: false,
        error: null,
      }
    default:
      return state
  }
}

const statisticsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_STATS_STARTED':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'LOAD_OVERVIEW_STATS':
      return {
        ...state,
        data: action.data,
        loading: false,
        initialized: true,
        error: null,
      }
    case 'LOAD_PRODUCT_STATS':
      return {
        ...state,
        products: statisticsProductReducer(state.products, action),
        loading: false,
        initialized: true,
        error: null,
      }
    case 'LOAD_STATS_FAILED':
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case 'UPDATE_DATE_RANGE':
      return {
        ...state,
        dateRange: action.dateRange,
      }
    case 'UPDATE_EXCLUDED_BILLING_PRODUCTS':
      return {
        ...state,
        excludedBillingProducts: action.excludedBillingProducts,
      }
    default:
      return state
  }
}

export {
  statisticsReducer,
  loadOverview,
  loadDashboard,
  loadProduct,
  updateDateRange,
  updateExludedBillingProductUids,
}
