import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { FormHelperText } from '@material-ui/core'

const SimpleSelect = props => {
  const {
    name,
    field,
    options,
    selected,
    valueField,
    error,
    disabled,
    helperText,
    fullWidth = true,
  } = props
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current ? inputLabel.current.offsetWidth : 0)
  }, [])
  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      error={Boolean(error)}
      disabled={Boolean(disabled)}
    >
      <InputLabel ref={inputLabel} htmlFor={field}>
        {name}
      </InputLabel>
      <Select
        value={selected}
        onChange={event => {
          const { value } = event.target
          props.onSelect(value)
        }}
        inputProps={{
          name: field,
          id: field,
        }}
        labelWidth={labelWidth}
      >
        {options.map(option => {
          const value = valueField ? option[valueField] : option.value
          return (
            <MenuItem key={value} value={value}>
              {option.name}
            </MenuItem>
          )
        })}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
export default SimpleSelect
