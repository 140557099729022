import { database } from './firebase'
import { PRODUCT_TYPES } from './constants'

class DatabaseManager {
  batch = () => database.batch()

  getDatabase = () => database

  // Collection refs
  productsCollection = database.collection('products')

  // Queries
  queryProducts = () => this.productsCollection

  queryProduct = productUid => this.productsCollection.doc(productUid)

  queryProductsForUser = user => {
    if (user.isPharmacyAdmin()) {
      return this.queryProductsForPharmacy(user.pharmacy)
    }
    if (user.isSupplierAdmin()) {
      return this.queryProductsForSupplier(user.supplierUid)
    }
    if (user.isSuperAdmin()) {
      return this.queryProducts()
    }
    return []
  }

  queryProductForUser = (productUid, user) => {
    if (user.isPharmacyAdmin()) {
      return this.queryProductsForPharmacy(user.pharmacy).doc(productUid)
    }
    if (user.isSupplierAdmin() || user.isSuperAdmin()) {
      return this.queryProduct(productUid)
    }
    return {}
  }

  queryProductsForSupplier = supplierUid =>
    this.productsCollection.where('supplierUid', '==', supplierUid)

  queryProductsForPharmacy = pharmacy => pharmacy.collection('products')

  queryAllProducts = () =>
    this.productsCollection
      .where('type', '<=', PRODUCT_TYPES.SERIES_PRODUCT)
      .orderBy('type')

  queryAllProductsForUser = user =>
    this.queryProductsForUser(user)
      .where('type', '<=', PRODUCT_TYPES.SERIES_PRODUCT)
      .orderBy('type')

  queryAllProductsAndSeriesForUser = user => this.queryProductsForUser(user)

  queryStandaloneProducts = () =>
    this.productsCollection.where('type', '==', PRODUCT_TYPES.PRODUCT)

  queryStandaloneProductsForUser = user =>
    this.queryProductsForUser(user).where('type', '==', PRODUCT_TYPES.PRODUCT)

  querySeriesProducts = () =>
    this.productsCollection.where('type', '==', PRODUCT_TYPES.SERIES_PRODUCT)

  querySeriesProductsForUser = user =>
    this.queryProductsForUser(user).where(
      'type',
      '==',
      PRODUCT_TYPES.SERIES_PRODUCT
    )

  querySeries = () =>
    this.productsCollection.where('type', '==', PRODUCT_TYPES.SERIES)

  querySeriesForUser = user =>
    this.queryProductsForUser(user).where('type', '==', PRODUCT_TYPES.SERIES)

  queryTherapeutics = () => database.collection('therapeutics')

  queryTherapeutic = therapeuticUid =>
    database.collection('therapeutics').doc(therapeuticUid)

  queryPublicationRequestsForPharmacy = pharmacyUid =>
    database
      .collection('pharmacies')
      .doc(pharmacyUid)
      .collection('publicationRequests')

  queryPublicationRequestsForPharmacyProduct = (pharmacyUid, productUid) =>
    this.queryPublicationRequestsForPharmacy(pharmacyUid).where(
      'productUid',
      '==',
      productUid
    )

  // Utilites
  getCollectionDocsDataWithUid = collection =>
    collection.docs.map(doc => ({ ...doc.data(), uid: doc.id }))

  getCollectionDocsData = collection => collection.docs.map(doc => doc.data())

  getDocDataWithUid = doc => ({ ...doc.data(), uid: doc.id })

  getProductWithSubproducts = (product, user) => {
    if (product.productUids) {
      const subproductFetches = product.productUids.map(productUid =>
        this.queryProductForUser(productUid, user)
          .get()
          .then(this.getDocDataWithUid)
      )
      return Promise.all(subproductFetches).then(subproducts => {
        return {
          ...product,
          subproducts,
        }
      })
    }
    return Promise.resolve(product)
  }

  getProductCollections = productRef => {
    const fetchSections = productRef
      .collection('sections')
      .orderBy('index')
      .get()
      .then(this.getCollectionDocsDataWithUid)
    const fetchQuiz = productRef
      .collection('quiz')
      .orderBy('index')
      .get()
      .then(this.getCollectionDocsDataWithUid)
    const fetchAttachments = productRef
      .collection('attachments')
      .get()
      .then(this.getCollectionDocsDataWithUid)
    return Promise.all([fetchSections, fetchQuiz, fetchAttachments]).then(
      ([sections, quiz, attachments]) => ({
        sections,
        quiz,
        attachments,
      })
    )
  }

  getRequestWithFullProduct = requestRef => {
    return requestRef.get().then(requestSnap => {
      const request = this.getDocDataWithUid(requestSnap)
      return this.getProductCollections(requestRef).then(collections => ({
        ...request,
        product: {
          ...request.product,
          ...collections,
        },
      }))
    })
  }

  getFullProductForUser = (productUid, user) => {
    const productRef = this.queryProductForUser(productUid, user)
    const fetchCollections = this.getProductCollections(productRef)
    const fetchProduct = productRef.get().then(productSnap => {
      const product = this.getDocDataWithUid(productSnap)
      return this.getProductWithSubproducts(product, user)
    })
    return Promise.all([fetchProduct, fetchCollections]).then(
      ([product, collections]) => ({
        ...product,
        ...collections,
      })
    )
  }
}

export default new DatabaseManager()
