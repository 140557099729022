import React, { Component, Fragment } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import database from '../../database'
import { functionsEurope } from '../../firebase'
import RequestResponseInput from './RequestResponseInput'
import RequestProductInformation from './RequestProductInformation'
import ApprovalInfo from './ApprovalInfo'
import Discussion from './Discussion'
import { withUser } from '../../actions'

const internallyApprovePublicationRequest = functionsEurope.httpsCallable(
  'qualityControl-internallyApprovePublicationRequest'
)

function RequestGroupReadOnly(props) {
  const { requestGroup, requestGroupRef, requests } = props
  const { approved, comment } = requestGroup.internalApproval
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h6">Apostar</Typography>
          <ApprovalInfo approval={requestGroup.internalApproval} />
          {comment && (
            <Discussion
              requestRef={requestGroupRef}
              approved={approved}
              comment={comment}
              pharmacy={{ name: 'Apostar' }}
            />
          )}
        </Grid>
        {approved === true &&
          requests
            .filter(request => !request.pharmacyApproval.autoAccept)
            .map(request => {
              const { requestRef, pharmacyName, pharmacyApproval } = request
              return (
                <Grid key={request.requestUid} item xs={12} md={6} lg={4}>
                  <Typography variant="h6">{pharmacyName}</Typography>
                  <ApprovalInfo approval={pharmacyApproval} />
                  {pharmacyApproval.comment && (
                    <Discussion
                      requestRef={requestRef}
                      comment={pharmacyApproval.comment}
                      pharmacy={{ name: pharmacyName }}
                    />
                  )}
                </Grid>
              )
            })}
      </Grid>
      <RequestProductInformation request={requestGroup} />
    </Fragment>
  )
}

class RequestGroupResponse extends Component {
  state = {
    sending: false,
    error: null,
  }

  sendApprovalStatus = (approved, comment) => {
    this.setState({ sending: true })
    internallyApprovePublicationRequest({
      requestGroupUid: this.props.requestGroupUid,
      approved,
      timeout: false,
      comment: comment || '',
    })
      .then(() => {
        this.setState({ sending: false })
        this.props.history.push('/')
      })
      .catch(() => {
        this.setState({
          error: 'Kunde inte skicka svar',
          sending: false,
        })
      })
  }

  accept = comment => {
    this.sendApprovalStatus(true, comment)
  }

  deny = comment => {
    this.sendApprovalStatus(false, comment)
  }

  render() {
    const { error, sending } = this.state
    const { requestGroup, requestGroupRef, requests } = this.props
    const { approved, comment } = requestGroup.internalApproval
    return (
      <Fragment>
        <Grid container>
          {approved !== null &&
            requests.map(request => (
              <Grid key={request.requestUid} item xs={12} md={6} lg={4}>
                <Typography variant="h6">{request.pharmacyName}</Typography>
                <ApprovalInfo approval={request.pharmacyApproval} />
              </Grid>
            ))}
        </Grid>
        <RequestProductInformation request={requestGroup} />
        {requestGroup.internalApproval && (
          <ApprovalInfo approval={requestGroup.internalApproval} />
        )}
        {approved === null ? (
          <RequestResponseInput
            showComment={approved === null}
            disabled={approved !== null}
            sending={sending}
            onAccept={this.accept}
            onDeny={this.deny}
          />
        ) : (
          <Discussion
            onAccept={this.accept}
            requestRef={requestGroupRef}
            approved={approved}
            comment={comment}
          />
        )}
        {error && <Typography color="error">{error}</Typography>}
      </Fragment>
    )
  }
}
const RoutedRequestGroupResponse = withRouter(RequestGroupResponse)

class RequestGroup extends Component {
  state = {
    loading: true,
    requestGroup: {},
    requestGroupRef: {},
    requests: [],
  }

  componentDidMount() {
    const { user } = this.props
    if (!(user.isSuperAdmin() || user.isSupplierAdmin())) {
      return
    }
    const requestsRef = database.getDatabase().collection('publicationRequests')
    const requestGroupRef = requestsRef.doc(this.props.requestGroupUid)
    this.requestGroupRef = requestGroupRef
    database.getRequestWithFullProduct(requestGroupRef).then(requestGroup => {
      const requestFetches = requestGroup.requests.map(request =>
        request.requestRef.get().then(requestSnap => ({
          ...request,
          ...requestSnap.data(),
        }))
      )
      Promise.all(requestFetches).then(requests => {
        this.setState({
          requestGroup,
          requestGroupRef,
          loading: false,
          user,
          requests,
        })
      })
    })
  }

  render() {
    const { loading, ...requestProps } = this.state
    const { user } = this.props
    if (loading || !user) {
      return <LinearProgress />
    }
    if (user.isSuperAdmin()) {
      return (
        <RoutedRequestGroupResponse
          {...requestProps}
          requestGroupUid={this.props.requestGroupUid}
        />
      )
    }
    if (user.isSupplierAdmin()) {
      return <RequestGroupReadOnly {...requestProps} />
    }
    return null
  }
}
export default withUser(withRouter(RequestGroup))
