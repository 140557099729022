import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import HourGlass from '@material-ui/icons/HourglassEmpty'
import { withStyles } from '@material-ui/core/styles'
import { timestampToDateString } from '../../utils'

const styles = () => ({
  textIcon: {
    verticalAlign: 'middle',
  },
})

function ApprovalStatus({ approval, classes: { textIcon } }) {
  if (approval.approved === null) {
    return (
      <Typography>
        Ej granskad <HourGlass className={textIcon} />
      </Typography>
    )
  }
  if (approval.approved === true) {
    return (
      <Typography>
        Godkänd <CheckCircleOutline color="primary" className={textIcon} />
        {approval.autoAccept && ' Auto'}
      </Typography>
    )
  }
  if (approval.approved === false) {
    return (
      <Typography>
        Komplettring begärd <ErrorOutline color="error" className={textIcon} />
      </Typography>
    )
  }
  return null
}
const StyledApprovalStatus = withStyles(styles)(ApprovalStatus)

function ApprovalInfo({ approval }) {
  return (
    <Fragment>
      <StyledApprovalStatus approval={approval} />
      {approval.dateTime && (
        <Typography>
          Besvarad {timestampToDateString(approval.dateTime)}
        </Typography>
      )}
      {approval.comment && (
        <Typography>
          Motivering:
          <br />
          {approval.comment}
        </Typography>
      )}
    </Fragment>
  )
}

export default ApprovalInfo
