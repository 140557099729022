import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { stableSort, getSorting } from './statistics/common'

class SortableTable extends Component {
  state = {
    order: 'desc',
    orderBy: '',
  }

  componentDidMount() {
    const { orderBy, order } = this.props
    this.setState({
      orderBy: orderBy || '',
      order: order || 'desc',
    })
  }

  handleRequestSort = property => () => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  render() {
    const { order, orderBy } = this.state
    const { children, rows, data } = this.props
    return (
      <Table>
        <TableHead>
          <TableRow>
            {rows.map(row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'inherit'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                colSpan={row.colSpan || 1}
              >
                {row.disableSorting ? (
                  row.label
                ) : (
                  <Tooltip
                    title="Sortera"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.handleRequestSort(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(data, getSorting(order, orderBy)).map(children)}
        </TableBody>
      </Table>
    )
  }
}
export default SortableTable
