const timestampToDateString = timestamp =>
  timestamp.toDate().toLocaleDateString('sv-SE')

const semverCompare = (a, b) => {
  const pa = a.split('.')
  const pb = b.split('.')
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i])
    const nb = Number(pb[i])
    if (na > nb) return 1
    if (nb > na) return -1
    if (!Number.isNaN(na) && Number.isNaN(nb)) return 1
    if (Number.isNaN(na) && !Number.isNaN(nb)) return -1
  }
  return 0
}
export { timestampToDateString, semverCompare }
