import React, { Component } from 'react'
import {
  withRouter,
  Switch,
  Redirect,
  Link as RouterLink,
} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  Typography,
  Grid,
  Button,
  Paper,
  List,
  ListItemText,
  LinearProgress,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItem,
  Collapse,
  IconButton,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Smartphone from '@material-ui/icons/Smartphone'
import ShowChart from '@material-ui/icons/ShowChart'
import Delete from '@material-ui/icons/Delete'
import Help from '@material-ui/icons/Help'
import Info from '@material-ui/icons/Info'
import Send from '@material-ui/icons/Send'
import EditIcon from '@material-ui/icons/Edit'
import { RestoreFromTrash } from '@material-ui/icons'
import PrivateRoute from '../PrivateRoute'
import { loadFullProduct, streamRequestsForProduct } from '../actions'
import ReviewDialog from './product-input/ReviewDialog'
import MobilePreview from './MobilePreview'
import Quiz from './Quiz'
import ProductSections from './ProductSections'
import ProductBase from './ProductBase'
import PublicationRequestPanel from './quality-control/PublicationRequestPanel'
import ProductEvents from './ProductEvents'
import ProductMedia from './ProductMedia'
import ProductExports from './ProductExports'
import DeepLinkButton from './DeepLinkButton'
import database from '../database'
import { functionsEurope } from '../firebase'
import ConfirmDialog from './ConfirmDialog'

const trashProduct = functionsEurope.httpsCallable('trashProduct')

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(10),
  },
  widget: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
})

const ProductMenu = withStyles(styles)(
  withRouter(props => {
    const {
      match: {
        url,
        params: { productUid },
      },
      history,
      location,
      classes,
    } = props
    const [open, setOpen] = React.useState(true)
    const editBaseUrl = `/product/${productUid}/create`
    return (
      <Paper>
        <List disablePadding>
          <ListItem
            // key={item.path}
            button
            onClick={() => setOpen(!open)}
          >
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText>Produktinformation</ListItemText>

            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                selected={location.pathname === `${url}/base`}
                onClick={() => history.push(`${url}/base`)}
              >
                <ListItemText>Grundläggande information</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => history.push(`${editBaseUrl}/base`)}
                  >
                    <EditIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                selected={location.pathname === `${url}/media`}
                onClick={() => history.push(`${url}/media`)}
              >
                <ListItemText>Media</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => history.push(`${editBaseUrl}/media`)}
                  >
                    <EditIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                selected={location.pathname === `${url}/content`}
                onClick={() => history.push(`${url}/content`)}
              >
                <ListItemText>Utbildningsinnehåll</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => history.push(`${editBaseUrl}/content`)}
                  >
                    <EditIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            // key={item.path}
            button
            selected={location.pathname === `${url}/preview`}
            onClick={() => history.push(`${url}/preview`)}
          >
            <ListItemIcon>
              <Smartphone />
            </ListItemIcon>
            <ListItemText>Förhandsgranskning</ListItemText>
          </ListItem>
          <ListItem
            // key={item.path}
            button
            selected={location.pathname === `${url}/quiz`}
            onClick={() => history.push(`${url}/quiz`)}
          >
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText>Kunskapstest</ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                onClick={() =>
                  history.push(`/product/${productUid}/create/quiz`)
                }
              >
                <EditIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>
    )
  })
)

class ProductView extends Component {
  state = {
    showSendButton: true,
    openReviewDialog: false,
    openHistoryDialog: false,
    openTrashDialog: false,
    trashing: false,
  }

  componentDidMount() {
    const {
      match: {
        params: { productUid },
      },
      product,
      loadFullProduct,
      streamRequestsForProduct,
    } = this.props
    this.unsubscribeToRequest = streamRequestsForProduct(productUid)
    if (!product || product.productUid !== productUid) {
      loadFullProduct(productUid)
    }
  }

  componentWillUnmount() {
    this.unsubscribeToRequest()
  }

  trash = () => {
    this.setState({ trashing: true })
    return trashProduct({
      productUid: this.props.match.params.productUid,
    }).then(() => {
      this.setState(
        {
          openTrashDialog: false,
          trashing: false,
        },
        () => this.props.history.push('/products')
      )
    })
  }

  render() {
    const {
      product,
      match: {
        path,
        params: { productUid },
      },
      publicationRequests,
      pharmacies,
      classes,
    } = this.props
    const {
      showSendButton,
      openReviewDialog,
      openTrashDialog,
      openHistoryDialog,
      trashing,
    } = this.state

    const productPubRequests = publicationRequests[productUid]
    return (
      <div>
        <Typography variant="h4" color="primary" gutterBottom>
          {product.name}
        </Typography>
        <Dialog open={openHistoryDialog} maxWidth="md" fullWidth>
          <DialogTitle title="Granskningshistorik" />
          <DialogContent>
            {productPubRequests &&
              productPubRequests.list.map(request => (
                <PublicationRequestPanel
                  key={request.uid}
                  className={classes.widget}
                  requestGroup={request}
                  pharmacies={pharmacies}
                />
              ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openHistoryDialog: false })}>
              Stäng
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={3}>
          <Grid item lg={6}>
            <>
              {productPubRequests &&
                !product.trashed &&
                productPubRequests.initialized &&
                productPubRequests.list.length > 0 && (
                  <div className={classes.widget}>
                    <PublicationRequestPanel
                      className={classes.widget}
                      requestGroup={productPubRequests.list[0]}
                      pharmacies={pharmacies}
                      onOpenHistory={() =>
                        this.setState({ openHistoryDialog: true })
                      }
                    />
                  </div>
                )}
              <div className={classes.widget}>
                <ProductMenu />
              </div>
              <div className={classes.widget}>
                <Grid
                  container
                  spacing={1}
                  alignItems="flex-start"
                  justify="space-between"
                >
                  <Grid item>
                    <Button
                      // className={classes.button}
                      variant="contained"
                      color="inherit"
                      component={RouterLink}
                      to={`/statistics/product/${productUid}`}
                      endIcon={<ShowChart />}
                    >
                      Statistik
                    </Button>
                  </Grid>
                  <Grid item>
                    <DeepLinkButton productUid={productUid} />
                  </Grid>
                  <Grid item>
                    <ProductExports
                      // className={classes.button}
                      productUid={productUid}
                    />
                  </Grid>
                  <Grid item>
                    {product.trashed ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          database
                            .getDatabase()
                            .collection('products')
                            .doc(product.uid)
                            .update({ trashed: false })
                            .then(() => this.props.loadFullProduct(product.uid))
                        }
                        endIcon={<RestoreFromTrash />}
                      >
                        Återställ
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => this.setState({ openTrashDialog: true })}
                        endIcon={<Delete />}
                      >
                        Ta bort
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </div>
            </>
          </Grid>
          <Grid item lg={6}>
            <>
              {showSendButton &&
                !product.trashed &&
                (!product.lastSentForQualityControlDateTime ||
                  product.lastSentForQualityControlDateTime <
                    product.editDateTime) && (
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Send />}
                    fullWidth
                    className={classes.widget}
                    onClick={() => this.setState({ openReviewDialog: true })}
                  >
                    Skicka{' '}
                    {product.lastSentForQualityControlDateTime && 'ändringar '}
                    till granskning och publicera
                  </Button>
                )}
              <div className={classes.widget}>
                <ProductEvents productUid={productUid} />
              </div>
              {!product.name || product.loading ? (
                <LinearProgress />
              ) : (
                <Switch>
                  <Redirect exact path={`${path}`} to={`${path}/base`} />
                  <PrivateRoute
                    path={`${path}/base`}
                    component={ProductBase}
                    title="Produkt grundläggande info"
                  />
                  <PrivateRoute
                    path={`${path}/media`}
                    component={ProductMedia}
                    title="Produkt media"
                  />
                  <PrivateRoute
                    path={`${path}/content`}
                    component={ProductSections}
                    title="Produkt utbildningsinnehåll"
                  />
                  <PrivateRoute
                    path={`${path}/preview`}
                    component={MobilePreview}
                    title="Produkt förhandsgranskning"
                  />
                  <PrivateRoute
                    path={`${path}/quiz`}
                    component={Quiz}
                    title="Produkt kunskapstest"
                  />
                </Switch>
              )}
            </>
          </Grid>
        </Grid>
        <ReviewDialog
          open={openReviewDialog}
          onClose={() => this.setState({ openReviewDialog: false })}
          onSendSuccess={() =>
            this.setState({ openReviewDialog: false, showSendButton: false })
          }
          productUid={productUid}
        />
        <ConfirmDialog
          title="Vill du verkligen flytta produkten till papperskorgen?"
          text="Produkten försvinner från appen"
          open={openTrashDialog}
          loading={trashing}
          onCancel={() => this.setState({ openTrashDialog: false })}
          onConfirm={this.trash}
          confirmText="Ta bort"
          confirmColor="secondary"
        />
      </div>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  connect(
    state => ({
      product: state.product,
      publicationRequests: state.publicationRequests,
    }),
    { loadFullProduct, streamRequestsForProduct }
  ),
  withRouter
)
export default enhance(ProductView)
