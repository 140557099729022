import React, { Component, Fragment } from 'react'
import { Router } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import '../App.css'
import yellow from '@material-ui/core/colors/yellow'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { Provider, connect } from 'react-redux'
import thunk from 'redux-thunk'
import LinearProgress from '@material-ui/core/LinearProgress'
import { gaTrackerId, version } from '../config'
// import { productInputReducer } from './product-input/actions'
import MainContent from './MainContent'
import PersistentDrawer from './Drawer'
import UpdateSnackbar from './UpdateSnackbar'
import { statisticsReducer } from './statistics/actions'
import { semverCompare } from '../utils'
import {
  loadProducts,
  loadOptions,
  loadUser,
  loadSupplier,
  loadPharmacies,
  removeUser,
  productReducer,
  fullProductsReducer,
  guideReducer,
  productsReducer,
  therapeuticsReducer,
  therapeuticPartsReducer,
  optionsReducer,
  userReducer,
  supplierReducer,
  authReducer,
  pharmaciesReducer,
  requestsReducer,
  loadTherapeutics,
  invitesReducer,
} from '../actions'
import { loadEvents, eventsReducer } from '../events'
import { defaultDateRange } from './statistics/common'
import { Permission } from '../constants'
import { database, analytics, firebaseAuth } from '../firebase'

const history = createBrowserHistory()

ReactGA.initialize(gaTrackerId, {
  debug: false,
})
ReactGA.set({
  dimension4: version,
})

const appReducer = combineReducers({
  product: productReducer,
  guide: guideReducer,
  products: productsReducer,
  therapeutics: therapeuticsReducer,
  therapeuticParts: therapeuticPartsReducer,
  pharmacies: pharmaciesReducer,
  options: optionsReducer,
  user: userReducer,
  supplier: supplierReducer,
  auth: authReducer,
  statistics: statisticsReducer,
  events: eventsReducer,
  publicationRequests: requestsReducer,
  invites: invitesReducer,
  fullProducts: fullProductsReducer,
  // you have to pass formReducer under 'form' key,
  // for custom keys look up the docs for 'getFormState'
  form: formReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'UNATHENTICATED') {
    // Dan said it was ok...
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }
  return appReducer(state, action)
}

const initialState = {
  statistics: {
    products: {},
    dateRange: defaultDateRange,
    excludedBillingProducts: {},
    initialized: false,
  },
  pharmacies: [],
  products: {
    list: [],
    grouped: {},
    initialized: false,
  },
  therapeutics: {
    list: [],
    grouped: {},
    initialized: false,
  },
  invites: {
    list: [],
    initialized: false,
  },
  guide: {
    exampleProduct: {},
    parts: {
      quiz: false,
      content: false,
      base: false,
    },
  },
  therapeuticParts: {},
  publicationRequests: {},
  user: null,
  options: {
    categories: {
      list: [],
      byKey: {},
      byGroup: {},
    },
    classifications: {
      list: [],
      byKey: {},
      byGroup: {},
    },
  },
  events: {
    list: [],
    actionable: {
      list: [],
      loading: false,
      initialized: false,
      lastEventSnap: null,
    },
    informational: {
      list: [],
      loading: false,
      initialized: false,
      lastEventSnap: null,
    },
    products: {},
  },
}

const store = createStore(rootReducer, initialState, applyMiddleware(thunk))

const statColors = {
  blue: '#0088FE',
  green: '#00C49F',
  yellow: '#FFBB28',
  orange: '#FF8042',
  deepBlue: '#086788',
  pink: '#EA526F',
  deepOrange: '#F85A3E',
  purple: '#161032',
  turquoise: '#55DDE0',
  darkGreen: '#034732',
}

const muiTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#579B63',
    },
  },
  status: {
    safe: green[500],
    danger: yellow[500],
    error: red[500],
  },
  stats: {
    colors: Object.values(statColors),
    ...statColors,
  },
})

const initializeAppWithUser = user => {
  store.dispatch(loadProducts())
  store.dispatch(loadPharmacies())
  store.dispatch(loadOptions('classifications', 'index'))
  store.dispatch(loadOptions('categories', 'name'))
  let userType = ''
  let organisation = ''
  if (user.isSuperAdmin() || user.hasPermission(Permission.THERAPEUTICS)) {
    store.dispatch(loadTherapeutics())
  }
  if (user.isSupplierAdmin()) {
    store.dispatch(loadSupplier(user.supplierUid))
    store.dispatch(loadEvents('informational'))
    store.dispatch(loadEvents('actionable'))
    userType = 'supplier_admin'
    organisation = user.supplierUid
  } else if (user.isPharmacyAdmin()) {
    userType = 'pharmacy_admin'
    organisation = user.pharmacyUid
  } else if (user.isSuperAdmin()) {
    userType = 'apostar_admin'
    organisation = 'apostar'
  }
  ReactGA.set({
    userId: user.uid,
    dimension1: userType,
    dimension2: organisation,
  })
  analytics.setUserId(user.uid)
  analytics.setUserProperties({
    userType,
    organisationUid: organisation,
  })
  // ReactGA.pageview(window.location.pathname + window.location.search);
}

const makeUser = user => ({
  ...user,
  isPharmacyAdmin: () =>
    (user.permission === 'admin' ||
      (user.permissions && user.permissions.admin)) &&
    user.type === 'pharmacist',
  isSupplierAdmin: () =>
    (user.permission === 'admin' ||
      (user.permissions && user.permissions.admin)) &&
    user.type === 'supplier',
  isSuperAdmin: () =>
    user.permission === 'superadmin' ||
    (user.permissions && user.permissions.superadmin),
  getFullName: () => `${user.firstname} ${user.lastname}`,
  hasPermission: permission =>
    user.permissions && user.permissions[permission] === true,
})

firebaseAuth.onAuthStateChanged(authUser => {
  if (!authUser) {
    store.dispatch(removeUser())
    store.dispatch({ type: 'UNAUTHENTICATED' })
  } else {
    database
      .collection('users')
      .doc(authUser.uid)
      .onSnapshot(userSnapshot => {
        const user = {
          uid: authUser.uid,
          ...userSnapshot.data(),
        }
        const fullUser = makeUser(user)
        const prevUser = store.getState().user
        store.dispatch(loadUser(fullUser))
        store.dispatch({ type: 'AUTHENTICATED' })
        // Only init when new user is loaded, not just edited
        if (!prevUser) {
          initializeAppWithUser(fullUser)
        }
      })
  }
})

function AuthCanvas(props) {
  const { auth } = props
  if (auth.initialized) {
    return (
      <Router history={history}>
        <PersistentDrawer>
          <MainContent />
        </PersistentDrawer>
      </Router>
    )
  }
  return <LinearProgress />
}
const Canvas = connect(state => ({
  auth: state.auth,
}))(AuthCanvas)

class App extends Component {
  state = {
    updateAvailable: false,
  }

  componentDidMount() {
    if (this.props.onUpdate) {
      this.props.onUpdate.then(updateAvailable => {
        this.setState({ updateAvailable })
        database
          .collection('system')
          .doc('settings')
          .get()
          .then(settingsSnap => {
            const retries = parseInt(
              window.sessionStorage.getItem('updateRetries') || 0,
              10
            )
            const minimumVersion =
              settingsSnap.get('minimumWebVersion') || '0.0.0'
            if (semverCompare(version, minimumVersion) === -1 && retries < 3) {
              window.sessionStorage.setItem('updateRetries', retries + 1)
              window.location.reload(true)
            } else {
              window.sessionStorage.setItem('updateRetries', 0)
            }
          })
      })
    }
  }

  render() {
    return (
      <Fragment>
        <Provider store={store}>
          <MuiThemeProvider theme={muiTheme}>
            <UpdateSnackbar updateAvailable={this.state.updateAvailable} />
            <Canvas />
          </MuiThemeProvider>
        </Provider>
      </Fragment>
    )
  }
}

export default App
