import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import { Button, Typography } from '@material-ui/core'
import { connect } from 'react-redux'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventId: null,
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      scope.setUser({ id: this.props.user ? this.props.user.uid : null })
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.hasError) {
      // render fallback UI
      const { user = {} } = this.props
      return (
        <>
          <Typography variant="h4" color="error">
            Något gick fel!
          </Typography>
          <br />
          <Button
            onClick={() =>
              Sentry.showReportDialog({
                lang: 'se',
                user: {
                  email: user.email,
                  name: `${user.firstname} ${user.lastname}`,
                },
                eventId: this.state.eventId,
                title: 'Det verkar som att vi har problem',
                subtitle: 'Vårt team har blivit informerade.',
                subtitle2: 'Om du vill hjälpa oss, berätta gärna vad som hände',
                labelName: 'Namn',
                labelEmail: 'Epost',
                labelComments: 'Vad hände?',
                labelClose: 'Stäng',
                labelSubmit: 'Skicka',
                errorGeneric:
                  'Ett okänt fel uppstod vid inskickning av din rapport. Försök igen',
                errorFormEntry:
                  'Några fält är felaktiga. Var god korrigera fälten',
                successMessage: 'Tack! Din felrapport har skickats',
              })
            }
            variant="contained"
          >
            Rapportera fel
          </Button>
        </>
      )
    }

    // when there's not an error, render children untouched
    return this.props.children
  }
}

export default connect(state => ({
  user: state.user,
}))(ErrorBoundary)
