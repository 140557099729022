import React, { Component, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { database, firebaseAuth } from '../../firebase'
import ChangePassword from './ChangePassword'
import EditableFields, { getDataToUpdate } from '../EditableFields'
import NotificationSettings from './NotificationSettings'
import SummarySettings from './SummarySettings'

const userFields = [
  { label: 'Förnamn', prop: 'firstname' },
  { label: 'Efternamn', prop: 'lastname' },
  { label: 'Yrkestitel', prop: 'role' },
]

class User extends Component {
  saveUser = event => userData => {
    event.preventDefault()
    const userUpdateObj = getDataToUpdate(userFields, userData)
    return database
      .collection('users')
      .doc(this.props.user.uid)
      .update(userUpdateObj)
  }

  signout = () => {
    return firebaseAuth.signOut()
  }

  render() {
    const { user } = this.props
    return (
      <Fragment>
        <Grid container spacing={3} direction="column">
          {!user ? (
            <LinearProgress />
          ) : (
            <Fragment>
              <Grid item>
                <EditableFields
                  title="Mitt konto"
                  fields={userFields}
                  fieldsData={user}
                  onSave={this.saveUser}
                />
              </Grid>
              {user.isSupplierAdmin() && (
                <>
                  <Grid item>
                    <SummarySettings />
                  </Grid>
                  <Grid item>
                    <NotificationSettings user={user} />
                  </Grid>
                </>
              )}
              <Grid item>
                <ChangePassword email={user.email} />
              </Grid>
            </Fragment>
          )}
        </Grid>
        <Button
          variant="contained"
          style={{ marginTop: 24 }}
          onClick={this.signout}
        >
          Logga ut
        </Button>
      </Fragment>
    )
  }
}

export default connect(state => ({ user: state.user }))(User)
