import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TherapeuticsQuizInput from './TherapeuticsQuizInput'
import TherapeuticsSectionsInput from './TherapeuticsSectionsInput'

export default function TherapeuticPartInput(props) {
  const { storagePath, onSave, disabled } = props
  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <Typography variant="h6">Utbildningsmaterial</Typography>
        <TherapeuticsSectionsInput
          storagePath={storagePath}
          form="therapeuticPart"
        />
      </Grid>
      <Grid item md={6}>
        <Typography variant="h6">Kunskapstest</Typography>
        <TherapeuticsQuizInput storagePath={storagePath} />
      </Grid>
      <Button
        onClick={onSave}
        variant="contained"
        color="primary"
        disabled={disabled}
      >
        Spara del
      </Button>
    </Grid>
  )
}
