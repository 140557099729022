import React from 'react'
import Button from '@material-ui/core/Button'
import { Field, reduxForm, reset } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Grid } from '@material-ui/core'
import { MTableToolbar } from 'material-table'
import DataTable from './DataTable'
import database from '../database'
import { FormTextField, FormSelect } from './product-input/input-components'
import { serverTimestamp } from '../firebase'
import { withUser } from '../actions'
import InviteLinkButton from './InviteLinkButton'

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Ogiltig epost'
    : undefined

const required = value => (value ? undefined : 'Obligatorisk')

const InvitesForm = reduxForm({
  form: 'invites',
  onSubmitSuccess: (result, dispatch) => {
    dispatch(reset('invites'))
  },
})(props => {
  const {
    handleSubmit,
    submitting,
    invalid,
    pristine,
    suppliers = [],
    user,
  } = props
  const submit = values => {
    const supplier = suppliers.find(
      supplier => supplier.value === values.supplierUid
    )
    const supplierUid = values.supplierUid || user.supplierUid
    const supplierName = supplier ? supplier.name : ''
    return database
      .getDatabase()
      .collection('invitations')
      .add({
        type: 'supplier',
        supplierName,
        creationDateTime: serverTimestamp(),
        createdByUserUid: user.uid,
        createdBySupplier: Boolean(user.supplierUid),
        ...values,
        supplierUid,
      })
  }
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={3} style={{ marginLeft: 2 }}>
        <Grid item>
          <Field
            name="firstname"
            label="Förnamn"
            component={FormTextField}
            // className={classes.textField}
            variant="outlined"
            validate={required}
            disabled={submitting}
          />
        </Grid>
        <Grid item>
          <Field
            name="lastname"
            label="Efternamn"
            component={FormTextField}
            // className={classes.textField}
            variant="outlined"
            validate={required}
            disabled={submitting}
          />
        </Grid>
        <Grid item>
          <Field
            name="email"
            label="Epost"
            component={FormTextField}
            // className={classes.textField}
            variant="outlined"
            validate={[required, email]}
            disabled={submitting}
          />
        </Grid>
        {user.isSuperAdmin() && (
          <Grid item>
            <Field
              name="supplierUid"
              label="Leverantör"
              options={suppliers}
              component={FormSelect}
              variant="outlined"
              validate={required}
              disabled={submitting}
              fullWidth={false}
            />
          </Grid>
        )}
        <Grid item>
          <Button
            style={{ height: '100%' }}
            color="primary"
            type="submit"
            disabled={pristine || invalid || submitting}
            variant="outlined"
          >
            Skicka inbjudan
          </Button>
        </Grid>
      </Grid>
    </form>
  )
})

function Invites(props) {
  const { invites, suppliers = [], user } = props
  return (
    <DataTable
      title="Inbjudan konto"
      user={user}
      options={{
        filtering: user.isSuperAdmin(),
        emptyRowsWhenPaging: false,
      }}
      components={{
        Toolbar: props => (
          <div>
            <MTableToolbar {...props} />
            <InvitesForm user={user} suppliers={suppliers} />
          </div>
        ),
      }}
      columns={[
        { field: 'firstname', title: 'Förnamn' },
        { field: 'lastname', title: 'Efternamn' },
        { field: 'email', title: 'E-post' },
        {
          field: 'link',
          title: 'Länk',
          render: ({ link }) => <InviteLinkButton link={link} />,
        },
        { field: 'accepted', title: 'Accepterad', type: 'boolean' },
        // { field: 'error', title: 'Error', type: 'boolean' },
        {
          field: 'creationDateTime',
          title: 'Datum',
          type: 'datetime',
          render: ({ creationDateTime }) =>
            creationDateTime &&
            creationDateTime.toDate().toLocaleDateString('sv-SV'),
        },
      ].concat(
        user.isSuperAdmin()
          ? { field: 'supplierName', title: 'Leverantör' }
          : []
      )}
      data={invites}
      emptyText="Inbjudan saknas"
    />
  )
}
export default compose(
  withUser,
  connect(state => ({
    invites: state.invites.list,
  }))
)(Invites)
