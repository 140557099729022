import React, { Fragment } from 'react'
import Radio from '@material-ui/core/Radio'
import Grid from '@material-ui/core/Grid'
import { Field, FieldArray, change } from 'redux-form'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CardActions from '@material-ui/core/CardActions'
import HelpIcon from '@material-ui/icons/Help'
import { Typography } from '@material-ui/core'
import { uploadImage } from '../../StorageUtils'
import { PRODUCT_TYPES } from '../../constants'
import ImageUpload from '../ImageUpload'
import { emptyQuestion } from './common'
import { FormRemovableImage, FormTextField } from './input-components'
import ProductInputCard from './ProductInputCard'

const MIN_QUESTIONS = 3
const MAX_QUESTIONS = 7

const FormRadioButton = ({
  input: { value },
  meta,
  altIndex,
  setCorrect,
  ...props
}) => (
  <FormControlLabel
    key={altIndex}
    value={value}
    control={<Radio checked={value} onChange={() => setCorrect(altIndex)} />}
    label={value ? 'Rätt svar' : ''}
    {...props}
  />
)

const renderAlternatives = ({ fields, meta, setter }) => {
  const setCorrect = setter(meta.form, fields)
  return (
    <Grid container spacing={2} align="center">
      {fields.map((alternative, altIndex) => (
        <Fragment>
          <Grid item xs={11}>
            <Field
              key={altIndex}
              label={`Alternativ ${altIndex + 1}`}
              name={`${alternative}.text`}
              component={FormTextField}
              spellCheck
              fullWidth
              multiline
            />
          </Grid>
          <Grid item xs={1}>
            <Field
              key={altIndex}
              name={`${alternative}.correct`}
              altIndex={altIndex}
              component={FormRadioButton}
              setCorrect={setCorrect}
            />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  )
}

const uploadQuestionImage = (storagePath, imageFile) =>
  uploadImage(`${storagePath}/images`, imageFile)

const renderImageUpload = ({
  input: { onChange },
  storagePath,
  value,
  ...custom
}) => (
  <ImageUpload
    onImageSelect={file =>
      uploadQuestionImage(storagePath, file).then(onChange)
    }
    {...custom}
  />
)

const renderQuestions = ({ fields, storagePath, type, setter }) => {
  return (
    <Grid container direction="column" spacing={3}>
      {fields.map((question, index) => (
        <Grid item key={`${question}-index`}>
          <ProductInputCard title={`Fråga ${index + 1}`} key={index}>
            <Field name={`${question}.image`} component={FormRemovableImage} />
            <Grid container spacing={1} align="center">
              <Grid item xs={11}>
                <Field
                  label="Frågeställning"
                  name={`${question}.question`}
                  component={FormTextField}
                  fullWidth
                  spellCheck
                  multiline
                />
              </Grid>
              <Grid item xs={1}>
                <HelpIcon />
              </Grid>
            </Grid>
            <Typography style={{ marginTop: 16 }} gutterBottom>
              Ange dina svarsalternativ och markera rätt svar
            </Typography>
            <FieldArray
              name={`${question}.alternatives`}
              component={renderAlternatives}
              setter={setter}
            />
            <CardActions>
              <Field
                name={`${question}.image`}
                storagePath={storagePath}
                component={renderImageUpload}
              />
              {index >= MIN_QUESTIONS && (
                <Button onClick={() => fields.remove(index)}>Ta bort</Button>
              )}
            </CardActions>
          </ProductInputCard>
        </Grid>
      ))}
      {(fields.length < MAX_QUESTIONS || type === PRODUCT_TYPES.GENERIC) && (
        <Grid item>
          <Button
            onClick={() => fields.push(emptyQuestion)}
            variant="contained"
          >
            Lägg till fråga
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

const formName = 'education'
function QuizInput(props) {
  return <FieldArray name="quiz" {...props} component={renderQuestions} />
}
export default connect(null, dispatch => ({
  setter: (form, altFields) => correctIndex => {
    altFields.forEach((alternative, index) => {
      dispatch(
        change(formName, `${alternative}.correct`, index === correctIndex)
      )
    })
  },
}))(QuizInput)
