import React from 'react'
import { connect } from 'react-redux'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Chip,
  CardHeader,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  card: {
    marginBottom: theme.spacing(3),
  },
  missing: {
    fontStyle: 'italic',
  },
})

function ProductBase(props) {
  const {
    categories,
    classifications,
    product: {
      name,
      eanNumber,
      classificationUid,
      categoryUid,
      minimiText,
      tags = [],
    },
    classes,
  } = props
  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Typography variant="h6">Namn</Typography>
              <Typography>{name}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6">Klassificering</Typography>
              {classifications && classifications[classificationUid] && (
                <Typography>
                  {classifications[classificationUid].name}
                </Typography>
              )}
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6">Kategori</Typography>
              {categories && categories[categoryUid] && (
                <Typography>{categories[categoryUid].name}</Typography>
              )}
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6">EAN-nummer</Typography>
              <Typography>{eanNumber}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardHeader
          title="Taggar"
          // action={
          //   <IconButton>
          //     <PenIcon />
          //   </IconButton>
          // }
        />
        <CardContent>
          {tags.length === 0 && (
            <Typography className={classes.missing}>Taggar saknas</Typography>
          )}
          {tags.map(tag => (
            <Chip
              key={tag}
              label={tag}
              style={{ marginRight: 8, marginBottom: 8 }}
            />
          ))}
        </CardContent>
      </Card>
      {minimiText && (
        <Card className={classes.card}>
          <CardHeader title="Plikttext" />
          <CardContent>
            <Typography>{minimiText}</Typography>
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default withStyles(styles)(
  connect(state => ({
    product: state.product,
    categories: state.options.categories.byKey,
    classifications: state.options.classifications.byKey,
  }))(ProductBase)
)
