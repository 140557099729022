import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import SimpleLineChart from './SimpleLineChart'
import PharmacyDistribution from './PharmacyDistribution'
import { loadProduct } from './actions'
import LineChartTotals from './LineChartTotals'
import QuestionStatisticsTable from './QuestionStatisticsTable'
import { styles } from './common'

class ProductStatistics extends Component {
  componentDidMount() {
    const { pharmacies, productUid, dateRange } = this.props
    this.props.loadProduct(productUid, dateRange, pharmacies)
    // this.props.loadProductQuestions(productUid, dateRange)
  }

  componentDidUpdate(prevProps) {
    const { dateRange, pharmacies, productUid, data } = this.props
    const dateRangeChanged = prevProps.dateRange !== dateRange
    const productChanged = prevProps.productUid !== productUid
    const invalidDateRange =
      data &&
      (data.dateRange.to !== dateRange.to ||
        data.dateRange.from !== dateRange.from)
    const pharmaciesChanged = prevProps.pharmacies !== pharmacies
    if (
      (dateRangeChanged ||
        productChanged ||
        invalidDateRange ||
        pharmaciesChanged) &&
      pharmacies.length
    ) {
      this.props.loadProduct(productUid, dateRange, pharmacies)
    }
  }

  render() {
    const { data, product, classes, initialized } = this.props
    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom>
          {product && product.name}
        </Typography>
        {initialized && Boolean(data) && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <LineChartTotals
                  data={data.educationTotals}
                  distributedCount={product.activeEducationCount || 0}
                  undistributedCount={0}
                />
                <SimpleLineChart
                  data={data.lineChartData}
                  className={classes.section}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <PharmacyDistribution data={data} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader title="Kunskapstest frågor" />
                <CardContent>
                  <QuestionStatisticsTable data={data.questionsData} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    )
  }
}
const enhance = compose(
  withStyles(styles),
  connect(
    (state, props) => ({
      ...state.statistics,
      product:
        state.products.initialized &&
        state.products.list.find(product => product.uid === props.productUid),
      data: state.product && state.statistics.products[props.productUid],
      pharmacies: state.pharmacies,
    }),
    { loadProduct }
  )
)
export default enhance(ProductStatistics)
