import React from 'react'
import {
  Pie,
  PieChart,
  Legend,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { withTheme } from '@material-ui/core/styles'
import _ from 'lodash'
import { withData } from './common'

function SimplePieChart(props) {
  const { data, dataKey, total, theme } = props
  const sortedData = _.orderBy(data, dataKey, 'desc')
  const { colors } = theme.stats
  return (
    <ResponsiveContainer width="99%" height={320}>
      <PieChart>
        <Tooltip
          formatter={value => `${((value / total) * 100).toFixed(0)}%`}
        />
        <Pie
          data={sortedData}
          dataKey={dataKey}
          outerRadius={100}
          cx="50%"
          cy="50%"
          fill="#82ca9d"
          label
        >
          {data.map((entry, index) => (
            <Cell key={entry.uid} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend verticalAlign="middle" layout="vertical" align="right" />
      </PieChart>
    </ResponsiveContainer>
  )
}
export default withData(withTheme(SimplePieChart), data =>
  data.some(({ passedCount, clickCount }) => passedCount || clickCount)
)
