import React from 'react'
import LinkIcon from '@material-ui/icons/Link'
import { Typography, Link, AppBar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Paragraphs from './Paragraphs'
import Image from './Image'
import Video from './Video'

const useStyles = makeStyles(theme => ({
  name: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  minimiTextHeader: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  minimiText: {
    fontSize: 10,
  },
  content: {
    padding: theme.spacing(1),
  },
}))

export default function MobileEducation(props) {
  const {
    product: { name, image, video, sections = [], minimiText },
  } = props
  const classes = useStyles()
  return (
    <>
      <AppBar position="sticky">
        {/* <Toolbar> */}
        <Typography variant="h5" className={classes.name}>
          {name}
        </Typography>
        {/* </Toolbar> */}
      </AppBar>
      {image && (
        <Image image={image} style={{ margin: 'auto', display: 'block' }} />
      )}
      {video && (
        <div>
          <Typography variant="h6" color="primary" className={classes.content}>
            Produktfilm
          </Typography>
          <Video video={video} />
        </div>
      )}

      <div className={classes.content}>
        {sections.map(section => (
          <div key={section.uid}>
            <Typography gutterBottom variant="h6" color="primary">
              {section.title}
            </Typography>
            {section.irrelevant && (
              <Typography style={{ fontStyle: 'italic' }}>
                Ej relevant
              </Typography>
            )}
            {section.image && <Image image={section.image} />}
            <Paragraphs>{section.text}</Paragraphs>
            {section.list && (
              <ul style={{ paddingLeft: 20 }}>
                {section.list.map((listText, index) =>
                  listText ? <li key={index}>{listText}</li> : null
                )}
              </ul>
            )}
            {section.links &&
              section.links.map((link, index) => (
                <Typography key={index}>
                  <Link color="inherit" href={link.url} target="_blank">
                    <LinkIcon style={{ verticalAlign: 'middle' }} />{' '}
                    {link.label || link.url}
                  </Link>
                </Typography>
              ))}
          </div>
        ))}
        {minimiText && (
          <>
            <Typography className={classes.minimiTextHeader}>
              Plikttext
            </Typography>
            <Typography className={classes.minimiText}>{minimiText}</Typography>
          </>
        )}
      </div>
    </>
  )
}
