import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  LinearProgress,
} from '@material-ui/core'
import { connect } from 'react-redux'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { streamProductEvents, markEventComplete } from '../events'
import EventList from './EventList'

function ProductEvents(props) {
  const { events, productUid, streamProductEvents } = props
  React.useEffect(() => {
    const unsubcribe = streamProductEvents(productUid)
    return unsubcribe
  }, [productUid])
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {!events && <LinearProgress />}
        {events && events.length ? (
          <Typography style={{ width: '100%' }}>
            Att åtgärda
            <span style={{ float: 'right' }}>
              {events.length}
              <ErrorOutline
                color="secondary"
                style={{ verticalAlign: 'middle' }}
              />
            </span>
          </Typography>
        ) : (
          <Typography>Inget att åtgärda</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {events ? <EventList events={events} /> : <LinearProgress />}
      </AccordionDetails>
      {/* <AccordionActions /> */}
    </Accordion>
  )
}
export default connect(
  (state, ownProps) => ({
    events: state.events.products[ownProps.productUid],
  }),
  { streamProductEvents, markEventComplete }
)(ProductEvents)
