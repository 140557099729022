import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  /* The device with borders */
  smartphone: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: 360 * 0.6,
      height: 640 * 0.6,
    },
    [theme.breakpoints.down(1450)]: {
      width: 360 * 0.8,
      height: 640 * 0.8,
    },
    [theme.breakpoints.up(1450)]: {
      width: 360,
      height: 640,
    },
    margin: 'auto',
    border: '16px black solid',
    borderTopWidth: 60,
    borderBottomWidth: 60,
    borderRadius: 37,
    /* The horizontal line on the top of the device */
    '&:before': {
      content: '""',
      display: 'block',
      width: 60,
      height: 5,
      position: 'absolute',
      top: -30,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#333',
      borderRadius: 10,
    },

    /* The circle on the bottom of the device */
    '&:after': {
      content: '""',
      display: 'block',
      width: 35,
      height: 35,
      position: 'absolute',
      left: '50%',
      bottom: '-65',
      transform: 'translate(-50%, -50%)',
      background: '#333',
      borderRadius: '50%',
    },
  },

  /* The screen (or content) of the device */
  content: {
    [theme.breakpoints.down('md')]: {
      width: 360 * 0.6,
      height: 640 * 0.6,
    },
    [theme.breakpoints.down(1450)]: {
      width: 360 * 0.8,
      height: 640 * 0.8,
    },
    [theme.breakpoints.up(1450)]: {
      width: 360,
      height: 640,
    },
    background: 'white',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
})

const MobileDevice = props => {
  const { classes, children } = props
  return (
    <div className={classes.smartphone}>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

export default withStyles(styles)(MobileDevice)
