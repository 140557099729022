import React from 'react'
import Typography from '@material-ui/core/Typography'

const Paragraphs = ({ children, ...props }) =>
  children
    ? React.Children.map(children, child => {
        if (typeof child === 'string') {
          return child.split('\n').map((paragraph, index) =>
            paragraph ? (
              <Typography key={index} {...props}>
                {paragraph}
              </Typography>
            ) : (
              <br key={index} />
            )
          )
        }
        return child
      })
    : null
export default Paragraphs
