import uuidv4 from 'uuid/v4'

import { storage as storageNamespace } from 'firebase/app'
import { storage, functionsEurope } from './firebase'
import 'firebase/storage'

const getFileEnding = file => file.name.split('.').pop()

const getUploadedFileMetadata = (uploadTask, file) =>
  uploadTask.then(uploadTaskSnap =>
    uploadTaskSnap.ref.getDownloadURL().then(downloadURL => ({
      originalName: file.name,
      name: uploadTaskSnap.metadata.name,
      storageRef: uploadTaskSnap.metadata.fullPath,
      contentType: uploadTaskSnap.metadata.contentType,
      url: downloadURL,
    }))
  )

const uploadFile = (basePath, file) => {
  const uid = uuidv4()
  const fileEnding = getFileEnding(file)
  const path = `${basePath}/${uid}.${fileEnding}`
  const uploadTask = storage.ref(path).put(file)
  return uploadTask
}
const uploadImage = (basePath, imageFile) => {
  const uploadTask = uploadFile(basePath, imageFile)
  return getUploadedFileMetadata(uploadTask, imageFile).then(metadata => {
    const payload = {
      path: metadata.storageRef,
      contentType: metadata.contentType,
    }
    const generateFormattedImage = functionsEurope.httpsCallable(
      'generateFormattedImage'
    )
    return generateFormattedImage(payload).then(formattedImage =>
      Object.assign(metadata, formattedImage.data)
    )
  })
}

const monitorUpload = (uploadTask, onUpdate) => {
  uploadTask.on(storageNamespace.TaskEvent.STATE_CHANGED, snapshot => {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    const update = {
      progress: parseInt(progress, 10),
    }
    switch (snapshot.state) {
      case storageNamespace.TaskState.PAUSED: // or 'paused'
        update.isPaused = true
        update.isRunning = false
        break
      case storageNamespace.TaskState.RUNNING: // or 'running'
        update.isPaused = false
        update.isRunning = true
        break
      default:
        break
    }
    if (onUpdate) {
      onUpdate(update)
    }
  })
}

const getProductStorageRef = productUid => storage.ref(`products/${productUid}`)

export {
  uploadImage,
  uploadFile,
  monitorUpload,
  getUploadedFileMetadata,
  getProductStorageRef,
}
