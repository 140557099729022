import React, { Fragment } from 'react'
import {
  Typography,
  CardActions,
  CardContent,
  Radio,
  Button,
  Grid,
  FormControlLabel,
} from '@material-ui/core'
import { Field, FieldArray, change, formValueSelector } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  FormRemovableImage,
  FormTextField,
  FormImageUpload,
} from './input-components'
import ProductInputCard from './ProductInputCard'
import { required } from './common'
import HelpPopover from '../HelpPopover'
import ProductQuestionContent from '../ProductQuestionContent'

const MIN_QUESTIONS = 3

const FormRadioButton = ({
  input: { value },
  meta,
  altIndex,
  setCorrect,
  ...props
}) => (
  <FormControlLabel
    key={altIndex}
    value={value}
    control={<Radio checked={value} onChange={() => setCorrect(altIndex)} />}
    style={{ marginRight: 0, marginLeft: 0 }}
    {...props}
  />
)

const renderAlternatives = ({ fields, meta, setter }) => {
  const setCorrect = setter(meta.form, fields)
  return (
    <Grid container spacing={2} alignItems="center">
      {fields.map((alternative, altIndex) => (
        <Fragment key={alternative}>
          <Grid item xs={11}>
            <Field
              label={`Alternativ ${altIndex + 1}`}
              name={`${alternative}.text`}
              component={FormTextField}
              spellCheck
              fullWidth
              multiline
              validate={required}
            />
          </Grid>
          <Grid item xs={1}>
            <Field
              key={altIndex}
              name={`${alternative}.correct`}
              altIndex={altIndex}
              component={FormRadioButton}
              setCorrect={setCorrect}
            />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  )
}

function ProductQuestionInput(props) {
  const {
    questionUid,
    fieldname,
    match: {
      params: { productUid },
    },
    index,
    onRemove,
    setter,
    exampleProduct,
  } = props

  return (
    <ProductInputCard
      title={`Fråga ${index + 1}`}
      key={index}
      validate={errors => !(errors.quiz && errors.quiz[index])}
    >
      <CardContent>
        <Field name={`${fieldname}.image`} component={FormRemovableImage} />
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={11}>
            <Field
              label="Frågeställning"
              name={`${fieldname}.question`}
              component={FormTextField}
              fullWidth
              spellCheck
              multiline
              validate={required}
            />
          </Grid>
          <Grid item xs={1}>
            <HelpPopover text="Formulera gärna frågan som en arbetssituation för apotekspersonal. Svaret bör finnas i utbildningsinnehållet">
              {exampleProduct.quiz && (
                <ProductQuestionContent question={exampleProduct.quiz[index]} />
              )}
            </HelpPopover>
          </Grid>
        </Grid>
        <Typography style={{ marginTop: 16 }} gutterBottom>
          Ange dina svarsalternativ och markera rätt svar{' '}
          <span style={{ float: 'right' }}>Rätt svar</span>
        </Typography>
        <FieldArray
          name={`${fieldname}.alternatives`}
          component={renderAlternatives}
          setter={setter}
        />
      </CardContent>
      <CardActions>
        <Field
          name={`${fieldname}.image`}
          component={FormImageUpload}
          storagePath={`products/${productUid}`}
        />
        {index >= MIN_QUESTIONS && (
          <Button
            onClick={() => onRemove(index, questionUid)}
            variant="outlined"
          >
            Ta bort
          </Button>
        )}
        {/* <SaveCollectionDocButton
          fieldname={fieldname}
          collection="quiz"
          productUid={productUid}
        /> */}
      </CardActions>
    </ProductInputCard>
  )
}

const selector = formValueSelector('quiz')
export default withRouter(
  connect(
    (state, ownProps) => ({
      exampleProduct: state.guide.exampleProduct,
      questionUid: selector(state, `${ownProps.fieldname}.uid`),
    }),
    dispatch => ({
      setter: (form, altFields) => correctIndex => {
        altFields.forEach((alternative, index) => {
          dispatch(
            change('quiz', `${alternative}.correct`, index === correctIndex)
          )
        })
      },
    })
  )(ProductQuestionInput)
)
