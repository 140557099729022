import React, { Component, Fragment } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import ProductSections from '../ProductSections'
import { loadTherapeuticPart } from '../../actions'
import Quiz from '../Quiz'

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  navigation: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})

class TherapeuticPartOverview extends Component {
  componentDidMount() {
    const {
      therapeuticPart,
      loadTherapeuticPart,
      match: {
        params: { therapeuticUid, partUid },
      },
    } = this.props
    if (!therapeuticPart) {
      loadTherapeuticPart(therapeuticUid, partUid)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      loadTherapeuticPart,
      match: {
        params: { therapeuticUid, partUid },
      },
    } = this.props
    const {
      match: {
        params: { therapeuticUid: prevTherapeuticUid, partUid: prevPartUid },
      },
    } = prevProps
    if (prevPartUid !== partUid || prevTherapeuticUid !== therapeuticUid) {
      loadTherapeuticPart(therapeuticUid, partUid)
    }
  }

  render() {
    const {
      therapeuticPart,
      match: {
        params: { therapeuticUid, partUid },
      },
    } = this.props
    if (!therapeuticPart) {
      return null
    }
    const { loading, initialized, sections, quiz } = therapeuticPart
    if (!initialized || loading) {
      return <LinearProgress />
    }
    return (
      <Fragment>
        <Button
          variant="contained"
          component={Link}
          to={`/therapeutic/${therapeuticUid}/part/${partUid}/edit`}
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          Redigera del
        </Button>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography>Utbildningsmaterial</Typography>
            <ProductSections sections={sections} diffs={{}} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Kunskapstest</Typography>
            <Quiz quiz={quiz} diffs={{}} />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

const enhance = compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const { partUid, therapeuticUid } = ownProps.match.params
      const parts = state.therapeuticParts[therapeuticUid]
      const partsData = parts && parts.partsData
      return {
        therapeuticPart: partsData ? parts.partsData[partUid] : null,
      }
    },
    { loadTherapeuticPart }
  ),
  withStyles(styles)
)
export default enhance(TherapeuticPartOverview)
