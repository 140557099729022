import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import '../App.css'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import _ from 'lodash'
import {
  Edit,
  ErrorOutline,
  CheckCircleOutline,
  Warning,
  RestoreFromTrash,
} from '@material-ui/icons'
import DataTable from './DataTable'
import Image from './Image'
import QualityControlStatus from './quality-control/QualityControlStatus'
import { timestampToDateString } from '../utils'
import database from '../database'

const baseUrl = '/product'

const columnDefs = {
  name: {
    title: 'Produkt',
    field: 'name',
    render: product => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link
          to={`${baseUrl}/${product.uid}`}
          style={{ display: 'block', verticalAlign: 'middle' }}
        >
          {product.image ? (
            <Image image={product.image} maxWidth={50} />
          ) : (
            <div style={{ width: 50 }} />
          )}
        </Link>
        <Link
          to={
            product.isComplete ||
            product.lastSentForQualityControlDateTime ||
            !product.type
              ? `${baseUrl}/${product.uid}`
              : `${baseUrl}/${product.uid}/create`
          }
          style={{
            marginLeft: 16,
            display: 'table-cell',
            verticalAlign: 'middle',
            fontStyle: product.name ? 'normal' : 'italic',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          {product.name || 'Namnlös'}
        </Link>
      </div>
    ),
  },
  qualityControlSummary: {
    title: 'Kvalitetsgranskning',
    sorting: false,
    field: 'qualityControlSummary',
    filtering: false,
    width: '15%',
    render: product => (
      <QualityControlStatus
        summary={product.qualityControlSummary}
        productUid={product.uid}
      />
    ),
  },
  published: {
    title: 'Publicerad',
    field: 'approvedPharmacyUids',
    type: 'boolean',
    width: '10%',
    customFilterAndSearch: (term, product) =>
      term === (product.approvedPharmacyUids.length ? 'checked' : 'unchecked'),
    customSort: (a, b) =>
      a.approvedPharmacyUids.length - b.approvedPharmacyUids.length,
    render: product =>
      product.approvedPharmacyUids &&
      Boolean(product.approvedPharmacyUids.length) ? (
        <CheckCircleOutline color="primary" />
      ) : (
        <ErrorOutline color="error" />
      ),
  },
  editDateTime: {
    title: 'Senast ändrad',
    field: 'editDateTime',
    type: 'date',
    defaultSort: 'desc',
    filtering: false,
    width: '15%',
    render: product =>
      product.editDateTime && (
        <Typography>{timestampToDateString(product.editDateTime)}</Typography>
      ),
  },
  progress: {
    title: 'Utveckling',
    field: 'completion',
    defaultSort: 'desc',
    filtering: false,
    customSort: (a, b) =>
      _.sum(
        Object.keys(a.completion || {}).map(key =>
          _.toInteger(a.completion[key])
        )
      ) -
      _.sum(
        Object.keys(b.completion || {}).map(key =>
          _.toInteger(b.completion[key])
        )
      ),
    render: product => (
      <LinearProgress
        variant="determinate"
        style={{ width: 140 }}
        value={
          product.completion
            ? _.sum(Object.values(product.completion)) /
              Object.keys(product.completion).length
            : 0
        }
      />
    ),
  },
  activeEducation: {
    title: 'Aktiv',
    field: 'activeEducation',
    type: 'boolean',
    render: product =>
      product.activeEducation === true ? (
        <CheckCircleOutline style={{ paddingLeft: 12 }} color="primary" />
      ) : (
        <Tooltip title="Användare kan inte ta produktens kunskapstest. Fördela kunskapstest och aktivera för att säkerställa kunskapen">
          <Link to={`${baseUrl}/${product.uid}/activation`}>
            <IconButton>
              <Warning color="action" />
            </IconButton>
          </Link>
        </Tooltip>
      ),
  },
  activeEducationCount: {
    title: 'Kunskapstest',
    field: 'activeEducationCount',
    // type: 'numeric',
    defaultSort: 'desc',
    filtering: false,
    render: product => (
      <Typography
        color={product.activeEducationCount < 5 ? 'error' : 'inherit'}
      >
        {product.activeEducationCount || 0}
      </Typography>
    ),
  },
  responsibleUserNames: {
    title: 'Produktansvarig',
    field: 'responsibleUserNames',
    render: ({ responsibleUserNames }) =>
      responsibleUserNames ? responsibleUserNames.join(', ') : 'Saknas',
  },
  supplierName: {
    title: 'Leverantör',
    field: 'supplierName',
  },
}

function ProductTable(props) {
  const {
    columnIds = [],
    actionIds = [],
    products,
    title,
    history,
    tableProps,
  } = props
  const baseUrl = props.baseUrl || '/product'
  const actionDefs = {
    edit: {
      icon: Edit,
      tooltip: 'Redigera',
      onClick: (event, product) => {
        history.push(`${baseUrl}/${product.uid}/create/base`)
      },
    },
    restore: {
      icon: RestoreFromTrash,
      tooltip: 'Återställ',
      onClick: (event, product) => {
        database
          .getDatabase()
          .collection('products')
          .doc(product.uid)
          .update({ trashed: false })
      },
    },
  }
  const idsToItems = (ids, definitions) =>
    ids.map(id => definitions[id]).filter(item => item)
  const columns = idsToItems(columnIds, columnDefs)
  const actions = idsToItems(actionIds, actionDefs)
  return (
    <DataTable
      title={title}
      columns={columns}
      data={products}
      actions={actions}
      options={{
        actionsColumnIndex: columns.length,
        pageSizeOptions: [5, 10, 25, 100],
        pageSize: 10,
        paging: products.length > 10,
        // filtering: products.length > 10,
        emptyRowsWhenPaging: false,
        tableLayout: 'auto',
      }}
      {...tableProps}
    />
  )
}

export default withRouter(ProductTable)
