import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import database from '../../database'
import ProductInformation from '../ProductInformation'
import { timestampToDateString } from '../../utils'
import Discussion from './Discussion'
import ApprovalInfo from './ApprovalInfo'

class PublicationRequestProduct extends Component {
  state = {
    pharmacy: {},
  }

  componentDidMount() {
    const pharmacyRef = database
      .getDatabase()
      .collection('pharmacies')
      .doc(this.props.pharmacyUid)
    const requestRef = pharmacyRef
      .collection('publicationRequests')
      .doc(this.props.requestUid)
    this.setState({ requestRef })
    pharmacyRef.get().then(pharmacySnap => {
      const pharmacy = pharmacySnap.data()
      this.setState({ pharmacy })
    })
    database.getRequestWithFullProduct(requestRef).then(request => {
      const requestGroupRef = request.requestGroupUid
        ? database
            .getDatabase()
            .collection('publicationRequests')
            .doc(request.requestGroupUid)
        : null
      this.setState({
        request,
        requestGroupRef,
      })
      if (request.product.name) {
        this.setState({ product: request.product })
      } else if (requestGroupRef) {
        database
          .getRequestWithFullProduct(requestGroupRef)
          .then(requestGroup => {
            this.setState({ product: requestGroup.product })
          })
      }
    })
  }

  render() {
    const {
      request,
      requestRef,
      requestGroupRef,
      pharmacy,
      product,
    } = this.state
    const { classifications, categories } = this.props
    if (!(request && classifications && categories)) {
      return <LinearProgress />
    }
    const { internalApproval, pharmacyApproval } = request
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Kvalitetsgranskning</Typography>
            <Typography>
              Produktinformation senast ändrad{' '}
              {request.product.editDateTime
                ? timestampToDateString(request.product.editDateTime)
                : 'Okänt'}
            </Typography>
            <Typography gutterBottom>
              Skickad för kvalitetsgranskning{' '}
              {timestampToDateString(request.creationDateTime)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant="h6">Apostar</Typography>
            <ApprovalInfo approval={internalApproval} />
            {requestGroupRef && internalApproval.approved !== null && (
              <Discussion
                requestRef={requestGroupRef}
                pharmacy={{ name: 'Apostar' }}
                approved={internalApproval.approved}
                comment={internalApproval.comment}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant="h6">
              {pharmacy.name || 'Apotekskedja'}
            </Typography>
            <ApprovalInfo approval={pharmacyApproval} />
            {internalApproval.approved &&
              pharmacyApproval.approved !== null && (
                <Discussion
                  requestRef={requestRef}
                  pharmacy={pharmacy}
                  approved={pharmacyApproval.approved}
                  comment={pharmacyApproval.comment}
                />
              )}
          </Grid>
        </Grid>
        {product && <ProductInformation product={product} />}
      </div>
    )
  }
}

export default connect(state => ({
  classifications: state.options.classifications,
  categories: state.options.categories,
}))(PublicationRequestProduct)
