import React, { Component } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import MailIcon from '@material-ui/icons/Mail'
import LocalPharmacy from '@material-ui/icons/LocalPharmacy'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Image from './Image'

class ConversationList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasInitData: false,
    }
    this.select = this.select.bind(this)
  }

  componentWillMount() {
    if (this.props.conversations) {
      this.loadGroupedConversationLists(this.props.conversations)
    }
  }

  componentWillUpdate(prevProps) {
    if (prevProps.conversations !== this.props.conversations) {
      this.loadGroupedConversationLists(this.props.conversations)
    }
  }

  loadGroupedConversationLists = conversations => {
    const unresolvedConverstations = conversations.filter(
      conversation => !conversation.resolvedDateTime
    )
    const resolvedConverstations = conversations.filter(
      conversation => conversation.resolvedDateTime
    )
    if (!this.state.hasInitData && conversations.length) {
      const firstConversation = unresolvedConverstations.length
        ? unresolvedConverstations[0]
        : resolvedConverstations[0]
      this.select(firstConversation.id)
    }
    this.setState({
      unresolvedConverstations,
      resolvedConverstations,
      hasInitData: true,
    })
  }

  select(conversationUid) {
    this.setState({ selectedConversation: conversationUid })
    this.props.onSelect(conversationUid)
  }

  renderConversation = conversation => {
    const isSelected = this.state.selectedConversation === conversation.id
    // Do it manually because we need raw img element in ListItemIcon
    const image = conversation.product ? conversation.product.image : null
    return (
      <ListItem
        style={isSelected ? { backgroundColor: '#dfdfdf' } : {}}
        onClick={() => this.select(conversation.id)}
        key={conversation.id}
      >
        {image ? (
          <ListItemAvatar>
            <Avatar>
              <Image maxHeight={40} image={image} />
            </Avatar>
          </ListItemAvatar>
        ) : (
          <ListItemIcon>
            <LocalPharmacy />
          </ListItemIcon>
        )}
        <ListItemText
          primary={conversation.username || ''}
          secondary={conversation.productName}
        />
        <ListItemIcon>
          {conversation.editDateTime > conversation.supplierLastReadDateTime &&
          !isSelected ? (
            <MailIcon />
          ) : (
            <div />
          )}
        </ListItemIcon>
      </ListItem>
    )
  }

  render() {
    return (
      <div>
        {this.state.unresolvedConverstations && (
          <Paper style={{ marginBottom: 16 }}>
            <Typography variant="body1" style={{ padding: 8 }} gutterBottom>
              Pågående konversationer
            </Typography>
            <List>
              {this.state.unresolvedConverstations.map(this.renderConversation)}
            </List>
          </Paper>
        )}
        {this.state.resolvedConverstations && (
          <Paper>
            <Typography variant="body1" style={{ padding: 8 }} gutterBottom>
              Klarmarkerade konversationer
            </Typography>
            <List>
              {this.state.resolvedConverstations.map(this.renderConversation)}
            </List>
          </Paper>
        )}
      </div>
    )
  }
}

export default ConversationList
