import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListSubheader from '@material-ui/core/ListSubheader'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Add from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import { connect } from 'react-redux'

import Image from '../Image'
import AddProductButton from '../AddProductButton'

const sortByEditDateDesc = (a, b) =>
  b.editDateTime && a.editDateTime
    ? b.editDateTime.toMillis() - a.editDateTime.toMillis()
    : 0
class ProductList extends Component {
  state = {
    limit: 10,
  }

  render() {
    const {
      productGroups: { unfinishedProducts, finishedProducts },
      products,
      initialized,
      history,
    } = this.props

    if (unfinishedProducts) {
      unfinishedProducts.sort(sortByEditDateDesc)
    }
    if (finishedProducts) {
      finishedProducts.sort(sortByEditDateDesc)
    }

    const finishedProductsLimit = unfinishedProducts
      ? this.state.limit - unfinishedProducts.length
      : 0
    return (
      <Card>
        <CardHeader
          avatar={<LocalPharmacyIcon />}
          title="Produkter"
          action={
            <AddProductButton
              startIcon={<Add />}
              variant="text"
              color="primary"
            />
          }
        />
        <CardContent>
          {!initialized ? (
            <LinearProgress />
          ) : (
            <Fragment>
              {initialized && products && products.length === 0 && (
                <Typography>Det finns inga produkter</Typography>
              )}
              {unfinishedProducts && Boolean(unfinishedProducts.length) && (
                <List>
                  <ListSubheader>Utkast</ListSubheader>
                  {unfinishedProducts.map(product => (
                    <ListItem
                      key={product.uid}
                      button
                      onClick={() =>
                        history.push(`/product/${product.uid}/create/base`)
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          {product.image ? (
                            <Image image={product.image} maxHeight={150} />
                          ) : (
                            <LocalPharmacyIcon />
                          )}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={product.name} />
                      <ListItemSecondaryAction>
                        <IconButton>
                          <Link to={`product/${product.uid}/create/base`}>
                            <EditIcon />
                          </Link>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                  <Divider />
                </List>
              )}
              {finishedProducts &&
                Boolean(finishedProducts.length) &&
                Boolean(finishedProductsLimit) && (
                  <List>
                    <ListSubheader>Fullständiga</ListSubheader>
                    {finishedProducts
                      .slice(0, this.state.limit - unfinishedProducts.length)
                      .map(product => (
                        <ListItem
                          key={product.uid}
                          button
                          onClick={() =>
                            history.push(`/product/${product.uid}`)
                          }
                        >
                          <ListItemAvatar>
                            <Avatar>
                              {product.image ? (
                                <Image image={product.image} maxHeight={150} />
                              ) : (
                                <LocalPharmacyIcon />
                              )}
                            </Avatar>
                          </ListItemAvatar>
                          <Link to={`product/${product.uid}`}>
                            <ListItemText primary={product.name} />
                          </Link>
                        </ListItem>
                      ))}
                  </List>
                )}
            </Fragment>
          )}
        </CardContent>
        <CardActions>
          {initialized &&
            Boolean(finishedProducts.length) &&
            (unfinishedProducts.length + finishedProductsLimit) % 5 === 0 && (
              <Button
                onClick={() =>
                  this.setState(({ limit }) => ({ limit: limit + 5 }))
                }
              >
                Visa fler <ExpandMore />
              </Button>
            )}
          <Link to="/products" style={{ marginLeft: 'auto' }}>
            <Button>
              Produkttabell <ChevronRight />
            </Button>
          </Link>
        </CardActions>
      </Card>
    )
  }
}

export default connect(state => ({
  initialized: state.products.initialized,
  productGroups: state.products.grouped,
  products: state.products.list,
}))(withRouter(ProductList))
