import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import AnsweredRequests from '../quality-control/AnsweredRequests'
import UnansweredRequests from '../quality-control/UnansweredRequests'
import ProductList from './ProductList'
import DashboardStatistics from './DashboardStatistics'
import ActionableEvents from './ActionableEvents'
import InformationalEvents from './InformationalEvents'
import AddProductButton from '../AddProductButton'

function PharmacyDashboard() {
  return (
    <div>
      <Typography color="primary" variant="h4" gutterBottom>
        Välkommen till Apostar apoteksportal
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <UnansweredRequests />
        </Grid>
        <Grid item xs={12} md={6}>
          <AnsweredRequests />
        </Grid>
      </Grid>
    </div>
  )
}

const SupplierDashboard = connect(state => ({
  user: state.user,
  ...state.products,
}))(({ user, list: products, initialized }) => {
  return (
    <React.Fragment>
      <Typography color="primary" variant="h4" gutterBottom>
        Välkommen till Apostar leverantörsportal
      </Typography>
      {initialized && !products.length && (
        <Typography variant="subtitle1" gutterBottom>
          <AddProductButton variant="text" color="primary" /> redan nu för att
          nå ut till apotekspersonalen
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DashboardStatistics />
            </Grid>
            <Grid item xs={12}>
              <ProductList supplier={user.supplier} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ActionableEvents />
            </Grid>
            <Grid item xs={12}>
              <InformationalEvents />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
})

function SuperadminDashboard() {
  return (
    <div>
      <Typography color="primary" variant="h4" gutterBottom>
        Välkommen till Apostar superadmin
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <UnansweredRequests />
        </Grid>
        <Grid item xs={12} md={6}>
          <AnsweredRequests />
        </Grid>
      </Grid>
    </div>
  )
}
function Home({ user }) {
  if (user && user.isPharmacyAdmin()) {
    return <PharmacyDashboard />
  }
  if (user && user.isSupplierAdmin()) {
    return <SupplierDashboard />
  }
  if (user && user.isSuperAdmin()) {
    return <SuperadminDashboard />
  }
  return null
}

export default connect(state => ({
  user: state.user,
}))(Home)
