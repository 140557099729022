import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import Image from './Image'

function SubproductInformation({ subproduct }) {
  return (
    <div>
      {subproduct ? (
        <div>
          <Link to={`/product/${subproduct.uid}`}>
            <Typography color="primary" variant="h4">
              {subproduct.name}
            </Typography>
            <Image image={subproduct.image} />
          </Link>
          {
            // <ProductSections sections={this.state.sections} />
          }
        </div>
      ) : (
        <LinearProgress />
      )}
    </div>
  )
}
export default SubproductInformation
