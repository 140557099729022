import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  Link,
  Checkbox,
  Typography,
  LinearProgress,
  Divider,
} from '@material-ui/core'
import _ from 'lodash'
import { connect } from 'react-redux'
import { functionsEurope, analytics } from '../../firebase'

function ReviewDialog(props) {
  const {
    open,
    onClose,
    onSendSuccess,
    pharmacies,
    productUid,
    product: { lastSentToPharmacyUids, name, quiz, sections },
    cancelText,
  } = props
  const [sending, setSending] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [optInPharmacies, optOutPharmacies] = _.partition(pharmacies, 'optIn')
  const [shouldSendRequest, setShouldSend] = React.useState(
    lastSentToPharmacyUids
      ? lastSentToPharmacyUids.reduce(
          (sendTo, pharmacyUid) => ({
            ...sendTo,
            [pharmacyUid]: true,
          }),
          {}
        )
      : pharmacies.reduce(
          (sendTo, pharmacy) => ({
            ...sendTo,
            [pharmacy.uid]: !pharmacy.optIn,
          }),
          {}
        )
  )

  const sendIt = () => {
    const makePublicationRequest = functionsEurope.httpsCallable(
      'qualityControl-makePublicationRequest'
    )
    const pharmacyUidsForSending = Object.keys(shouldSendRequest).filter(
      pharmacyUid => shouldSendRequest[pharmacyUid]
    )
    setSending(true)
    return makePublicationRequest({
      productUid,
      pharmacyUids: pharmacyUidsForSending,
    })
      .then(() => {
        // ReactGA.event({
        //   category: 'Product',
        //   action: 'Sent for QC',
        //   label: product.id,
        // })
        analytics.logEvent('send_product_for_review', { productUid })

        setSending(false)
        if (onSendSuccess) onSendSuccess()
      })
      .catch(error => {
        setError(error.message)
      })
  }
  const hasImage = list =>
    list && list.some(item => item.image && !_.isEmpty(item.image))
  const switchPharmacy = pharmacyUid => () =>
    setShouldSend(prevValue => ({
      ...prevValue,
      [pharmacyUid]: !prevValue[pharmacyUid],
    }))
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
    >
      <DialogTitle id="alert-dialog-title">
        Skicka produktutbildning till kvalitetsgranskning
      </DialogTitle>

      <DialogContent>
        {!lastSentToPharmacyUids && (
          <DialogContentText>
            Utbildningen {name} är fullständig! För att publicera utbildningen i
            appen behöver den granskas av Apostar och i vissa fall av
            apotekskedjor.
          </DialogContentText>
        )}
        {!(hasImage(quiz) && hasImage(sections)) && (
          <DialogContentText color="error">
            Obs! Utbildningsinnehållet eller kunskapstestet saknar bilder! Det
            blir mycket lättare för användarna att ta till sig informationen med
            bilder. Vi rekommenderar starkt att lägga till bilder i både
            kunskapstest och utbildningsinnehållet innan publicering.
          </DialogContentText>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle">Välj apotek</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle">
              Skickas även till användargrupper:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset">
              <FormGroup>
                {optInPharmacies.map(pharmacy => (
                  <FormControlLabel
                    key={pharmacy.uid}
                    disabled={sending}
                    control={
                      <Checkbox
                        checked={shouldSendRequest[pharmacy.uid]}
                        onChange={switchPharmacy(pharmacy.uid)}
                        value={pharmacy.uid}
                      />
                    }
                    label={pharmacy.name}
                  />
                ))}
              </FormGroup>
              <FormHelperText>
                Välj bara apotek som har produkten i sitt sortiment
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <List dense>
              {optOutPharmacies.map(pharmacy => {
                let secondary = null
                if (pharmacy.userEducationCost === 0) {
                  secondary = 'Gratis kunskapstest och klick'
                }
                if (pharmacy.userEducationCost === 0.5) {
                  secondary = `2 ${pharmacy.name.toLowerCase()} debiteras som 1 apotekspersonal`
                }
                return (
                  <ListItem key={pharmacy.uid}>
                    <ListItemText
                      primary={pharmacy.name}
                      secondary={secondary}
                    />
                  </ListItem>
                )
              })}
            </List>
          </Grid>
        </Grid>
        {sending && <LinearProgress />}
        {error && (
          <Typography color="error">
            Något gick fel. Försök igen och kontakta{' '}
            <Link target="_blank" href="mailto:support@apostar.se">
              support@apostar.se
            </Link>{' '}
            om felet kvarstår {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={sending} onClick={onClose}>
          {cancelText || 'Avbryt'}
        </Button>
        <Button
          disabled={sending}
          onClick={sendIt}
          color="primary"
          variant="outlined"
          autoFocus
        >
          Skicka till granskning och publicera
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default connect(state => ({
  pharmacies: state.pharmacies,
  product: state.product,
}))(ReviewDialog)
