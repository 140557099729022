import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { connect } from 'react-redux'
import { Typography, TextField, IconButton, Button } from '@material-ui/core'
import TrashIcon from '@material-ui/icons/Delete'
import database from '../database'
import { FieldValue } from '../firebase'

const getSupplierRef = supplierUid =>
  database
    .getDatabase()
    .collection('suppliers')
    .doc(supplierUid)

const addEmail = (supplier, email) =>
  getSupplierRef(supplier.uid).update({
    summaryRecipientEmails: FieldValue.arrayUnion(email),
  })

const removeEmail = (supplier, email) =>
  getSupplierRef(supplier.uid).update({
    summaryRecipientEmails: FieldValue.arrayRemove(email),
  })

function SummaryRecipients(props) {
  const { supplier } = props
  const [newRecipientEmail, setNewRecipientEmail] = React.useState('')
  return (
    <Card>
      <CardHeader title="Summering" />
      <CardContent>
        <Typography>
          Alla konton får summeringar enligt personliga inställningar. <br />
          Lägg till e-post här för mottagare <strong>utan konto</strong>. <br />
          Externa mottagare får månadsvis summering om alla produkter.
        </Typography>
        {supplier.summaryRecipientEmails &&
          supplier.summaryRecipientEmails.map(email => (
            <Typography>
              {email}
              <IconButton onClick={() => removeEmail(supplier, email)}>
                <TrashIcon />
              </IconButton>
            </Typography>
          ))}
        <div>
          <form
            onSubmit={event => {
              event.preventDefault()
              return addEmail(supplier, newRecipientEmail).then(() =>
                setNewRecipientEmail('')
              )
            }}
          >
            <TextField
              placeholder="E-post"
              value={newRecipientEmail}
              onChange={event => setNewRecipientEmail(event.target.value)}
              type="email"
            />
            <Button type="submit" disabled={!newRecipientEmail}>
              Lägg till
            </Button>
          </form>
        </div>
      </CardContent>
    </Card>
  )
}

export default connect(state => ({
  supplier: state.supplier,
}))(SummaryRecipients)
