import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as Sentry from '@sentry/browser'
import App from './components/App'
import { unregister } from './registerServiceWorker'

Sentry.init({
  dsn: 'https://2237b8c1ef15445990aed5a5c768fca7@sentry.io/2121904',
})
unregister()
ReactDOM.render(<App />, document.getElementById('root'))
