import React from 'react'
import { Popover, Typography, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help'
import { analytics } from '../firebase'

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(2),
    maxWidth: 500,
  },
}))

function HelpPopover(props) {
  const { uid, text, children, open: forceOpen } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    analytics.logEvent('help_popover')
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const popoverActions = React.useRef(null)

  const open = Boolean(anchorEl) || forceOpen
  const id = open ? `simple-popover-${uid}` : undefined

  React.useLayoutEffect(() => {
    const timer = !open
      ? null
      : setInterval(() => {
          if (popoverActions.current) {
            popoverActions.current.updatePosition()
          }
        }, 500)
    return () => {
      if (timer) clearInterval(timer)
    }
  }, [open, popoverActions.current])

  return (
    <>
      <Popover
        action={popoverActions}
        id={id}
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.wrapper}>
          {text && <Typography>{text}</Typography>}
          {children && (
            <>
              <Typography gutterBottom style={{ fontStyle: 'italic' }}>
                Exempel:
              </Typography>
              {children}
            </>
          )}
        </div>
      </Popover>

      <IconButton
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <HelpIcon />
      </IconButton>
    </>
  )
}
export default HelpPopover
