import React from 'react'
import { FieldArray, reduxForm } from 'redux-form'
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  ListItemIcon,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Help, InsertPhoto, SupervisorAccount } from '@material-ui/icons'
import database from '../../database'
import { PRODUCT_TYPES } from '../../constants'
import {
  emptyQuestion,
  batchUpdateOrderedCollection,
  calcProgress,
  getGuidePart,
} from './common'
import ProductQuestionInput from './ProductQuestionInput'
import { serverTimestamp } from '../../firebase'
import ErrorDialog from './ErrorDialog'
import Quiz from '../Quiz'
import { closeGuide } from '../../actions'

const MAX_QUESTIONS = 7

const getQuizRef = productUid =>
  database.queryProduct(productUid).collection('quiz')

const renderQuestions = withRouter(
  ({
    fields,
    type,
    meta: { dirty },
    match: {
      params: { productUid },
    },
  }) => {
    const quizRef = getQuizRef(productUid)
    const [loading, setLoading] = React.useState(false)
    const removeQuestion = (index, uid) => {
      fields.remove(index) // manually remove in case it's kept due being dirty
      return quizRef.doc(uid).delete()
    }

    const addQuestion = () => {
      setLoading(true)
      const newQuestion = {
        index: fields.length,
        creationDateTime: serverTimestamp(),
        ...emptyQuestion,
      }
      return quizRef.add(newQuestion).then(questionRef => {
        if (dirty) {
          fields.push({ uid: questionRef.id, ...newQuestion })
        }
        setLoading(false)
      })
    }
    return (
      <>
        {fields.map((question, index) => (
          <ProductQuestionInput
            key={question}
            fieldname={question}
            index={index}
            onRemove={removeQuestion}
          />
        ))}
        {(fields.length < MAX_QUESTIONS || type === PRODUCT_TYPES.GENERIC) && (
          <Button disabled={loading} onClick={addQuestion} variant="contained">
            Lägg till fråga
          </Button>
        )}
      </>
    )
  }
)

const QuizGuideDialog = connect(
  state => ({
    quizGuide: getGuidePart(state, 'quiz'),
    exampleProduct: state.guide.exampleProduct,
  }),
  { closeGuide }
)(props => {
  const { closeGuide, quizGuide, exampleProduct } = props
  return (
    <Dialog
      onClose={() => closeGuide('quiz')}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={quizGuide}
    >
      <DialogTitle id="alert-dialog-title">Guide kunskapstest</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Ha följande i åtanke när du utformar kunskapstestet:
        </DialogContentText>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText>
              Svaret bör finnas i utbildningsinnehållet
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InsertPhoto />
            </ListItemIcon>
            <ListItemText>Använd gärna mycket bilder</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SupervisorAccount />
            </ListItemIcon>
            <ListItemText>
              Formulera gärna frågor som en arbetssituation för apotekspersonal
            </ListItemText>
          </ListItem>
        </List>
        <DialogContentText>
          Nedan ser du ett exempel på kunskapstest till {exampleProduct.name}
        </DialogContentText>
        <Quiz quiz={exampleProduct.quiz} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeGuide('quiz')}>Stäng</Button>
      </DialogActions>
    </Dialog>
  )
})

function QuizInput(props) {
  const {
    handleSubmit,
    submitting,
    submitFailed,
    pristine,
    match: {
      params: { productUid },
    },
    history,
    valid,
  } = props
  const save = ({ quiz }, errors) => {
    const progress = valid ? 100 : calcProgress('quiz', errors, { quiz })
    const batch = database.batch()
    batch.update(database.queryProduct(productUid), {
      'completion.quiz': Math.ceil(progress),
      editDateTime: serverTimestamp(),
    })
    const quizRef = getQuizRef(productUid)
    return batchUpdateOrderedCollection(quizRef, quiz, batch)
      .commit()
      .then(() => history.push(`/product/${productUid}/create/base`))
  }
  return (
    <>
      <form onSubmit={handleSubmit(save)}>
        <FieldArray name="quiz" component={renderQuestions} />
        <Button
          disabled={pristine || submitting}
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginLeft: 16 }}
        >
          Spara och fortsätt
        </Button>
        {submitFailed && (
          <ErrorDialog
            submitting={submitting}
            submitFailed={submitFailed}
            description="kunskapstestet"
            onSave={save}
          />
        )}
      </form>
      <QuizGuideDialog />
    </>
  )
}

const enhance = compose(
  withRouter,
  reduxForm({
    form: 'quiz',
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
  })
)
export default enhance(QuizInput)
