import React, { Component } from 'react'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import { FieldArray } from 'redux-form'
import { Typography } from '@material-ui/core'

class QueryTagsInput extends Component {
  state = { tagToBeAdded: '' }

  handleInputChange = fields => event => {
    const tag = event.target.value
    const lastChar = tag.charAt(tag.length - 1)
    const endsWithWhitespace = lastChar === ' ' || lastChar === '\n'
    if (endsWithWhitespace) {
      const trimmed = tag.trim()
      if (trimmed.length > 1) {
        fields.push(trimmed)
      }
      this.setState({ tagToBeAdded: '' })
    } else {
      this.setState({ tagToBeAdded: tag })
    }
  }

  renderTagInput = ({ fields, tagToBeAdded }) => {
    const tags = fields.getAll() || []
    return (
      <React.Fragment>
        <div>
          <TextField
            multiline
            helperText="Tex halsont, febernedsättande, hudbesvär"
            value={tagToBeAdded}
            onChange={this.handleInputChange(fields)}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          {tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => fields.remove(index)}
              style={{ margin: 8 }}
            />
          ))}
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <>
        <Typography>
          Tagga produkten med sökord. Separera med mellanslag eller ny rad
        </Typography>
        <FieldArray
          name="tags"
          props={{ tagToBeAdded: this.state.tagToBeAdded }}
          component={this.renderTagInput}
        />
      </>
    )
  }
}

export default QueryTagsInput
