import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import SimplePieChart from './SimplePieChart'
import SimpleSelect from '../SimpleSelect'

function PharmacyDistribution({ data }) {
  const [mode, setMode] = useState('passed')
  const dataKeyForMode = {
    passed: 'passedCount',
    clicks: 'clickCount',
  }
  const options = [
    {
      value: 'passed',
      name: 'Godkända test',
    },
    {
      value: 'clicks',
      name: 'Klick',
    },
  ]
  return (
    <Card>
      <CardHeader
        title="Apotekskedjor"
        action={
          <SimpleSelect selected={mode} onSelect={setMode} options={options} />
        }
      />
      <CardContent style={{ minHeight: 370 }}>
        <SimplePieChart
          data={data.pieChartData}
          dataKey={dataKeyForMode[mode]}
          total={data.educationTotals.totals[mode]}
        />
      </CardContent>
    </Card>
  )
}
export default PharmacyDistribution
