import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import SortableTable from '../SortableTable'
import { updateExludedBillingProductUids } from './actions'

const rowStyles = {
  hover: {
    cursor: 'pointer',
  },
}

const rows = [
  { id: 'checked', numeric: false, disablePadding: false, label: 'Fakturera' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Produkt' },
  { id: 'clickCount', numeric: true, disablePadding: false, label: 'Klick' },
  // {
  //   id: 'avgReadTime',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Lästid / Klick',
  // },
  {
    id: 'passedCount',
    numeric: true,
    disablePadding: false,
    label: 'Godkända',
  },
  {
    id: 'failedCount',
    numeric: true,
    disablePadding: false,
    label: 'Underkända',
  },
  {
    id: 'passFailRatio',
    numeric: true,
    disablePadding: false,
    label: 'Andel godkända av påbörjade',
  },
]

// eslint-disable-next-line no-unused-vars
const HoverStyledRow = withStyles(rowStyles)(TableRow)

// eslint-disable-next-line no-unused-vars
function ProductStatisticsTable({
  data,
  history,
  excludedBillingProducts,
  updateExludedBillingProductUids,
  user,
}) {
  // const numSelected = Object.values(excluded).filter(val => !val).length
  // const rowCount = data.length
  const onSelectClick = productUid => {
    updateExludedBillingProductUids({
      ...excludedBillingProducts,
      [productUid]: !excludedBillingProducts[productUid],
    })
  }
  const rowDefs = user.isSuperAdmin() ? rows : rows.slice(1)
  return (
    <SortableTable
      order="desc"
      orderBy="passedCount"
      rows={rowDefs}
      data={data}
    >
      {product => (
        <HoverStyledRow
          hover
          key={product.uid}
          onClick={event =>
            event.target.type !== 'checkbox' &&
            history.push(`/statistics/product/${product.uid}`)
          }
        >
          {user.isSuperAdmin() && (
            <TableCell padding="checkbox">
              <Checkbox
                checked={!excludedBillingProducts[product.uid]}
                onChange={() => onSelectClick(product.uid)}
              />
            </TableCell>
          )}
          <TableCell>{product.name}</TableCell>
          <TableCell align="right">{product.clickCount}</TableCell>
          {/* <TableCell align="right">
            {durationMinutesAndSeconds(product.avgReadTime)}
          </TableCell> */}
          <TableCell align="right">{product.passedCount}</TableCell>
          <TableCell align="right">{product.failedCount}</TableCell>
          <TableCell align="right">
            {product.passFailRatio.toFixed(0)}%
          </TableCell>
        </HoverStyledRow>
      )}
    </SortableTable>
  )
}
export default connect(
  state => ({
    excludedBillingProducts: state.statistics.excludedBillingProducts,
    user: state.user,
  }),
  { updateExludedBillingProductUids }
)(withRouter(ProductStatisticsTable))
