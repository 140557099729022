import React, { Component } from 'react'
import PictureAsPdf from '@material-ui/icons/PictureAsPdf'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudDownload from '@material-ui/icons/CloudDownload'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import { timestampToDateString } from '../utils'
import database from '../database'
import { serverTimestamp, analytics } from '../firebase'
import { withUser } from '../actions'

const styles = theme => ({
  buttonIcon: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
  },
  link: {
    marginTop: theme.spacing(1),
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const UnstyledExportLink = React.forwardRef(({ pdfExport, classes }, ref) =>
  pdfExport && pdfExport.processedDateTime ? (
    <Link
      ref={ref}
      href={pdfExport.downloadUrl}
      download
      className={classes.link}
    >
      {timestampToDateString(pdfExport.processedDateTime)}
      <CloudDownload className={classes.buttonIcon} />
    </Link>
  ) : null
)

const ExportLink = withStyles(styles)(UnstyledExportLink)

class ProductExports extends Component {
  state = {}

  listeners = []

  downloadRef = React.createRef()

  componentDidMount() {
    const { productUid, user } = this.props
    const exportsListener = database
      .queryProductForUser(productUid, user)
      .collection('exports')
      .onSnapshot(exportsSnap => {
        const pdfExports = database.getCollectionDocsDataWithUid(exportsSnap)
        this.setState({ pdfExports })
      })
    this.listeners.push(exportsListener)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pdfExports !== this.state.pdfExports) {
      if (this.downloadRef.current) {
        this.downloadRef.current.click()
      }
    }
  }

  componentWillUnmount() {
    this.listeners.forEach(listener => listener())
  }

  exportToPDF = () => {
    const { productUid } = this.props
    database
      .queryProduct(productUid)
      .collection('exports')
      .add({
        creationDateTime: serverTimestamp(),
      })
      .then(ref => {
        analytics.logEvent('product_export', { productUid })
        const newExportListener = ref.onSnapshot(exportSnap => {
          this.setState({ newExport: exportSnap.data() })
        })
        this.listeners.push(newExportListener)
      })
  }

  render() {
    const { newExport } = this.state
    const { classes, style } = this.props
    const isLoading =
      newExport && newExport.creationDateTime && !newExport.processedDateTime
    return (
      <div style={{ display: 'inline-block', ...style }}>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            onClick={this.exportToPDF}
            disabled={isLoading}
            endIcon={<PictureAsPdf />}
          >
            Exportera
          </Button>
          {isLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          {
            // pdfExports.map(ExportLink)}
          }
        </div>
        <ExportLink pdfExport={newExport} ref={this.downloadRef} />
      </div>
    )
  }
}
export default withUser(withStyles(styles)(ProductExports))
