import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from '@material-ui/core'
import FAQ from './FAQ'
import { version, commit } from '../config'

function Help() {
  return (
    <div>
      <Typography color="primary" variant="h4" gutterBottom>
        Hjälp
      </Typography>
      <Typography>
        Om du inte hittar svar på din fråga nedan kan du skicka ett
        supportärende till{' '}
        <Link target="_blank" href="mailto:support@apostar.se">
          support@apostar.se
        </Link>
      </Typography>
      <Typography>
        För övrig kontakt mejla oss på{' '}
        <Link target="_blank" href="mailto:info@apostar.se">
          info@apostar.se
        </Link>
      </Typography>
      <Typography>Version: {version}</Typography>
      <Typography>Commit: {commit}</Typography>
      <FAQ />
    </div>
  )
}
export default Help
