import databaseManager from './database'

const database = databaseManager.getDatabase()

const EventCategory = {
  INFORMATIONAL: 'informational',
  ACTIONABLE: 'actionable',
}

const getUserEventsRef = userUid =>
  database
    .collection('users')
    .doc(userUid)
    .collection('events')

const streamProductEvents = productUid => (dispatch, getState) => {
  const { uid: userUid } = getState().user
  return getUserEventsRef(userUid)
    .orderBy('severity')
    .orderBy('creationDateTime', 'desc')
    .where('productUid', '==', productUid)
    .where('severity', '<=', 1)
    .where('completed', '==', false)
    .onSnapshot(eventsSnap => {
      const events = databaseManager.getCollectionDocsDataWithUid(eventsSnap)
      dispatch({
        type: 'LOAD_PRODUCT_EVENTS',
        data: events,
        productUid,
      })
    })
}

const loadEvents = (category, count = 7) => (dispatch, getState) => {
  const { uid: userUid } = getState().user
  const baseQuery = getUserEventsRef(userUid)
    .orderBy('severity')
    .orderBy('creationDateTime', 'desc')
  let query = baseQuery
  switch (category) {
    case EventCategory.ACTIONABLE:
      query = baseQuery
        .where('severity', '<=', 1)
        .where('completed', '==', false)
      break
    case EventCategory.INFORMATIONAL:
      query = baseQuery
        .where('severity', '>=', 2)
        .where('dismissed', '==', false)
      break
    default:
      return
  }
  dispatch({
    type: 'EVENTS_LOAD_STARTED',
    category,
  })
  const { lastEventSnap } = getState().events[category]
  let paginatedQuery = query
  if (lastEventSnap) {
    paginatedQuery = paginatedQuery.startAfter(lastEventSnap)
  }
  paginatedQuery
    .limit(count)
    .get()
    .then(eventsSnap => {
      const newLastEventSnap = eventsSnap.docs[eventsSnap.docs.length - 1]
      const events = databaseManager.getCollectionDocsDataWithUid(eventsSnap)
      dispatch({
        type: 'EVENTS_LOAD_FINISHED',
        events,
        lastEventSnap: newLastEventSnap,
        category,
      })
    })
}

const markEventComplete = (eventUid, eventIndex) => (dispatch, getState) => {
  const { uid: userUid } = getState().user
  getUserEventsRef(userUid)
    .doc(eventUid)
    .update({ completed: true })
    .then(() => {
      dispatch({
        type: 'EVENT_REMOVE',
        category: EventCategory.ACTIONABLE,
        index: eventIndex,
      })
    })
}

const dismissEvent = (eventUid, eventIndex) => (dispatch, getState) => {
  const { uid: userUid } = getState().user
  getUserEventsRef(userUid)
    .doc(eventUid)
    .update({ dismissed: true })
    .then(() => {
      dispatch({
        type: 'EVENT_REMOVE',
        category: EventCategory.INFORMATIONAL,
        index: eventIndex,
      })
    })
}
const eventsCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case 'EVENTS_LOAD_STARTED':
      return {
        ...state,
        loading: true,
      }
    case 'EVENTS_LOAD_FINISHED':
      return {
        ...state,
        list: state.list.concat(action.events),
        lastEventSnap: action.lastEventSnap,
        allEventsLoaded: !action.events.length,
        loading: false,
        initialized: true,
      }
    case 'EVENT_REMOVE': {
      const newEvents = state.list.slice()
      newEvents.splice(action.index, 1)
      return {
        ...state,
        list: newEvents,
      }
    }
    default:
      return state
  }
}
const eventsReducer = (state = {}, action) => {
  if (action.type === 'LOAD_PRODUCT_EVENTS') {
    return {
      ...state,
      products: {
        ...state.products,
        [action.productUid]: action.data,
      },
    }
  }
  switch (action.category) {
    case EventCategory.INFORMATIONAL:
      return {
        ...state,
        informational: eventsCategoryReducer(state.informational, action),
      }
    case EventCategory.ACTIONABLE:
      return {
        ...state,
        actionable: eventsCategoryReducer(state.actionable, action),
      }
    default:
      return state
  }
}
export {
  loadEvents,
  streamProductEvents,
  eventsReducer,
  dismissEvent,
  markEventComplete,
  EventCategory,
}
