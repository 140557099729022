import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import Cross from '@material-ui/icons/Close'
import Notifications from '@material-ui/icons/Notifications'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadEvents, dismissEvent } from '../../events'
import { timestampToDateString } from '../../utils'

function InformationalEvents(props) {
  const {
    events,
    loading,
    loadEvents,
    dismissEvent,
    initialized,
    allEventsLoaded,
  } = props
  return (
    <Card>
      <CardHeader avatar={<Notifications />} title="Senaste händelser" />
      <CardContent>
        {loading && <LinearProgress />}
        {initialized && (
          <List>
            {events.map((event, index) => (
              <ListItem key={event.uid}>
                <ListItemIcon>
                  <CheckCircleOutline color="primary" />
                </ListItemIcon>
                <Link to={event.url || '/'}>
                  <ListItemText
                    primary={event.title}
                    secondary={timestampToDateString(event.creationDateTime)}
                  />
                </Link>
                <ListItemSecondaryAction>
                  <IconButton onClick={() => dismissEvent(event.uid, index)}>
                    <Cross />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            {events.length === 0 && (
              <Typography variant="body1">
                Det finns inga händelser än
              </Typography>
            )}
          </List>
        )}
      </CardContent>
      <CardActions>
        {events.length % 7 === 0 && events.length > 0 && !allEventsLoaded && (
          <Button
            onClick={() => loadEvents('informational')}
            disabled={loading}
          >
            Visa fler <ExpandMore />
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

export default connect(
  state => ({
    events: state.events.informational.list,
    loading: state.events.informational.loading,
    initialized: state.events.informational.initialized,
    allEventsLoaded: state.events.informational.allEventsLoaded,
  }),
  { loadEvents, dismissEvent }
)(InformationalEvents)
