import React, { Component, useMemo } from 'react'
import {
  withRouter,
  Switch,
  Redirect,
  Prompt,
  Link as RouterLink,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  getFormSyncErrors,
  getFormValues,
  isDirty,
  initialize,
  destroy,
} from 'redux-form'
import ReactGA from 'react-ga'
import {
  Typography,
  Grid,
  Button,
  Paper,
  Link,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  ListItemSecondaryAction,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Box,
  ListItemIcon,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckCircle'
import SaveIcon from '@material-ui/icons/Save'
import SendIcon from '@material-ui/icons/Send'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { Help, Subject, Search, Assessment } from '@material-ui/icons'
import { loadExampleProduct, setGuideOpen, closeGuide } from '../../actions'

import PrivateRoute from '../../PrivateRoute'
import ProductSectionsInput from './ProductSectionsInput'
import QuizInput from './QuizInput'
import ProductBaseInput from './ProductBaseInput'
import ProductMediaInput from './ProductMediaInput'
import {
  batchUpdateOrderedCollection,
  calcProgress,
  makeInitData,
  getGuidePart,
} from './common'
import { serverTimestamp, analytics } from '../../firebase'
import database from '../../database'
import ReviewDialog from './ReviewDialog'
import MobileDevice from '../MobileDevice'
import MobileEducation from '../MobileEducation'
import ProductExports from '../ProductExports'

const listItems = [
  {
    title: 'Grundläggande info',
    path: 'base',
  },
  {
    title: 'Lägg till media',
    path: 'media',
  },
  {
    title: 'Utbildningsinnehåll',
    path: 'content',
  },
  {
    title: 'Kunskapstest',
    path: 'quiz',
  },
]

const styles = theme => ({
  saveAll: {
    margin: theme.spacing(2),
  },
  wrapper: {
    position: 'relative',
    display: 'inline',
  },
  buttonSuccess: {
    // backgroundColor: green[500],
    // '&:hover': {
    //   backgroundColor: green[700],
    // },
  },
  buttonProgress: {
    // color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  circleProgress: {
    // color: green[500],
    position: 'absolute',
    top: '0%',
  },
})

const loadProductPart = (productUid, data) => ({
  type: 'LOAD_PARTIAL_PRODUCT',
  data,
  productUid,
})
const startLoadFullProduct = productUid => ({
  type: 'LOAD_FULL_PRODUCT_START',
  productUid,
})

const formNames = ['base', 'media', 'content', 'quiz']

const areEqual = (prevProps, nextProps) => {
  const { forms: prevForms, ...restPrev } = prevProps
  const { forms: nextForms, ...restNext } = nextProps
  if (prevForms) {
    const formsEqual = formNames.every(
      form =>
        _.isEqual(prevForms[form].errors, nextForms[form].errors) &&
        _.isEqual(prevForms[form].dirty, nextForms[form].dirty)
    )
    return formsEqual && _.isEqual(restPrev, restNext)
  }
  return Object.is(prevProps, nextProps)
}

const formsSelector = state =>
  formNames.reduce(
    (forms, formName) => ({
      ...forms,
      [formName]: {
        errors: getFormSyncErrors(formName)(state),
        dirty: isDirty(formName)(state),
        values: getFormValues(formName)(state),
      },
    }),
    {}
  )

const enhanceSaveAll = compose(
  withStyles(styles),
  connect(state => ({
    forms: formsSelector(state),
    supplier: state.supplier,
  }))
  // React.memo
  // memoize
)

const onUnload = event => {
  // the method that will be used for both add and remove event
  event.preventDefault()
  const msg =
    'Dina ändringar kommer att gå förlorade om du fortsätter. Forsätt ändå?'
  // eslint-disable-next-line no-param-reassign
  event.returnValue = msg
  return msg
}

const SaveAll = enhanceSaveAll(props => {
  const { classes, forms, productUid, supplier } = props
  const dirty = _.some(forms, 'dirty')
  const [isSaving, setSaving] = React.useState(false)

  React.useEffect(() => {
    // window.addEventListener('beforeunload', event => onUnload(event, dirty))
    // return () => window.removeEventListener('beforeunload', onUnload)
    if (dirty) {
      window.onbeforeunload = onUnload
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [forms])

  const saveAll = () => {
    const productRef = database.queryProduct(productUid)
    const batch = database.batch()
    const updateData = {
      editDateTime: serverTimestamp(),
    }
    if (!supplier.legacyPricing) {
      updateData.activeEducation = true
    }
    const { base, media, quiz, content } = forms
    if (base.dirty) {
      Object.assign(updateData, {
        ...base.values,
        'completion.base': calcProgress('base', base.errors, base.values),
      })
    }
    if (media.dirty) {
      Object.assign(updateData, {
        ...media.values,
        'completion.media': calcProgress('media', media.errors, media.values),
      })
    }
    if (quiz.dirty) {
      updateData['completion.quiz'] = calcProgress(
        'quiz',
        quiz.errors,
        quiz.values
      )
      batchUpdateOrderedCollection(
        productRef.collection('quiz'),
        quiz.values.quiz,
        batch
      )
    }
    if (content.dirty) {
      updateData['completion.content'] = calcProgress(
        'content',
        content.errors,
        content.values
      )
      batchUpdateOrderedCollection(
        productRef.collection('sections'),
        content.values.sections,
        batch
      )
    }
    setSaving(true)
    batch.update(productRef, updateData)
    analytics.logEvent('product_save', { productUid })
    return batch.commit().then(() => setSaving(false))
  }
  const getButtonText = () => {
    if (isSaving) {
      return 'Sparar...'
    }
    if (dirty) {
      return 'Spara'
    }
    return 'Sparat!'
  }

  return (
    <div>
      <Prompt
        when={dirty}
        message={location =>
          location.pathname.includes('/create') &&
          location.pathname.includes('/product')
            ? true
            : 'Dina ändringar kommer att gå förlorade om du fortsätter. Forsätt ändå?'
        }
      />
      <div className={classes.wrapper}>
        <Button
          color="primary"
          variant="contained"
          className={classes.saveAll}
          onClick={saveAll}
          disabled={!dirty || isSaving}
          endIcon={<SaveIcon />}
          size="large"
        >
          {getButtonText()}
        </Button>
        {isSaving && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  )
})

const memoize = comp => React.memo(comp, areEqual)
const enhanceStatus = compose(
  withStyles(styles),
  withRouter,
  connect(
    state => ({
      forms: formsSelector(state),
      product: state.product,
    }),
    { setGuideOpen }
  ),
  // React.memo
  memoize
)

const FormsStatus = enhanceStatus(props => {
  const {
    product,
    forms,
    classes,
    match: {
      params: { productUid },
      url,
    },
    location,
    history,
    onOpenReview,
  } = props
  const itemsWithProgress = useMemo(
    () =>
      listItems.map(item => {
        const form = forms[item.path]
        const savedProgress = product.completion
          ? _.toInteger(product.completion[item.path])
          : 0
        const progress = form.dirty
          ? calcProgress(item.path, form.errors, form.values)
          : savedProgress
        return {
          ...item,
          progress,
        }
      }),
    [product, ...formNames.map(form => forms[form].errors)]
  )
  const dirty = _.some(forms, 'dirty')

  const avgProgress = Math.ceil(
    _.sumBy(itemsWithProgress, 'progress') / listItems.length
  )
  const selectedItem = listItems.find(
    item => location.pathname === `${url}/${item.path}`
  )
  const selectedPath = selectedItem && selectedItem.path

  const readyForQualityControl =
    avgProgress === 100 &&
    !dirty &&
    (!product.lastSentForQualityControlDateTime ||
      product.lastSentForQualityControlDateTime < product.editDateTime)
  return (
    <>
      <Paper>
        <List disablePadding>
          {itemsWithProgress.map(item => {
            return (
              <ListItem
                key={item.path}
                button
                selected={item.path === selectedPath}
                onClick={() => history.push(`${url}/${item.path}`)}
              >
                <ListItemText primary={item.title} />
                <ListItemSecondaryAction>
                  {item.progress >= 100 ? (
                    <CheckIcon color="primary" />
                  ) : (
                    <LinearProgress
                      style={{ width: 120 }}
                      variant="determinate"
                      value={item.progress}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
        <Grid
          container
          spacing={2}
          alignItems="flex-end"
          justify="space-between"
        >
          <Grid item>
            <div
              style={{
                position: 'relative',
                display: 'inline-block',
                width: 120,
                height: 120,
                margin: 16,
              }}
            >
              <Typography
                style={{
                  lineHeight: '120px',
                  textAlign: 'center',
                }}
                variant="h6"
                color="primary"
              >
                {avgProgress}%
              </Typography>
              <CircularProgress
                variant="static"
                value={avgProgress}
                thickness={6}
                size={120}
                className={classes.circleProgress}
              />
            </div>
          </Grid>
          <Grid item>
            <SaveAll productUid={productUid} />
          </Grid>
        </Grid>
      </Paper>

      <Grid
        style={{ marginTop: 24 }}
        container
        spacing={2}
        alignItems="flex-start"
        justify="flex-start"
      >
        <Grid item>
          <ProductExports productUid={productUid} style={{ marginRight: 16 }} />
        </Grid>
        <Grid item>
          <Button
            fullWidth
            endIcon={<SendIcon />}
            color="primary"
            variant="contained"
            disabled={!readyForQualityControl}
            onClick={onOpenReview}
          >
            Skicka till granskning
          </Button>
        </Grid>
      </Grid>
    </>
  )
})
const ProductGeneralGuide = connect(null, { closeGuide })(({ closeGuide }) => (
  <Card>
    <CardHeader title="Publicera och följ upp din utbildning i tre steg" />
    <CardContent>
      <List>
        <ListItem>
          <ListItemIcon>
            <Subject />
          </ListItemIcon>
          <ListItemText>
            Lägg till grundläggande information, media, utbildningsinnehåll och
            kunskapstest för att göra din utbildning komplett. Mer guidning och
            exempel kommer under respektive del.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Search />
          </ListItemIcon>
          <ListItemText>
            Skicka till granskning som utförs av Apostar och sedan vissa apotek.
            Om komplettering begärs så gör du ändringar och skickar till
            granskning på nytt. Efter godkänd granskning publiceras utbildningen
            i appen.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Assessment />
          </ListItemIcon>
          <ListItemText>
            Du som utbildningsskapare får nu automatisk uppföljning per mejl och
            kan se detaljerad{' '}
            <RouterLink target="_blank" component={Link} to="/statistics">
              statistik här i portalen
            </RouterLink>
            . Information om svårighetsgrad på kunskapstestet och individuella
            frågor ger dig underlag att justera utbildningen.
          </ListItemText>
        </ListItem>
      </List>
    </CardContent>
    <CardActions>
      <Button color="primary" onClick={() => closeGuide('productGeneral')}>
        Jag förstår
      </Button>
    </CardActions>
  </Card>
))

class ProductCreate extends Component {
  state = { openReviewDialog: false }

  componentDidMount() {
    const {
      match: {
        params: { productUid },
      },
      loadProductPart,
      loadExampleProduct,
      startLoadFullProduct,
      initialize,
    } = this.props
    ReactGA.set({ dimension3: productUid })
    ReactGA.event({
      category: 'Product',
      action: 'Started Edit',
      label: productUid,
    })
    analytics.logEvent('product_edit_start', { productUid })

    const isInitializedSinceMount = formNames.reduce((obj, form) => ({
      ...obj,
      [form]: false,
    }))
    const initializeForm = (form, data) => {
      initialize(form, makeInitData(form, data), {
        keepDirty: isInitializedSinceMount[form],
        keepSubmitSucceeded: true,
        updateUnregisteredFields: true,
      })
      isInitializedSinceMount[form] = true
    }

    startLoadFullProduct(productUid)
    const productRef = database.queryProduct(productUid)
    const productListener = productRef.onSnapshot(productSnap => {
      const product = productSnap.data()
      initializeForm('base', product)
      loadProductPart(productUid, product)
      if (this.attachmentsListener) this.attachmentsListener()
      this.attachmentsListener = productRef
        .collection('attachments')
        .orderBy('index')
        .onSnapshot(attachmentsSnap => {
          const attachments = database.getCollectionDocsDataWithUid(
            attachmentsSnap
          )
          loadProductPart(productUid, { attachments })
          initializeForm('media', { ...product, attachments })
        })
      this.listeners.push(this.attachmentsListener)
    })
    loadExampleProduct()
    const sectionsListener = productRef
      .collection('sections')
      .orderBy('index')
      .onSnapshot(sectionsSnap => {
        const sections = database.getCollectionDocsDataWithUid(sectionsSnap)
        initializeForm('content', { sections })
        loadProductPart(productUid, { sections })
      })
    const quizListener = productRef
      .collection('quiz')
      .orderBy('index')
      .onSnapshot(quizSnap => {
        const quiz = database.getCollectionDocsDataWithUid(quizSnap)
        initializeForm('quiz', { quiz })
        loadProductPart(productUid, { quiz })
      })
    this.listeners = [productListener, quizListener, sectionsListener]
  }

  componentWillUnmount() {
    ReactGA.set({ dimension3: null })
    this.listeners.forEach(unsubscribe => unsubscribe())
    formNames.forEach(form => destroy(form))
  }

  setReviewOpen = open => this.setState({ openReviewDialog: open })

  render() {
    const {
      product,
      location,
      match: {
        path,
        url,
        params: { productUid },
      },
      history,
      generalGuide,
      setGuideOpen,
    } = this.props
    const { openReviewDialog } = this.state
    const selectedItem = listItems.find(
      item => location.pathname === `${url}/${item.path}`
    )
    const selectedPath = selectedItem && selectedItem.path
    const guidePart =
      selectedPath === 'base' || selectedPath === 'media'
        ? 'productGeneral'
        : selectedPath
    return (
      <div>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h4" color="primary" gutterBottom>
              {product.lastSentForQualityControlDateTime ? 'Redigera' : 'Skapa'}{' '}
              produktutbildning
            </Typography>
          </Grid>
          <Grid item>
            <Button
              endIcon={<Help />}
              variant="contained"
              disabled={!guidePart}
              onClick={() => {
                setGuideOpen({ [guidePart]: true })
                analytics.logEvent('open_guide', { part: guidePart })
              }}
            >
              Guide
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {generalGuide && (
            <Grid item xs={12}>
              <ProductGeneralGuide />
            </Grid>
          )}
          <Grid item lg={5}>
            <FormsStatus onOpenReview={() => this.setReviewOpen(true)} />

            <Box mt={3}>
              <MobileDevice>
                <MobileEducation product={product} />
              </MobileDevice>
              <Typography align="center" style={{ fontStyle: 'italic' }}>
                Endast sparade ändringar visas i mobilen
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={7}>
            {!(product.creationDateTime && product.quiz && product.sections) ? (
              <LinearProgress />
            ) : (
              <Switch>
                <Redirect exact path={`${path}`} to={`${path}/base`} />
                <PrivateRoute
                  path={`${path}/base`}
                  component={ProductBaseInput}
                  title="Redigera grundinformation"
                />
                <PrivateRoute
                  path={`${path}/media`}
                  component={ProductMediaInput}
                  title="Redigera media"
                />
                <PrivateRoute
                  path={`${path}/content`}
                  component={ProductSectionsInput}
                  title="Redigera utbildningsinnehåll"
                />
                <PrivateRoute
                  path={`${path}/quiz`}
                  component={QuizInput}
                  title="Redigera kunskapstest"
                />
              </Switch>
            )}
          </Grid>
        </Grid>
        <ReviewDialog
          cancelText="Fortsätt redigera"
          open={openReviewDialog}
          onClose={() => this.setReviewOpen(false)}
          onSendSuccess={() =>
            history.push(`/product/${productUid}/overview/base`)
          }
          productUid={productUid}
        />
      </div>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  connect(
    (state, ownProps) => ({
      product: state.fullProducts[ownProps.match.params.productUid] || {},
      generalGuide: getGuidePart(state, 'productGeneral'),
    }),
    {
      startLoadFullProduct,
      loadProductPart,
      loadExampleProduct,
      initialize,
      closeGuide,
      setGuideOpen,
    }
  ),
  withRouter
)
export default enhance(ProductCreate)
