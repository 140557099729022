import _ from 'lodash'

const WORD_COUNT_CHANGED = 'WORD_COUNT_CHANGED'

const wordCountChange = wordCount => ({
  type: 'WORD_COUNT_CHANGED',
  data: wordCount,
})

const productInputReducer = (state, action) => {
  if (_.isUndefined(state)) {
    return state
  }
  if (action.type === WORD_COUNT_CHANGED && state.values) {
    return {
      ...state,
      values: {
        ...state.values,
        totalWordCount: !state.values.sections
          ? 0
          : state.values.sections.reduce(
              (totalWordCount, section) =>
                section.wordCount
                  ? totalWordCount + section.wordCount
                  : totalWordCount,
              0
            ),
      },
    }
  }
  return state
}
export { wordCountChange, WORD_COUNT_CHANGED, productInputReducer }
