import React from 'react'

const Image = props => {
  const { image, maxHeight, maxWidth, style } = props
  if (!image) {
    return null
  }
  const styles = style || {}
  if (maxHeight !== false) {
    styles.maxHeight = maxHeight || 300
  }
  if (maxWidth !== false) {
    styles.maxWidth = maxWidth || '100%'
  }
  let imageUrl = image.formattedUrl || image.url
  if (image.thumbnailUrl && (maxHeight <= 200 || maxWidth <= 200)) {
    imageUrl = image.thumbnailUrl
  }
  return image ? <img alt="" src={imageUrl} style={styles} /> : null
}

export default Image
