const configDev = {
  apiKey: 'AIzaSyBmvLVmDSOkqHlYbTitwDHFrh7qpHH4cic',
  authDomain: 'apostar-development.firebaseapp.com',
  databaseURL: 'https://apostar-development.firebaseio.com',
  functionsURL: 'https://us-central1-apostar-development.cloudfunctions.net',
  projectId: 'apostar-development',
  storageBucket: 'apostar-development.appspot.com',
  messagingSenderId: '665870731119',
  appId: '1:665870731119:web:aaa2e99a669f5c5c',
  measurementId: 'G-2RBWPQSXC9',
}
const configTest = {
  apiKey: 'AIzaSyAPprbDr-KCk1sqZTd0QBnI1lCa7QySwYs',
  authDomain: 'apostar-test.firebaseapp.com',
  databaseURL: 'https://apostar-test.firebaseio.com',
  functionsURL: 'https://us-central1-apostar-test.cloudfunctions.net',
  projectId: 'apostar-test',
  storageBucket: 'apostar-test.appspot.com',
  messagingSenderId: '523617115519',
  appId: '1:523617115519:web:0c64a388050af926',
}
const configProduction = {
  apiKey: 'AIzaSyA2ey4tlluhTa5idHmeVsndq77zT_Rw39A',
  authDomain: 'apostar-production.firebaseapp.com',
  databaseURL: 'https://apostar-production.firebaseio.com',
  projectId: 'apostar-production',
  storageBucket: 'apostar-production.appspot.com',
  messagingSenderId: '366600267237',
  appId: '1:366600267237:web:cc6ebc2aa4e6c8fc',
  measurementId: 'G-S18KHMBXZQ',
}
const version = '1.4.0'
const commit = 'asdr23'
const trackerSuffix = {
  development: '2',
  test: '4',
  production: '3',
}
const gaTrackerId = `UA-122982369-${trackerSuffix.development}`
export { configDev, configTest, configProduction, version, commit, gaTrackerId }
