import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import UnansweredRequests from './UnansweredRequests'
import AnsweredRequests from './AnsweredRequests'

function QualityControl(props) {
  const { user } = props
  return (
    <React.Fragment>
      <Typography color="primary" variant="h4" gutterBottom>
        Kvalitetsgranskning
      </Typography>
      {user.uid && user.isPharmacyAdmin() && (
        <Fragment>
          <Typography variant="body1">
            Här ser du alla nya och uppdaterade utbildningar som leverantörer
            önskar att få publicera till era användare.
          </Typography>
          <Typography variant="body1">
            Samtliga har granskats och godkänts av Apostar innan de syns här.
            Utbildningen godkänns automatiskt 2 veckor från inkommet datum om du
            inte godkänner den eller begär komplettering.
          </Typography>
        </Fragment>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <UnansweredRequests />
        </Grid>
        <Grid item xs={12} md={6}>
          <AnsweredRequests />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export default connect(state => {
  if (!state) {
    return {}
  }
  return {
    user: state.user,
  }
})(QualityControl)
