import React, { Component } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import LineChartIcon from '@material-ui/icons/ShowChart'
import AddShoppingCart from '@material-ui/icons/AddShoppingCart'
import PersonIcon from '@material-ui/icons/Person'
import HelpIcon from '@material-ui/icons/Help'
import DataUsage from '@material-ui/icons/DataUsage'
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'
import DomainIcon from '@material-ui/icons/Domain'
import HomeIcon from '@material-ui/icons/Home'
import _ from 'lodash'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Permission } from '../constants'

const drawerWidth = 260

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appFrame: {
    // height: 430,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    position: 'absolute',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'appBarShift-left': {
    marginLeft: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
})

const menuItems = [
  {
    name: 'Hem',
    path: '/',
    icon: <HomeIcon />,
    open: true,
    canView: user => user.isSupplierAdmin(),
  },
  {
    name: 'Kvalitetsgranskning',
    path: '/quality-control',
    icon: <RemoveRedEye />,
    open: true,
    canView: user => user.isSuperAdmin() || user.isPharmacyAdmin(),
  },
  {
    name: 'Produkter',
    path: '/products',
    icon: <LocalPharmacyIcon />,
    open: true,
    canView: () => true,
  },
  {
    name: 'Terapiområden',
    path: '/therapeutics',
    icon: <LocalPharmacyIcon />,
    open: true,
    canView: user =>
      (user.isSupplierAdmin() && user.hasPermission(Permission.THERAPEUTICS)) ||
      user.isSuperAdmin(),
  },
  {
    name: 'Produktfrågor',
    path: '/conversations',
    icon: <QuestionAnswerIcon />,
    open: true,
    canView: user => user.isSupplierAdmin(),
  },
  {
    name: 'Statistik',
    path: '/statistics/overview',
    icon: <LineChartIcon />,
    open: true,
    canView: user =>
      user.isSuperAdmin() ||
      user.isSupplierAdmin() ||
      (user.isPharmacyAdmin() && user.statisticsEnabled),
  },
  {
    name: 'Fördela kunskapstest',
    path: '/activation',
    icon: <DataUsage />,
    open: true,
    canView: (user, supplier) =>
      user.isSupplierAdmin() && supplier && supplier.legacyPricing,
  },
  {
    name: 'Mitt konto',
    path: '/my-account',
    icon: <PersonIcon />,
    open: true,
    canView: () => true,
  },
  {
    name: 'Min organisation',
    path: '/my-organization',
    icon: <DomainIcon />,
    open: true,
    canView: user => user.isSupplierAdmin() || user.isPharmacyAdmin(),
  },
  {
    name: 'Leverantörer & användare',
    path: '/accounts',
    icon: <PersonIcon />,
    open: true,
    canView: user => user.isSuperAdmin(),
  },
  {
    name: 'Hjälp',
    path: '/help',
    icon: <HelpIcon />,
    open: true,
    canView: () => true,
  },
  {
    name: 'Priser',
    path: '/pricing',
    icon: <AddShoppingCart />,
    open: true,
    canView: (user, supplier) =>
      user.isSupplierAdmin() && supplier && !supplier.legacyPricing,
  },
]
class PersistentDrawer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menuItems: [],
    }
    const pathsWithOpen = this.state.menuItems
      .filter(menuItem => menuItem.open)
      .map(menuItem => menuItem.path)
    this.state.open = pathsWithOpen.includes(this.props.location.pathname)
  }

  componentDidMount() {
    this.handleAuthChange()
  }

  componentDidUpdate(prevProps) {
    const { user, supplier } = this.props
    const userChanged = !_.isEqual(user, prevProps.user)
    const supplierChanged = !_.isEqual(supplier, prevProps.supplier)
    if (userChanged || supplierChanged) {
      this.handleAuthChange()
    }
  }

  handleDrawerOpen = () => {
    if (this.state.user) {
      this.setState({ open: true })
    }
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  handleAuthChange() {
    const { user, supplier } = this.props
    if (user && supplier) {
      this.setState({
        menuItems: menuItems.filter(menuItem =>
          menuItem.canView(user, supplier)
        ),
        open: true,
      })
    } else {
      this.setState({
        open: false,
      })
    }
  }

  render() {
    const { classes, user, supplier } = this.props
    const { open } = this.state

    const getBackgroundColor = menuItem =>
      this.props.location.pathname === menuItem.path ? '#ededed' : '#ffffff'

    const filteredMenuItems = !user
      ? []
      : menuItems.filter(menuItem => menuItem.canView(user, supplier))
    const drawer = (
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={this.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <List>
          {filteredMenuItems.map((menuItem, index) => (
            <Link key={index} to={menuItem.path}>
              <ListItem
                style={{ backgroundColor: getBackgroundColor(menuItem) }}
              >
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                <ListItemText>{menuItem.name}</ListItemText>
                <Divider />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    )

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open,
              [classes['appBarShift-left']]: open,
            })}
          >
            <Toolbar disableGutters={!open}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                style={{ flex: 1 }}
                noWrap
              >
                <Link to="/">Apostar</Link>
              </Typography>
              {!user && (
                <Link color="inherit" to="/login">
                  <Button color="inherit">Logga in</Button>
                </Link>
              )}
            </Toolbar>
          </AppBar>
          {drawer}
          <main
            className={classNames(classes.content, classes['content-left'], {
              [classes.contentShift]: open,
              [classes['contentShift-left']]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            {React.cloneElement(this.props.children, { ...this.props })}
          </main>
        </div>
      </div>
    )
  }
}

const enhance = compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(state => ({
    user: state.user,
    supplier: state.supplier,
  }))
)
export default enhance(PersistentDrawer)
