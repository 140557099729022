import React, { Fragment } from 'react'
import Button from '@material-ui/core/Button'
import { Field, FieldArray, FormSection } from 'redux-form'
import Typography from '@material-ui/core/Typography'
import TherapeuticsSectionInput from './TherapeuticsSectionInput'

const emptySection = {
  title: '',
  text: '',
  required: false,
}

const renderSections = ({ fields, storagePath }) => {
  return (
    <Fragment>
      {fields.map((section, index) => (
        <FormSection name={section}>
          <TherapeuticsSectionInput
            fieldname={section}
            storagePath={storagePath}
            onRemove={() => fields.remove(index)}
          />
        </FormSection>
      ))}
      <div>
        <Button
          variant="contained"
          onClick={() => fields.push({ ...emptySection })}
        >
          Lägg till rubrik
        </Button>
      </div>
    </Fragment>
  )
}

function ProductSectionsInput(props) {
  return (
    <React.Fragment>
      <FieldArray
        props={{ storagePath: props.storagePath }}
        name="sections"
        component={renderSections}
      />
      <Field
        name="totalWordCount"
        component={({ input: { value } }) => (
          <Typography>Ord {value}/600</Typography>
        )}
      />
    </React.Fragment>
  )
}

export default ProductSectionsInput
