import React from 'react'
import { ComposableMap, Geographies, Geography } from 'react-simple-maps'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import { withData } from './common'

const wrapperStyles = {
  width: '100%',
  maxWidth: 980,
  margin: '0 auto',
}

const countyValue = county => (county ? county.events : 0)

const colorScale = (value, maxValue = 300) => {
  // gradient 250, 250, 255 -> 0, 136, 255
  // const green = 255
  const green = value < maxValue ? 250 - ((250 - 194) * value) / maxValue : 194
  const red = value < maxValue ? 250 - (250 * value) / maxValue : 0
  const blue = value < maxValue ? 250 - ((250 - 159) * value) / maxValue : 159
  return `rgb(${red}, ${green}, ${blue})`
}

const getGeographyData = (data, geography, dataKey) => {
  const geographyData = data[geography.properties.LANSKOD]
  return geographyData ? geographyData[dataKey] : 0
}

const ForwardGeography = React.forwardRef((props, ref) => {
  //  Spread the props to the underlying DOM element.
  return (
    <Geography
      {...props}
      onMouseLeave={(geo, event) => props.onMouseLeave(event)}
      ref={ref}
    />
  )
})

const makeGeographyId = geography =>
  `geo-${geography.properties.KKOD}-${geography.properties.LANSKOD}`

function CountryMap({ data }) {
  const countyValues = Object.values(data).map(countyValue)
  const maxValue = _.max(countyValues)
  return (
    <div style={wrapperStyles}>
      <ComposableMap
        projection="mercator"
        projectionConfig={{
          scale: 1000,
          xOffset: -400,
          yOffset: 1400,
        }}
        width={1000}
        height={1000 * 0.56}
        style={{
          width: '100%',
          height: '340px',
        }}
      >
        <Geographies geography="/an_riks_proj.json" disableOptimization>
          {(geographies, projection) =>
            geographies.map(geography => (
              <Tooltip
                key={makeGeographyId(geography)}
                disableFocusListener
                disableTouchListener
                title={
                  <Typography variant="caption">
                    {geography.properties.LANSNAMN}
                    <br />
                    {/* Användare: {getGeographyData(data, geography, 'users') < 5 ? "< 5" : getGeographyData(data, geography, 'users')}<br/> */}
                    Godkända kunskapstest:{' '}
                    {getGeographyData(data, geography, 'events')}
                  </Typography>
                }
              >
                <ForwardGeography
                  geography={geography}
                  projection={projection}
                  style={{
                    default: {
                      fill: colorScale(
                        countyValue(data[geography.properties.LANSKOD]),
                        maxValue
                      ),
                      stroke: '#607D8B',
                      strokeWidth: 0.75,
                      outline: 'none',
                    },
                    hover: {
                      fill: '#607D8B',
                      stroke: '#607D8B',
                      strokeWidth: 0.75,
                      outline: 'none',
                    },
                    pressed: {
                      fill: '#FF5722',
                      stroke: '#607D8B',
                      strokeWidth: 0.75,
                      outline: 'none',
                    },
                  }}
                />
              </Tooltip>
            ))
          }
        </Geographies>
      </ComposableMap>
      <div
        style={{
          lineHeight: '25px',
          height: 25,
        }}
      >
        <span>0</span>
        <div
          style={{
            height: 25,
            width: 130,
            display: 'inline-block',
            backgroundImage: `linear-gradient(to right, ${colorScale(
              0
            )}, ${colorScale(1, 1)})`,
          }}
        />
        <span>{maxValue}</span>
      </div>
    </div>
  )
}

export default withData(CountryMap)
