import _ from 'lodash'
import { connect } from 'react-redux'
import databaseManager from './database'
import { PRODUCT_TYPES } from './constants'

const database = databaseManager.getDatabase()

const withUser = connect(state => ({ user: state.user }))

const setGuideOpen = data => ({
  type: 'SET_GUIDE_OPEN',
  data,
})

const closeGuide = part => (dispatch, getState) => {
  const { user } = getState()
  database
    .collection('users')
    .doc(user.uid)
    .update({ [`guide.${part}`]: false })
    .then(() => dispatch(setGuideOpen({ [part]: false })))
}

const loadOptions = (collection, sortKey) => dispatch =>
  database
    .collection(collection)
    .orderBy(sortKey)
    .get()
    .then(optionsSnap => {
      const options = optionsSnap.docs.map(optionSnap => ({
        value: optionSnap.id,
        ...optionSnap.data(),
      }))
      dispatch({
        type: 'LOAD_OPTIONS',
        name: collection,
        data: options,
      })
      return options
    })

const loadInviteQuery = (query, dispatch) =>
  query.orderBy('creationDateTime', 'desc').onSnapshot(invitesSnap => {
    const invites = databaseManager.getCollectionDocsDataWithUid(invitesSnap)
    dispatch({
      type: 'LOAD_INVITES',
      data: invites,
    })
    return invites
  })

const loadInvites = supplierUid => (dispatch, getState) => {
  const { user } = getState()
  const query = database
    .collection('invitations')
    .where('type', '==', 'supplier')
    .where('createdByUserUid', '==', user.uid)
  if (supplierUid) {
    const supplierQuery = query.where('supplierUid', '==', supplierUid)
    return loadInviteQuery(supplierQuery, dispatch)
  }
  return loadInviteQuery(query, dispatch)
}

const loadFullProduct = productUid => (dispatch, getState) => {
  const { user } = getState()
  dispatch({
    type: 'LOAD_FULL_PRODUCT_START',
    productUid,
  })
  return databaseManager
    .getFullProductForUser(productUid, user)
    .then(product => {
      dispatch({
        type: 'LOAD_FULL_PRODUCT',
        data: {
          ...product,
          productUid,
        },
      })
      return product
    })
}

const loadExampleProduct = () => (dispatch, getState) => {
  const { user } = getState()
  database
    .collection('products')
    .where('isExample', '==', true)
    .get()
    .then(productsSnap => {
      if (!productsSnap.empty) {
        const productUid = productsSnap.docs[0].id
        databaseManager
          .getFullProductForUser(productUid, user)
          .then(product => {
            dispatch({
              type: 'LOAD_EXAMPLE_PRODUCT',
              data: product,
            })
          })
      }
    })
}

const loadProducts = () => (dispatch, getState) => {
  const { user } = getState()
  return databaseManager
    .queryAllProductsAndSeriesForUser(user)
    .orderBy('name')
    .onSnapshot(productSnap => {
      const products = databaseManager.getCollectionDocsDataWithUid(productSnap)
      dispatch({
        type: 'LOAD_PRODUCTS',
        data: products,
      })
      return products
    })
}

const loadTherapeutics = () => dispatch =>
  databaseManager
    .queryTherapeutics()
    .orderBy('name')
    .onSnapshot(therapeuticsSnap => {
      const therapeutics = databaseManager.getCollectionDocsDataWithUid(
        therapeuticsSnap
      )
      dispatch({
        type: 'LOAD_THERAPEUTICS',
        data: therapeutics,
      })
      return therapeutics
    })

const loadTherapeuticParts = therapeuticUid => dispatch => {
  dispatch({
    type: 'LOAD_THERAPEUTIC_PARTS_START',
    therapeuticUid,
  })
  return databaseManager
    .queryTherapeutic(therapeuticUid)
    .collection('parts')
    .orderBy('index')
    .onSnapshot(partsSnap => {
      const parts = databaseManager.getCollectionDocsDataWithUid(partsSnap)
      dispatch({
        type: 'LOAD_THERAPEUTIC_PARTS',
        data: parts,
        therapeuticUid,
      })
      return parts
    })
}

const loadTherapeuticPart = (therapeuticUid, partUid) => dispatch => {
  dispatch({
    type: 'LOAD_THERAPEUTIC_PART_START',
    therapeuticUid,
    partUid,
  })
  const partRef = databaseManager
    .queryTherapeutic(therapeuticUid)
    .collection('parts')
    .doc(partUid)
  return databaseManager
    .getProductCollections(partRef)
    .then(collectionsData => {
      dispatch({
        type: 'LOAD_THERAPEUTIC_PART',
        data: collectionsData,
        therapeuticUid,
        partUid,
      })
      return collectionsData
    })
}

const loadPharmacies = () => dispatch =>
  database
    .collection('pharmacies')
    .orderBy('name')
    .get()
    .then(pharmaciesSnap => {
      const pharmacies = databaseManager.getCollectionDocsDataWithUid(
        pharmaciesSnap
      )
      dispatch({
        type: 'LOAD_PHARMACIES',
        data: pharmacies,
      })
      return pharmacies
    })

const streamRequestsForProduct = productUid => dispatch =>
  databaseManager
    .getDatabase()
    .collectionGroup('publicationRequests')
    .where('productUid', '==', productUid)
    .orderBy('creationDateTime', 'desc')
    .onSnapshot(requestsSnap => {
      const [requestGroups, pharmacyRequests] = _.partition(
        requestsSnap.docs,
        snap => snap.ref.path.startsWith('publicationRequests')
      ).map(requestDocs =>
        requestDocs.map(requestDoc => ({
          ...requestDoc.data(),
          uid: requestDoc.id,
        }))
      )
      const groupedRequests = _.groupBy(pharmacyRequests, 'requestGroupUid')
      const requestGroupsWithPharmacyRequests = requestGroups.map(
        requestGroup => ({
          ...requestGroup,
          requests: requestGroup.requests.map(request => ({
            ...request,
            ...groupedRequests[requestGroup.uid].find(
              pharmacyRequest => pharmacyRequest.uid === request.requestUid
            ),
          })),
        })
      )
      dispatch({
        type: 'LOAD_REQUESTS',
        data: requestGroupsWithPharmacyRequests,
        productUid,
      })
      return requestGroupsWithPharmacyRequests
    })

const loadUser = user => ({
  type: 'LOAD_USER',
  data: user,
})

const removeUser = () => ({
  type: 'LOAD_USER',
  data: null,
})

const loadSupplier = supplierUid => dispatch =>
  database
    .collection('suppliers')
    .doc(supplierUid)
    .onSnapshot(supplierSnap => {
      const supplier = { uid: supplierSnap.id, ...supplierSnap.data() }
      dispatch({
        type: 'LOAD_SUPPLIER',
        data: supplier,
      })
      return supplier
    })

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case 'AUTHENTICATED':
      return { ...state, authenticated: true, initialized: true }
    case 'UNAUTHENTICATED':
      return { ...state, authenticated: false, initialized: true }
    case 'AUTHENTICATION_ERROR':
      return { ...state, error: action.payload }
    default:
      return state
  }
}

const optionsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_OPTIONS': {
      const { data, name } = action
      const byGroup = _.groupBy(data, 'group')
      const byKey = _.keyBy(data, 'value')
      return {
        ...state,
        [name]: {
          ...state[name],
          list: data,
          byKey,
          byGroup,
        },
      }
    }
    default:
      return state
  }
}

const invitesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_INVITES': {
      const { data } = action
      return {
        list: data,
        initialized: true,
      }
    }
    default:
      return state
  }
}

const therapeuticsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_THERAPEUTICS': {
      const therapeutics = action.data
      const [finished, unfinished] = _.partition(therapeutics, 'isComplete')

      return {
        list: therapeutics,
        grouped: {
          finished,
          unfinished,
        },
        initialized: true,
      }
    }
    default:
      return state
  }
}

const therapeuticPartsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_THERAPEUTIC_PARTS_START': {
      const { therapeuticUid } = action
      return {
        ...state,
        [therapeuticUid]: {
          ...state[therapeuticUid],
          loading: true,
        },
      }
    }
    case 'LOAD_THERAPEUTIC_PARTS': {
      const { data: parts, therapeuticUid } = action
      const [finished, unfinished] = _.partition(parts, 'isComplete')
      return {
        ...state,
        [therapeuticUid]: {
          ...state[therapeuticUid],
          list: parts,
          grouped: {
            finished,
            unfinished,
          },
          initialized: true,
          loading: false,
        },
      }
    }
    case 'LOAD_THERAPEUTIC_PART': {
      const { data, therapeuticUid, partUid } = action
      const prevState = state[therapeuticUid]
      const prevPartsData = prevState ? prevState.partsData : {}
      return {
        ...state,
        [therapeuticUid]: {
          ...prevState,
          partsData: {
            ...prevPartsData,
            [partUid]: {
              ...data,
              initialized: true,
              loading: false,
            },
          },
        },
      }
    }
    default:
      return state
  }
}

const productsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_PRODUCTS': {
      const productsArray = action.data
      const [trashedProducts, products] = _.partition(productsArray, 'trashed')
      const productGroups = _.groupBy(products, 'type')
      const regularProducts = productGroups[PRODUCT_TYPES.PRODUCT] || []
      const seriesProducts = productGroups[PRODUCT_TYPES.SERIES_PRODUCT] || []
      const supplierProducts = regularProducts.concat(seriesProducts)
      const [finishedProducts, unfinishedProducts] = _.partition(
        supplierProducts,

        product =>
          product.lastSentForQualityControlDateTime || product.isComplete
      )

      const genericProducts = productGroups[PRODUCT_TYPES.GENERIC] || []
      const [finishedGenerics, unfinishedGenerics] = _.partition(
        genericProducts,

        product =>
          product.lastSentForQualityControlDateTime || product.isComplete
      )

      return {
        list: productsArray,
        grouped: {
          trashedProducts,
          finishedProducts,
          unfinishedProducts,
          finishedGenerics,
          unfinishedGenerics,
        },
        initialized: true,
      }
    }
    default:
      return state
  }
}

const productReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_FULL_PRODUCT_START': {
      return {
        loading: true,
      }
    }
    case 'LOAD_FULL_PRODUCT':
      return {
        ...action.data,
        loading: false,
      }
    case 'LOAD_PARTIAL_PRODUCT':
      return {
        ...state,
        ...action.data,
        loading: false,
      }
    default:
      return state
  }
}

const fullProductsReducer = (state = {}, action) => {
  return {
    ...state,
    [action.productUid]: productReducer(state[action.productUid], action),
  }
}

const guideReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_EXAMPLE_PRODUCT':
      return {
        ...state,
        exampleProduct: action.data,
      }
    case 'SET_GUIDE_OPEN':
      return {
        ...state,
        parts: {
          ...state.parts,
          ...action.data,
        },
      }
    default:
      return state
  }
}

const pharmaciesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_PHARMACIES':
      return action.data
    default:
      return state
  }
}

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_USER':
      return action.data
    case 'UPDATE_USER_SETTINGS':
      return {
        ...state,
        settings: action.data,
      }
    default:
      return state
  }
}

const supplierReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_SUPPLIER':
      return action.data
    default:
      return state
  }
}

const requestsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_REQUESTS':
      return {
        ...state,
        [action.productUid]: {
          list: action.data,
          initialized: true,
        },
      }
    default:
      return state
  }
}

export {
  loadOptions,
  loadProducts,
  loadTherapeutics,
  loadTherapeuticParts,
  loadTherapeuticPart,
  loadUser,
  loadSupplier,
  loadPharmacies,
  loadFullProduct,
  loadExampleProduct,
  loadInvites,
  streamRequestsForProduct,
  removeUser,
  setGuideOpen,
  closeGuide,
  optionsReducer,
  fullProductsReducer,
  productReducer,
  guideReducer,
  productsReducer,
  therapeuticsReducer,
  therapeuticPartsReducer,
  pharmaciesReducer,
  userReducer,
  supplierReducer,
  authReducer,
  requestsReducer,
  invitesReducer,
  withUser,
}
