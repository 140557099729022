import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { stableSort, getSorting, withData } from './common'

const rows = [
  { id: 'title', numeric: false, disablePadding: false, label: 'Fråga' },
  {
    id: 'avgCorrect',
    numeric: true,
    disablePadding: false,
    label: 'Andel rätt svar',
  },
]

class QuestionStatisticsTable extends Component {
  state = {
    order: 'desc',
    orderBy: 'avgCorrect',
  }

  handleRequestSort = property => () => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  render() {
    const { order, orderBy } = this.state
    const { data } = this.props
    return (
      <Table>
        <TableHead>
          <TableRow>
            {rows.map(row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'inherit'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sortera"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.handleRequestSort(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(data, getSorting(order, orderBy)).map(question => (
            <TableRow key={question.title}>
              <TableCell>{question.title}</TableCell>
              <TableCell
                align="right"
                style={{
                  color:
                    question.avgCorrect <= 0.25
                      ? 'rgb(255, 128, 66)'
                      : 'inherit',
                }}
              >
                {parseInt(question.avgCorrect * 100, 10)} %
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}
export default withData(QuestionStatisticsTable)
