import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Error from '@material-ui/icons/Error'
import database from '../../database'
import { timestampToDateString } from '../../utils'
import { withUser } from '../../actions'

class AnsweredRequests extends Component {
  state = {
    loading: true,
    requests: [],
  }

  componentDidMount() {
    const { user } = this.props
    let requestsQuery = null
    if (user.isPharmacyAdmin()) {
      const requestsCollection = user.pharmacy.collection('publicationRequests')
      requestsQuery = requestsCollection
        .where('internalApproval.approved', '==', true)
        .orderBy('pharmacyApproval.dateTime', 'desc')
      if (this.props.productUid) {
        requestsQuery = requestsQuery.where(
          'productUid',
          '==',
          this.props.productUid
        )
      }
      this.setState({
        pharmacyUid: user.pharmacy.id,
        approvalKey: 'pharmacyApproval',
      })
    } else if (user.isSuperAdmin()) {
      const requestsCollection = database
        .getDatabase()
        .collection('publicationRequests')
      requestsQuery = requestsCollection.orderBy(
        'internalApproval.dateTime',
        'desc'
      )
      if (this.props.productUid) {
        requestsQuery = requestsQuery.where(
          'productUid',
          '==',
          this.props.productUid
        )
      }
      this.setState({
        approvalKey: 'internalApproval',
      })
    }

    this.requestsQuery = requestsQuery.limit(7)
    this.fetchRequests(this.requestsQuery)
  }

  fetchRequests = query => {
    this.setState({ loading: true })
    query.get().then(requestsSnap => {
      const fetchedRequests = database.getCollectionDocsDataWithUid(
        requestsSnap
      )
      this.setState(state => {
        const requests = state.requests.concat(fetchedRequests)
        const lastVisible = requestsSnap.docs[requestsSnap.docs.length - 1]
        return {
          requests,
          lastVisible,
          loading: false,
          initialized: true,
        }
      })
    })
  }

  fetchMore = () => {
    const nextQuery = this.requestsQuery.startAfter(this.state.lastVisible)
    this.fetchRequests(nextQuery)
  }

  render() {
    const {
      requests,
      loading,
      approvalKey,
      pharmacyUid,
      initialized,
    } = this.state
    return (
      <div>
        <Card>
          <CardHeader title="Senast granskat" />
          <CardContent>
            <div>
              {!requests.length && initialized ? (
                <Typography>Det finns inget granskat</Typography>
              ) : (
                <List>
                  {requests
                    .filter(request => request[approvalKey].approved !== null)
                    .map(request => (
                      <Link
                        to={
                          approvalKey === 'pharmacyApproval'
                            ? `request/${request.uid}/${pharmacyUid}`
                            : `request-group/${request.uid}`
                        }
                        key={request.uid}
                      >
                        <ListItem className="productList">
                          <ListItemIcon>
                            {request[approvalKey].approved ? (
                              <CheckCircle />
                            ) : (
                              <Error />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={request.product.name}
                            secondary={
                              request[approvalKey].dateTime &&
                              timestampToDateString(
                                request[approvalKey].dateTime
                              )
                            }
                          />
                        </ListItem>
                        <li>
                          <Divider variant="inset" />
                        </li>
                      </Link>
                    ))}
                </List>
              )}
            </div>
            {loading && <LinearProgress />}
          </CardContent>
          <CardActions>
            <Button onClick={this.fetchMore} disabled={loading}>
              Visa fler
            </Button>
          </CardActions>
        </Card>
      </div>
    )
  }
}
export default withUser(AnsweredRequests)
