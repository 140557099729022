import React, { Fragment, Component } from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Prompt } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  withStyles,
  TableFooter,
} from '@material-ui/core'
import { compose } from 'redux'
import { Fields, FormSection, formValueSelector, reduxForm } from 'redux-form'
import { PRODUCT_TYPES } from '../constants'
import database from '../database'
import { FormTextField, FormSwitch } from './product-input/input-components'

const styles = theme => ({
  highlight: {
    backgroundColor: theme.palette.background.default,
  },
  numberInput: {
    fontSize: theme.typography.fontSize * 1.5,
    maxWidth: 100,
  },
})

const selector = formValueSelector('activation')

const toInteger = val => {
  const parsed = parseInt(val, 10)
  return Number.isNaN(parsed) ? 0 : parsed
}

const sumDistributed = products => {
  const sum = _.sumBy(_.toArray(products), product =>
    toInteger(product.activeEducationCount)
  )
  return sum
}
const ProductRow = withStyles(styles)(
  ({
    product,
    currentProduct,
    changeUndistributedCount,
    changeDistributedCount,
    unusedCount,
    classes,
    ...fields
  }) => {
    const { activeEducation, activeEducationCount } = fields[product.uid]
    const isCurrentProduct =
      currentProduct && currentProduct.uid === product.uid
    return (
      <TableRow
        key={product.uid}
        className={isCurrentProduct ? classes.highlight : ''}
      >
        <TableCell>
          <Typography
            variant="body1"
            color={
              activeEducation.input.value ? 'textPrimary' : 'textSecondary'
            }
          >
            {product.name} {product.type === PRODUCT_TYPES.SERIES && '(serie)'}
          </Typography>
        </TableCell>
        <TableCell colSpan={currentProduct && !isCurrentProduct ? 2 : 1}>
          <FormTextField
            {...activeEducationCount}
            type="number"
            min={0}
            step={1}
            InputProps={{ className: classes.numberInput }}
            // InputProps={{
            //   className: classes.numberInput,
            // }}
            // onChange={this.handleCountChange(product.uid)}
            onChange={event => {
              const value = toInteger(event.target.value)
              if (activeEducationCount.input.value <= 0 && value > 0) {
                activeEducation.input.onChange(true)
              } else if (activeEducationCount.input.value > 0 && value <= 0) {
                activeEducation.input.onChange(false)
              }
              activeEducationCount.input.onChange(value)
              changeDistributedCount((_, allValues) =>
                sumDistributed(allValues.products)
              )
              return value
            }}
          />
        </TableCell>
        {(!currentProduct || isCurrentProduct) && (
          <TableCell>
            <FormSwitch
              {...activeEducation}
              color="primary"
              disabled={
                activeEducationCount.input.value <= 0 ||
                activeEducationCount.meta.error
              }
            />
          </TableCell>
        )}
      </TableRow>
    )
  }
)

const FooterRow = connect(state =>
  selector(state, 'distributedCount', 'unusedCount')
)(({ distributedCount, unusedCount }) => {
  return (
    <Fragment>
      {/* <TableRow>
        <TableCell>
          <Typography variant="subtitle1">
            Oförbrukade kunskapstest
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">
            {unusedCount || 0}
          </Typography>
        </TableCell>
      </TableRow> */}
      <TableRow>
        <TableCell>
          <Typography variant="subtitle1">Totalt fördelade</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{distributedCount || 0}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography variant="subtitle1">Kvar att fördela</Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="subtitle1"
            color={unusedCount - distributedCount < 0 ? 'error' : 'primary'}
          >
            {unusedCount - distributedCount || 0}
          </Typography>
        </TableCell>
      </TableRow>
    </Fragment>
  )
})
class ProductActivation extends Component {
  state = {}

  componentDidMount() {
    const { products } = this.props
    this.setProductStates(products)
  }

  componentDidUpdate(prevProps) {
    const { products } = this.props
    if (prevProps.products !== products) {
      this.setProductStates(products)
    }
  }

  setProductStates = products => {
    const [[currentProduct], otherProducts] = _.partition(
      products,
      product => product.uid === this.props.productUid
    )
    this.setState({
      currentProduct,
      otherProducts,
    })
  }

  save = ({ products, unusedCount, distributedCount }) => {
    const { user } = this.props
    const batch = database.batch()
    Object.entries(products).forEach(([uid, values]) => {
      const productRef = database.queryProduct(uid)
      batch.update(productRef, {
        activeEducationCount: toInteger(values.activeEducationCount),
        activeEducation: Boolean(values.activeEducation),
      })
    })
    batch.update(user.supplier, {
      undistributedCertificates: unusedCount - distributedCount,
    })
    return batch.commit()
  }

  render() {
    const { currentProduct, otherProducts } = this.state
    const {
      invalid,
      pristine,
      handleSubmit,
      submitting,
      submitFailed,
      submitSucceeded,
      change,
      initialized,
      error,
    } = this.props
    const changeUndistributedCount = _.partial(change, 'undistributedCount')
    const changeDistributedCount = _.partial(change, 'distributedCount')
    return (
      <Fragment>
        <Prompt
          when={!pristine}
          message="Du har osparade ändringar. Är du säker att du vill lämna sidan? Ändringarna går förlorade."
        />
        <Typography variant="h4" color="primary">
          Fördela och aktivera kunskapstest
        </Typography>
        <Typography gutterBottom>
          Fördela dina oförbrukade kunskapstest på dina produktutbildningar. Du
          måste inte fördela alla, utan kan lämna ett antal ofördelade
          kunskapstest i en buffert som exempelvis kan fördelas när någon
          utbildning får slut på kunskapstest.
        </Typography>
        {/* <Typography gutterBottom>
          Aktivera eller avaktivera även utbildningar beroende på aktualitet för
          apotekspersonalen. En avaktiverad utbildning kan ha kunskapstest
          allokerade, men användarna kan inte genomföra kunskapstestet och
          därmed inte förbruka några.
        </Typography> */}
        <Grid container>
          <Grid item xs={12} md={10} lg={8}>
            <form onSubmit={handleSubmit(this.save)}>
              <Card>
                <CardHeader
                  title="Fördela kunskapstest"
                  action={
                    <Button
                      color="primary"
                      type="submit"
                      disabled={submitting || invalid || pristine}
                    >
                      Spara
                    </Button>
                  }
                />
                <CardContent>
                  {!otherProducts || !initialized ? (
                    <LinearProgress />
                  ) : (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant="body1">Produkt</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              Fördelade test
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">Aktiv</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <FormSection name="products" component={Fragment}>
                          {otherProducts &&
                            [currentProduct, ...otherProducts].map(product =>
                              !product ? null : (
                                <Fields
                                  key={product.uid}
                                  names={[
                                    `${product.uid}.activeEducationCount`,
                                    `${product.uid}.activeEducation`,
                                  ]}
                                  component={ProductRow}
                                  product={product}
                                  currentProduct={currentProduct}
                                  changeUndistributedCount={
                                    changeUndistributedCount
                                  }
                                  parse={(value, name) => {
                                    if (
                                      name ===
                                      `${product.uid}.activeEducationCount`
                                    ) {
                                      return toInteger(value)
                                    }
                                    return value
                                  }}
                                  changeDistributedCount={
                                    changeDistributedCount
                                  }
                                  validate={{
                                    [`${product.uid}.activeEducationCount`]: value =>
                                      Number.isNaN(value) || value < 0,
                                  }}
                                  warn={{
                                    [`${product.uid}.activeEducationCount`]: value =>
                                      value < 10,
                                  }}
                                />
                              )
                            )}
                        </FormSection>
                      </TableBody>
                      <TableFooter>
                        <FooterRow />
                      </TableFooter>
                    </Table>
                  )}
                  {submitting && <LinearProgress style={{ marginTop: 8 }} />}
                  {submitSucceeded && (
                    <Typography color="primary">
                      Dina ändringar har sparats
                    </Typography>
                  )}
                  {submitFailed && (
                    <Typography color="error">Kunde inte spara</Typography>
                  )}
                  {error && <Typography color="error">{error}</Typography>}
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitting || invalid || pristine}
                  >
                    Spara
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const products = state.products.list
  const formProducts = products.reduce(
    (acc, product) => ({
      ...acc,
      [product.uid]: {
        activeEducation: product.activeEducation,
        activeEducationCount: toInteger(product.activeEducationCount),
      },
    }),
    {}
  )
  const distributedCount = sumDistributed(formProducts)
  const undistributedCount = state.supplier
    ? toInteger(state.supplier.undistributedCertificates)
    : 0
  return {
    initialValues: {
      products: formProducts,
      distributedCount,
      undistributedCount,
      unusedCount: distributedCount + undistributedCount,
    },
    products,
    supplier: state.supplier,
    user: state.user,
    productsInit: state.products.initialized,
    ...selector(state, 'undistributedCount', 'distributedCount'),
  }
}

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps),
  reduxForm({
    form: 'activation',
    enableReinitialize: true,
    validate: ({ unusedCount, distributedCount }) =>
      unusedCount - distributedCount < 0 && {
        _error: 'För många test är fördelade',
      },
  })
)

export default enhance(ProductActivation)
