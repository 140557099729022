import React from 'react'
import ReactPlayer from 'react-player'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}

const Video = ({ video, classes }) => {
  const urls = [video.formattedUrl, video.url].filter(url => url)
  return (
    <div className={classes.playerWrapper}>
      <ReactPlayer
        url={urls}
        controls
        width="100%"
        height="100%"
        className={classes.reactPlayer}
      />
    </div>
  )
}
export default withStyles(styles)(Video)
