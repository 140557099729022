import React, { Fragment } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Accordion from '@material-ui/core/Accordion'
import AccordionActions from '@material-ui/core/AccordionActions'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/CheckCircleOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HourglassIcon from '@material-ui/icons/HourglassEmpty'
import { connect } from 'react-redux'
import _ from 'lodash'
import { LinearProgress } from '@material-ui/core'
import { timestampToDateString } from '../../utils'
import { functionsEurope } from '../../firebase'
import Paragraphs from '../Paragraphs'

const styles = theme => ({
  safe: {
    backgroundColor: theme.status.safe,
  },
  danger: {
    backgroundColor: theme.status.danger,
  },
  error: {
    backgroundColor: theme.status.error,
  },
  rowCell: {
    paddingRight: theme.spacing(2),
  },
  panelDetails: {
    display: 'block',
  },
})

const countIconStyles = { marginLeft: 12 }

const StatusIcon = ({ approved }) => {
  const style = { verticalAlign: 'middle', marginTop: -2 }
  if (approved) {
    return <CheckIcon style={style} color="primary" />
  }
  if (approved === false) {
    return <ErrorIcon style={style} color="error" />
  }
  return <HourglassIcon style={{ ...style, marginTop: 0 }} />
}

const cancelPublicationRequest = functionsEurope.httpsCallable(
  'qualityControl-cancelPublicationRequest'
)
// eslint-disable-next-line no-unused-vars
const cancelRequest = (pharmacyUid, requestUid) => {
  if (window.confirm('Vill du verkligen avbryta publiceringsförfrågan?')) {
    const requestData = {
      pharmacyUid,
      requestUid,
    }
    cancelPublicationRequest(requestData).then(console.log)
  }
}

function PublicationRequestPanel(props) {
  const [expanded, setExpanded] = React.useState(false)
  const { requestGroup, pharmacies, classes, onOpenHistory } = props
  if (pharmacies.length === 0) {
    return <LinearProgress />
  }
  const requestsWithControl = requestGroup.requests.filter(
    request =>
      !request.autoAccept ||
      pharmacies.find(pharmacy => pharmacy.uid === request.pharmacyUid).optIn
  )
  const groupedByApproval = _.groupBy(
    requestsWithControl,
    'pharmacyApproval.approved'
  )
  const {
    true: approved,
    false: disapproved,
    null: waiting,
  } = groupedByApproval
  const approvedCount = approved ? approved.length : 0
  const disapprovedCount = disapproved ? disapproved.length : 0
  const waitingCount = waiting ? waiting.length : 0
  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography>
              Granskning{' '}
              {!onOpenHistory &&
                timestampToDateString(requestGroup.creationDateTime)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {requestGroup.cancelled ? (
              <Typography>Avbruten</Typography>
            ) : (
              <Typography align="center">
                Apostar{' '}
                <StatusIcon approved={requestGroup.internalApproval.approved} />
              </Typography>
            )}
          </Grid>
          {requestGroup.internalApproval.approved && (
            <Grid item xs={5}>
              <Typography align="right">
                Apotek
                {approved && (
                  <span style={countIconStyles}>
                    {approvedCount}
                    <StatusIcon approved />
                  </span>
                )}
                {disapproved && (
                  <span style={countIconStyles}>
                    {disapprovedCount}
                    <StatusIcon approved={false} />
                  </span>
                )}
                {waiting && (
                  <span style={countIconStyles}>
                    {waitingCount}
                    <StatusIcon approved={null} />
                  </span>
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.panelDetails}>
        {requestGroup.internalApproval.approved === null && (
          <Typography variant="subtitle1">
            Inväntar granskning från Apostar
          </Typography>
        )}
        {requestGroup.internalApproval.approved === false && (
          <Typography variant="subtitle1">
            Apostar har begärt komplettering
          </Typography>
        )}
        {requestGroup.internalApproval.comment && (
          <Fragment>
            <Typography variant="body2" style={{ fontStyle: 'italic' }}>
              Kommentar från Apostar:
            </Typography>
            <Paragraphs>{requestGroup.internalApproval.comment}</Paragraphs>
          </Fragment>
        )}
        {requestGroup.internalApproval.approved && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Apotek/användargrupp</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Kommentar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requestsWithControl.map(request => {
                const { pharmacyApproval, pharmacyName, uid } = request
                return (
                  <TableRow key={uid}>
                    <TableCell>{pharmacyName}</TableCell>
                    <TableCell>
                      <StatusIcon approved={pharmacyApproval.approved} />
                      {/* pharmacyApproval.autoAccept && <span> auto</span> */}
                    </TableCell>
                    <TableCell>
                      <Paragraphs>{pharmacyApproval.comment}</Paragraphs>
                    </TableCell>
                    {/* <TableCell>{pharmacyApproval.approved === false && <Button>Komplettera</Button>}</TableCell> */}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </AccordionDetails>
      <AccordionActions>
        {/* Complete all if there are more than one pharmacy that denied? */}
        {/* <Button>Avbryt</Button> */}
        {/* {requestGroup.internalApproval.approved === false && (
            <Button color="primary">Komplettera</Button>
          )} */}
        {onOpenHistory && <Button onClick={onOpenHistory}>Historik</Button>}
        <Link to={`/request-group/${requestGroup.uid}`}>
          <Button>Visa granskningsärende</Button>
        </Link>
      </AccordionActions>
    </Accordion>
  )
}
export default connect(state => ({
  pharmacies: state.pharmacies,
}))(withStyles(styles)(PublicationRequestPanel))
