import React from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  Input,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import CopyIcon from '@material-ui/icons/FileCopy'
import { analytics } from '../firebase'

export default function DeepLinkButton({ productUid }) {
  const [open, setDialogOpen] = React.useState(false)
  const ref = React.createRef(null)
  const copyToClipboard = () => {
    if (ref.current) {
      ref.current.focus()
      ref.current.select()
      document.execCommand('copy')
      analytics.logEvent('product_share_copy', { productUid })
    }
  }
  return (
    <>
      <Button
        // className={classes.button}
        variant="contained"
        color="inherit"
        endIcon={<LinkIcon />}
        onClick={() => {
          setDialogOpen(true)
          analytics.logEvent('product_share_open', { productUid })
        }}
      >
        Dela
      </Button>
      <Dialog open={open} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Dela länk</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Länka direkt till produkten i appen genom dela länken nedan. Länken
            fungerar endast på mobilenheter.
          </DialogContentText>
          <FormControl fullWidth>
            <Input
              inputRef={ref}
              value={`apostar://product/${productUid}`}
              multiline
              onChange={() => {}}
              // style={{ display: 'block-inline', width: '100%' }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={copyToClipboard}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    <CopyIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Stäng</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
