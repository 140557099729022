import React from 'react'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/Help'
import { connect } from 'react-redux'
import { durationMinutesAndSeconds } from './common'

const toPercentage = number => {
  if (!Number.isFinite(number)) {
    return null
  }
  const color = number >= 0 ? 'green' : 'red'
  const sign = number >= 0 ? '+' : ''
  const roundedPercentage = (number * 100).toFixed(0)
  return (
    <span style={{ color }}>
      {sign}
      {roundedPercentage}%
    </span>
  )
}

const styles = theme => ({
  row: {
    height: 30,
  },
  headerCell: {
    fontSize: 1.2 * theme.typography.fontSize,
    borderBottom: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('lg')]: {
      fontSize: 1 * theme.typography.fontSize,
      paddingRight: theme.spacing(2),
    },
  },
  bigNumber: {
    fontSize: 2 * theme.typography.fontSize,
    [theme.breakpoints.down('lg')]: {
      fontSize: 1.5 * theme.typography.fontSize,
      paddingRight: theme.spacing(2),
    },
    borderBottom: 0,
    paddingBottom: 0,
  },
  percentage: {
    fontSize: 1 * theme.typography.fontSize,
    [theme.breakpoints.down('lg')]: {
      fontSize: 1.5 * theme.typography.fontSize,
      paddingRight: theme.spacing(2),
    },
    borderBottom: 0,
    paddingTop: 0,
  },
})
function LineChartTotals({
  data: { totals, deltas },
  avgReadTime,
  distributedCount,
  undistributedCount,
  classes,
  supplier,
}) {
  console.log(totals)
  return (
    <Table>
      <TableHead>
        <TableRow className={classes.row}>
          <TableCell align="center" className={classes.headerCell}>
            Klick
          </TableCell>
          {avgReadTime && (
            <TableCell align="center" className={classes.headerCell}>
              Lästid / Klick{' '}
              <Tooltip title="Baserad på data från 25 september 2019 och framåt">
                <span style={{ verticalAlign: 'middle' }}>
                  <HelpIcon />
                </span>
              </Tooltip>
            </TableCell>
          )}
          <TableCell align="center" className={classes.headerCell}>
            Godkända test
          </TableCell>
          <TableCell align="center" className={classes.headerCell}>
            Underkända test
          </TableCell>

          {supplier && supplier.legacyPricing && (
            <TableCell align="center" className={classes.headerCell}>
              Kvarstående test
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.row}>
          <TableCell align="center" className={classes.bigNumber}>
            {totals.clicks}
          </TableCell>
          {avgReadTime && (
            <TableCell align="center" className={classes.bigNumber}>
              {durationMinutesAndSeconds(avgReadTime)}
            </TableCell>
          )}
          <TableCell align="center" className={classes.bigNumber}>
            {totals.passed}
          </TableCell>
          <TableCell align="center" className={classes.bigNumber}>
            {totals.failed}
          </TableCell>
          {supplier && supplier.legacyPricing && (
            <TableCell align="center" className={classes.bigNumber}>
              <Tooltip
                title={
                  undistributedCount
                    ? `Varav ${undistributedCount} är ofördelade`
                    : ''
                }
              >
                <span>{Math.ceil(undistributedCount + distributedCount)}</span>
              </Tooltip>
            </TableCell>
          )}
        </TableRow>
        {deltas && (
          <TableRow className={classes.row}>
            <TableCell align="center" className={classes.percentage}>
              {toPercentage(deltas.clicks)}
            </TableCell>
            {avgReadTime && (
              <TableCell align="center" className={classes.percentage} />
            )}
            <TableCell align="center" className={classes.percentage}>
              {toPercentage(deltas.passed)}
            </TableCell>
            <TableCell align="center" className={classes.percentage}>
              {toPercentage(deltas.failed)}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
export default connect(state => ({
  supplier: state.supplier,
}))(withStyles(styles)(LineChartTotals))
