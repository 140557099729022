import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { LinearProgress } from '@material-ui/core'

export default function ConfirmDialog(props) {
  const {
    open,
    text,
    title,
    loading,
    onCancel,
    onConfirm,
    confirmColor = 'primary',
    confirmText = 'Fortsätt',
  } = props

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
        {loading && <LinearProgress />}
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          Avbryt
        </Button>
        <Button
          onClick={onConfirm}
          disabled={loading}
          color={confirmColor}
          autoFocus
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
