import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { formValueSelector, Field } from 'redux-form'
import {
  FormTextField,
  FormSelect,
  FormImageUpload,
  FormVideoUpload,
} from './input-components'
import QueryTagsInput from './QueryTagsInput'
import { CategoryGroup } from '../../constants'

const styles = theme => ({
  textField: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
})

const educationTypes = [
  {
    name: 'Sjukvård',
    value: CategoryGroup.HEALTH_CARE,
  },
  {
    name: 'Egenvård',
    value: CategoryGroup.SELF_CARE,
  },
]

function TherapeuticInput(props) {
  const { classes, categories, storagePath, educationType } = props
  return (
    <Grid container spacing={3}>
      <Grid item xl={7} xs={12}>
        <Field
          name="name"
          label="Namn"
          required
          autoFocus
          component={FormTextField}
          className={classes.textField}
        />
        <div className={classes.textField}>
          <Field
            name="educationType"
            label="Utbildningstyp"
            field="educationType"
            options={educationTypes}
            component={FormSelect}
          />
        </div>
        {categories && educationType && (
          <div className={classes.textField}>
            <Field
              name="categoryUid"
              label="Kategorier"
              field="categories"
              options={categories}
              component={FormSelect}
            />
          </div>
        )}
        <QueryTagsInput />
      </Grid>

      <Grid item xs={12} md={6}>
        <Field
          name="image"
          storagePath={`${storagePath}/images`}
          component={FormImageUpload}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="video"
          component={FormVideoUpload}
          storagePath={storagePath}
        />
      </Grid>
    </Grid>
  )
}
const formSelector = formValueSelector('therapeutic')

const enhance = compose(
  withStyles(styles),
  connect(state => {
    const { categories } = state.options
    const educationType = formSelector(state, 'educationType')
    return {
      categories: categories.byGroup[educationType],
      educationType,
    }
  })
)
export default enhance(TherapeuticInput)
