/* eslint-disable no-unused-vars */
import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import LinearProgress from '@material-ui/core/LinearProgress'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import NotificationImportant from '@material-ui/icons/NotificationImportant'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { connect } from 'react-redux'
import { loadEvents, markEventComplete } from '../../events'
import EventList from '../EventList'

function ActionableEvents(props) {
  const [confirmOpen, setOpen] = React.useState(false)
  const [event, setEvent] = React.useState('')
  const {
    events,
    loading,
    loadEvents,
    markEventComplete,
    initialized,
    allEventsLoaded,
  } = props
  const openConfirmation = (event, index) => {
    setEvent({
      ...event,
      index,
    })
    setOpen(true)
  }
  const closeDialog = () => setOpen(false)
  const confirmDismiss = () => {
    closeDialog()
    markEventComplete(event.uid, event.index)
  }
  return (
    <Card>
      <CardHeader avatar={<NotificationImportant />} title="Att åtgärda" />
      <CardContent>
        {loading && <LinearProgress />}
        {initialized && <EventList events={events} />}
      </CardContent>
      <CardActions>
        {events.length % 7 === 0 && events.length > 0 && !allEventsLoaded && (
          <Button onClick={() => loadEvents('actionable')} disabled={loading}>
            Visa fler <ExpandMore />
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

export default connect(
  state => ({
    events: state.events.actionable.list,
    loading: state.events.actionable.loading,
    initialized: state.events.actionable.initialized,
    allEventsLoaded: state.events.actionable.allEventsLoaded,
  }),
  { loadEvents, markEventComplete }
)(ActionableEvents)
