import React, { Component, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { Redirect, Switch, withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { compose } from 'redux'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import ProductStatistics from './ProductStatistics'
import StatisticsOverview from './StatisticsOverview'
import PrivateRoute from '../../PrivateRoute'
import { updateDateRange } from './actions'
import { defaultDateRange, dateFormat, styles } from './common'
import database from '../../database'
import SimpleSelect from '../SimpleSelect'

import 'moment/locale/sv'

moment.locale('sv')

class Statistics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateRangeInput: props.dateRange || defaultDateRange,
      supplierUid: '',
    }
    this.minDate = '2019-02-01'
    this.maxDate = moment().format()
    if (!props.dateRange) {
      props.updateDateRange(defaultDateRange)
    }
  }

  componentDidMount() {
    const { user } = this.props
    if (user.isSuperAdmin()) {
      database
        .getDatabase()
        .collection('suppliers')
        .orderBy('name')
        .get()
        .then(suppliersSnap => {
          const suppliers = suppliersSnap.docs.map(supplierSnap => ({
            value: supplierSnap.id,
            ...supplierSnap.data(),
          }))
          const suppliersAndAll = [
            {
              value: '',
              name: 'Alla',
            },
            ...suppliers,
          ]
          this.setState({ suppliers: suppliersAndAll })
        })
    }
  }

  handleDateChange = field => date => {
    const dateRange = {
      ...this.props.dateRange,
      [field]: date.format(dateFormat),
    }
    this.props.updateDateRange(dateRange)
  }

  render() {
    const { dateRangeInput, suppliers, supplierUid } = this.state
    const {
      loading,
      error,
      match: { path },
      classes,
      location,
      user,
      dateRange,
    } = this.props
    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container className={classes.section}>
            <Grid item xs={12} lg={6}>
              <Typography color="primary" variant="h4">
                <span>Statistik </span>
                {!location.pathname.endsWith('overview') && (
                  <Button component={Link} to={`${path}/overview`}>
                    <ArrowBackIcon />
                    Översikt
                  </Button>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <DatePicker
                style={{ float: 'right' }}
                variant="inline"
                value={dateRange.to}
                label="Till"
                minDate={this.minDate}
                maxDate={this.maxDate}
                onChange={this.handleDateChange('to')}
                autoOk
                error={dateRangeInput.error}
                // format={dateFormat}
              />
              <DatePicker
                style={{ float: 'right' }}
                variant="inline"
                value={dateRange.from}
                label="Från"
                minDate={this.minDate}
                maxDate={this.maxDate}
                onChange={this.handleDateChange('from')}
                autoOk
                error={dateRangeInput.error}
                // format={dateFormat}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        {loading && <LinearProgress />}
        {error && (
          <Fragment>
            <Typography color="error">Kunde inte ladda statistik</Typography>
            <Typography>
              {error.code} {error.message} {error.details}
            </Typography>
          </Fragment>
        )}
        {user.uid && user.isSuperAdmin() && suppliers && (
          <SimpleSelect
            selected={supplierUid}
            options={suppliers}
            onSelect={supplierUid => this.setState({ supplierUid })}
          />
        )}
        <Switch>
          <Redirect exact from="/statistics" to="/statistics/overview" />
          <PrivateRoute
            exact
            path={`${path}/overview`}
            render={() => <StatisticsOverview supplierUid={supplierUid} />}
            title="Statistik översikt"
          />
          <PrivateRoute
            path={`${path}/product/:productUid`}
            render={({ match }) => (
              <ProductStatistics productUid={match.params.productUid} />
            )}
            title="Statistik produkt"
          />
        </Switch>
      </Fragment>
    )
  }
}

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(
    state => ({
      dateRange: state.statistics.dateRange,
      loading: state.statistics.loading,
      error: state.statistics.error,
      user: state.user,
    }),
    { updateDateRange }
  )
)
export default enhance(Statistics)
