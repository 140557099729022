import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { Field, reduxForm, formValues, change } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { CardContent, Typography } from '@material-ui/core'
import { CategoryGroup } from '../../constants'
import { FormTextField, FormSelect } from './input-components'
import ProductInputCard from './ProductInputCard'
import QueryTagsInput from './QueryTagsInput'
import ErrorDialog from './ErrorDialog'
import {
  required,
  calcProgress,
  isPharmaceuticalClassification,
  defaultSections,
} from './common'
import database from '../../database'
import { serverTimestamp } from '../../firebase'

const styles = theme => ({
  textField: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
})

function ProductBaseInput(props) {
  const {
    handleSubmit,
    submitting,
    submitFailed,
    classes,
    categories,
    classifications,
    pristine,
    valid,
    match: {
      params: { productUid },
    },
    history,
    classificationUid,
  } = props
  const save = (values, errors) => {
    const progress = valid ? 100 : calcProgress('base', errors)
    const productRef = database.queryProduct(productUid)
    return productRef
      .update({
        ...values,
        editDateTime: serverTimestamp(),
        'completion.base': progress,
      })
      .then(() => {
        history.push(`/product/${productUid}/create/media`)
      })
  }
  const showMinimiText = isPharmaceuticalClassification(
    classifications,
    classificationUid
  )
  const classification = classifications.find(
    classification => classification.value === classificationUid
  )
  const isSupplement = classification && classification.name === 'Kosttillskott'

  return (
    <form onSubmit={handleSubmit(save)}>
      <ProductInputCard
        title="Produktinformation"
        validate={errors =>
          !(
            errors.name ||
            errors.classificationUid ||
            errors.categoryUid ||
            errors.eanNumber
          )
        }
      >
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Field
                name="name"
                label="Produktnamn"
                validate={required}
                autoFocus
                component={FormTextField}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              {classifications && (
                <div className={classes.textField}>
                  <Field
                    name="classificationUid"
                    label="Klassificering"
                    field="classification"
                    options={classifications}
                    component={FormSelect}
                    variant="outlined"
                    validate={required}
                    fullWidth
                  />
                </div>
              )}
            </Grid>
            <Grid item md={6}>
              {categories && (
                <div className={classes.textField}>
                  <Field
                    name="categoryUid"
                    label="Kategorier"
                    field="categories"
                    options={categories}
                    component={FormSelect}
                    variant="outlined"
                    validate={required}
                    fullWidth
                  />
                </div>
              )}
            </Grid>
            <Grid item md={6}>
              <Field
                name="eanNumber"
                label="EAN-nummer"
                field="eanNumber"
                component={FormTextField}
                className={classes.textField}
                variant="outlined"
                validate={required}
                fullWidth
              />
            </Grid>
          </Grid>
          {isSupplement && (
            <Typography>
              Obs! Använd endast godkända påståenden från EFSA eller
              livsmedelsverket
            </Typography>
          )}
        </CardContent>
      </ProductInputCard>
      <ProductInputCard title="Taggar" optional>
        <CardContent>
          <QueryTagsInput />
        </CardContent>
      </ProductInputCard>
      {showMinimiText && (
        <ProductInputCard
          title="Plikttext"
          validate={errors => !errors.minimiText}
        >
          <CardContent>
            <Field
              name="minimiText"
              component={FormTextField}
              // InputProps={{ style: { border: '1px solid #efefef' } }}
              multiline
              rows={3}
              rowsMax={20}
              fullWidth
              spellCheck
              validate={(value, allValues) =>
                isPharmaceuticalClassification(
                  classifications,
                  allValues.classificationUid
                )
                  ? required(value)
                  : undefined
              }
              helperText="Även kallat minimitext"
            />
          </CardContent>
        </ProductInputCard>
      )}
      <Button
        type="submit"
        disabled={submitting || pristine}
        variant="contained"
        color="primary"
      >
        Spara och gå vidare
      </Button>
      {submitFailed && (
        <ErrorDialog
          submitting={submitting}
          submitFailed={submitFailed}
          description="utbildningsinnehållet"
          onSave={save}
        />
      )}
    </form>
  )
}

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(({ options }) => ({
    classifications: options.classifications.list,
    categories: options.categories.byGroup[CategoryGroup.SELF_CARE] || [],
  })),
  reduxForm({
    form: 'base',
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
    onChange: (values, dispatch, props, prevValues) => {
      if (values.classificationUid !== prevValues.classificationUid) {
        const useIndication = isPharmaceuticalClassification(
          props.classifications,
          values.classificationUid
        )
        const sectionIndex = defaultSections.findIndex(
          section =>
            section.title === 'Indikation' || section.title === 'Målgrupp'
        )
        const newTitle = useIndication ? 'Indikation' : 'Målgrupp'
        dispatch(change('content', `sections[${sectionIndex}].title`, newTitle))
      }
    },
  }),
  formValues('classificationUid')
)

export default enhance(ProductBaseInput)
