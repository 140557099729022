import React from 'react'
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer'
import LineChart from 'recharts/lib/chart/LineChart'
import Line from 'recharts/lib/cartesian/Line'
import XAxis from 'recharts/lib/cartesian/XAxis'
import YAxis from 'recharts/lib/cartesian/YAxis'
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid'
import Tooltip from 'recharts/lib/component/Tooltip'
import Legend from 'recharts/lib/component/Legend'
import { withTheme } from '@material-ui/core/styles'

const margin = {
  left: -20,
  top: 5,
  bottom: 5,
  right: 5,
}
function SimpleLineChart({ data, theme, className }) {
  return (
    // 99% per https://github.com/recharts/recharts/issues/172
    <ResponsiveContainer width="99%" height={320} className={className}>
      <LineChart data={data} margin={margin}>
        <XAxis dataKey="weekLabel" />
        <YAxis allowDecimals={false} />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          name="Godkända kunskapstest"
          dataKey="passedCount"
          strokeWidth={3}
          stroke={theme.stats.green}
        />
        <Line
          type="monotone"
          name="Underkända kunskapstest"
          dataKey="failedCount"
          strokeWidth={3}
          stroke={theme.stats.orange}
        />
        {
          // <Line type="monotone" name="Påbörjade kunskapstest" dataKey="startedCount" strokeWidth={3} stroke={theme.stats.yellow} />
        }
        <Line
          type="monotone"
          name="Klick"
          dataKey="clickCount"
          strokeWidth={3}
          stroke={theme.stats.blue}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default withTheme(SimpleLineChart)
