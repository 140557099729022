import React, { Fragment, Component } from 'react'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import ProductInformation from '../ProductInformation'

class RequestProductInformation extends Component {
  state = {
    showChangesOnly: true,
  }

  toggleShowChangesOnly = event => {
    this.setState({ showChangesOnly: event.target.checked })
  }

  render() {
    const { request } = this.props
    const { showChangesOnly } = this.state
    return (
      <Fragment>
        {request.diff && (
          <Fragment>
            {/* insert previous comment if denied? */}
            <Typography variant="h6">Komplettering</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showChangesOnly}
                  onChange={this.toggleShowChangesOnly}
                  value="showChangesOnly"
                  color="primary"
                />
              }
              label="Visa bara ändringar"
            />
          </Fragment>
        )}
        {request.product ? (
          <ProductInformation
            product={request.product}
            diffs={request.diff}
            showChangesOnly={showChangesOnly && Boolean(request.diff)}
          />
        ) : (
          <LinearProgress />
        )}
      </Fragment>
    )
  }
}
export default RequestProductInformation
