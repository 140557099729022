import React, { Fragment } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'
import Quiz from './Quiz'
import ProductSections from './ProductSections'
import Image from './Image'
import Video from './Video'
import { diffByProp, findDiffForProp } from '../diff'

function ProductInformation(props) {
  const { product, categories, classifications, diffs, showChangesOnly } = props
  const { classificationUid, categoryUid } = product
  const categoryObj = categories.byKey[categoryUid]
  const category = categoryObj ? categoryObj.name : 'Kategori saknas'
  const classificationObj = classifications.byKey[classificationUid]
  const classification = classificationObj
    ? classificationObj.name
    : 'Klassificering saknas'
  const diffBySection = diffByProp(diffs, 'sections')
  const diffByQuestion = diffByProp(diffs, 'quiz')
  const minimiTextDiff = findDiffForProp(diffs, 'minimiText', 0)
  const videoDiff = findDiffForProp(diffs, 'video', 0)
  return (
    <div>
      <Typography color="primary" variant="h4">
        {product.name}
      </Typography>
      <Typography gutterBottom>{product.supplierName}</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {product.image ? <Image image={product.image} /> : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Kategori</Typography>
          <Typography variant="subtitle1">{category}</Typography>
          <Typography variant="h6">Klassificering</Typography>
          <Typography variant="subtitle1">{classification}</Typography>
          <Typography variant="h6">Taggar</Typography>
          {product.tags && product.tags.length > 0 ? (
            <Typography variant="subtitle1">
              {product.tags.map(tag => tag.trim()).join(', ')}
            </Typography>
          ) : (
            <Typography variant="subtitle1">Inga taggar</Typography>
          )}
          <Typography variant="h6">Extramaterial</Typography>
          {product.attachments && product.attachments.length > 0 ? (
            product.attachments.map(attachment => (
              <div key={attachment.name}>
                <Link
                  download={attachment.originalName}
                  target="_blank"
                  rel="noopener"
                  href={attachment.url}
                >
                  {attachment.originalName}
                </Link>
              </div>
            ))
          ) : (
            <Typography variant="subtitle1">Inget extramaterial</Typography>
          )}
          {product.seriesUid && (
            <Link component={RouterLink} to={`/products/${product.seriesUid}`}>
              Del av serie
            </Link>
          )}
        </Grid>
        {product.video && (!showChangesOnly || videoDiff) && (
          <Grid item xs={12}>
            <Typography variant="h6">Video</Typography>
            <Video video={product.video} />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} style={{ padding: 10 }}>
          <ProductSections
            sections={product.sections}
            diffs={diffBySection}
            showChangesOnly={showChangesOnly}
          />
          {product.minimiText && (!showChangesOnly || minimiTextDiff) && (
            <Fragment>
              <Typography variant="h6">Plikttext</Typography>
              {minimiTextDiff && (
                <Typography
                  variant="caption"
                  style={{ textDecoration: 'line-through' }}
                >
                  {minimiTextDiff.lhs}
                </Typography>
              )}
              <Typography gutterBottom variant="caption">
                {product.minimiText}
              </Typography>
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 10 }}>
          {product.quiz ? (
            <Quiz
              quiz={product.quiz}
              diffs={diffByQuestion}
              showChangesOnly={showChangesOnly}
            />
          ) : (
            <LinearProgress />
          )}
        </Grid>
      </Grid>
    </div>
  )
}
export default connect(state => ({
  classifications: state.options.classifications,
  categories: state.options.categories,
}))(ProductInformation)
