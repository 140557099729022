import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Button,
  ListItemIcon,
} from '@material-ui/core'
import { reduxForm, FieldArray } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ShortText, PostAdd, InsertPhoto } from '@material-ui/icons'
import ProductSectionInput from './ProductSectionInput'
import database from '../../database'
import ErrorDialog from './ErrorDialog'
import {
  defaultSections,
  batchUpdateOrderedCollection,
  calcProgress,
  getGuidePart,
} from './common'
import { serverTimestamp } from '../../firebase'
import ConfirmDialog from '../ConfirmDialog'
import ProductSections from '../ProductSections'
import { closeGuide } from '../../actions'

const emptySection = {
  title: '',
  text: '',
  required: false,
}

const getSectionsRef = productUid =>
  database.queryProduct(productUid).collection('sections')

const renderSections = withRouter(
  ({
    match: {
      params: { productUid },
    },
    fields,
    forcePush,
  }) => {
    const [loading, setLoading] = React.useState(false)
    const sectionsRef = getSectionsRef(productUid)
    const addSection = () => {
      setLoading(true)
      const newSection = {
        index: fields.length,
        creationDateTime: serverTimestamp(),
        ...emptySection,
      }
      return sectionsRef.add(newSection).then(sectionRef => {
        if (forcePush) {
          console.log('form is dirty. Push section directly')
          fields.push({ uid: sectionRef.id, ...newSection })
        }
        setLoading(false)
      })
    }
    const [confirmDialog, setConfirmDialog] = React.useState({
      open: false,
      index: -1,
    })
    const removeSection = index => {
      const section = fields.get(index)
      setLoading(true)
      fields.remove(index)
      return sectionsRef
        .doc(section.uid)
        .delete()
        .then(() => {
          setConfirmDialog({ open: false })
          setLoading(false)
        })
    }
    return (
      <>
        <ConfirmDialog
          open={confirmDialog.open}
          loading={loading}
          title="Ta bort rubrik?"
          text="Är du säker på att du vill ta bort rubriken? Det går inte att ångra"
          onConfirm={() => removeSection(confirmDialog.index)}
          onCancel={() => setConfirmDialog({ open: false })}
        />
        {fields.map((section, index) => (
          <ProductSectionInput
            id={`section-${index}`}
            key={section}
            fieldname={section}
            onRemove={() => setConfirmDialog({ open: true, index })}
            onMove={offset => fields.swap(index + offset, index)}
            isLast={fields.length - 1 === index}
            isFirstCustom={defaultSections.length === index}
            index={index}
          />
        ))}
        <Button disabled={loading} variant="contained" onClick={addSection}>
          Lägg till rubrik
        </Button>
      </>
    )
  }
)
const ContentGuideDialog = connect(
  state => ({
    exampleProduct: state.guide.exampleProduct,
    contentGuide: getGuidePart(state, 'content'),
  }),
  { closeGuide }
)(props => {
  const { exampleProduct, contentGuide, closeGuide } = props
  return (
    <Dialog
      onClose={() => closeGuide('content')}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={contentGuide}
    >
      <DialogTitle id="alert-dialog-title">
        Guide utbildningsinnehåll
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ha följande tumregler i åtanke när du utformar utbildningsinnehållet:
        </DialogContentText>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <ShortText />
            </ListItemIcon>
            <ListItemText>Håll det kort och koncict</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InsertPhoto />
            </ListItemIcon>
            <ListItemText>Använd gärna mycket bilder</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText>
              Lägg till egna rubriker i slutet för anpassa utbildningen eller
              uppmärksamma andra produkter i samma serie
            </ListItemText>
          </ListItem>
        </List>
        <DialogContentText>
          Nedan ser du ett exempel på utbildningsinnehåll till{' '}
          {exampleProduct.name}
        </DialogContentText>
        <ProductSections sections={exampleProduct.sections} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeGuide('content')}>Stäng</Button>
      </DialogActions>
    </Dialog>
  )
})

function ProductSectionsInput(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    submitFailed,
    match: {
      params: { productUid },
    },
    history,
    valid,
    dirty,
  } = props

  const save = ({ sections }, errors) => {
    const progress = valid ? 100 : calcProgress('content', errors, { sections })
    const batch = database.batch()
    batch.update(database.queryProduct(productUid), {
      'completion.content': Math.ceil(progress),
      editDateTime: serverTimestamp(),
    })
    const sectionsRef = getSectionsRef(productUid)
    return batchUpdateOrderedCollection(sectionsRef, sections, batch)
      .commit()
      .then(() => {
        history.push(`/product/${productUid}/create/quiz`)
        window.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
        })
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit(save)}>
        <FieldArray
          name="sections"
          component={renderSections}
          forcePush={dirty}
        />
        <Button
          disabled={pristine || submitting}
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginLeft: 16 }}
        >
          Spara och fortsätt
        </Button>
        {submitFailed && (
          <ErrorDialog
            submitting={submitting}
            submitFailed={submitFailed}
            description="utbildningsinnehållet"
            onSave={save}
          />
        )}
      </form>
      <ContentGuideDialog />
    </>
  )
}

const enhance = compose(
  withRouter,
  connect(
    state => ({
      guide: state.guide,
      user: state.user,
    }),
    { closeGuide }
  ),
  reduxForm({
    form: 'content',
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
  })
)
export default enhance(ProductSectionsInput)
