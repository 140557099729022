import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { functionsEurope, serverTimestamp } from '../../firebase'

import database from '../../database'
import RequestResponseInput from './RequestResponseInput'
import Discussion from './Discussion'
import ApprovalInfo from './ApprovalInfo'
import RequestProductInformation from './RequestProductInformation'

const isRequestApprovedForUser = (request, user) => {
  if (user.isPharmacyAdmin()) {
    return request.pharmacyApproval ? request.pharmacyApproval.approved : null
  }
  if (user.isSuperAdmin()) {
    return request.internalApproval ? request.internalApproval.approved : null
  }
  return null
}

class Request extends Component {
  constructor(props) {
    super(props)
    this.state = {
      request: {},
    }
    this.accept = this.accept.bind(this)
    this.deny = this.deny.bind(this)
  }

  componentDidMount() {
    const { user } = this.props
    if (user.isPharmacyAdmin()) {
      this.pharmacyRef = user.pharmacy
    } else if (user.isSuperAdmin()) {
      this.pharmacyRef = database
        .getDatabase()
        .collection('pharmacies')
        .doc(this.props.pharmacyUid)
    }
    this.requestRef = this.pharmacyRef
      .collection('publicationRequests')
      .doc(this.props.requestId)

    database.getRequestWithFullProduct(this.requestRef).then(request => {
      this.setState({
        request,
        loading: false,
      })
    })
  }

  accept(comment) {
    const { user } = this.props
    this.setState({ sending: true })
    return new Promise((resolve, reject) => {
      if (user.isSuperAdmin()) {
        this.requestRef
          .update({
            internalApproval: {
              approved: true,
              timeout: false,
              dateTime: serverTimestamp(),
              comment: comment || '',
            },
          })
          .then(resolve)
      } else if (user.isPharmacyAdmin()) {
        const requestData = {
          pharmacyUid: user.pharmacy.id,
          requestUid: this.requestRef.id,
        }
        if (comment) {
          requestData.comment = comment
        }
        const approvePublicationRequest = functionsEurope.httpsCallable(
          'qualityControl-approvePublicationRequest'
        )
        approvePublicationRequest(requestData).then(resolve)
      } else {
        reject(new Error('Invalid user'))
      }
    }).then(() => {
      this.setState({ sending: false })
      this.props.history.push('/')
    })
  }

  deny(comment) {
    const { user } = this.props
    let approvalObjectKey = ''
    if (user.isSuperAdmin()) {
      approvalObjectKey = 'internalApproval'
    } else if (user.isPharmacyAdmin()) {
      approvalObjectKey = 'pharmacyApproval'
    }
    this.requestRef
      .update({
        [approvalObjectKey]: {
          approved: false,
          timeout: false,
          dateTime: serverTimestamp(),
          userUid: user.uid,
          comment,
        },
      })
      .then(this.props.history.push('/'))
  }

  render() {
    const { request, sending, error } = this.state
    const { user } = this.props
    const approved = isRequestApprovedForUser(request, user)
    return (
      <div>
        <Typography variant="h5">Kvalitetsgranskning</Typography>
        {request.pharmacyApproval && (
          <ApprovalInfo approval={request.pharmacyApproval} />
        )}
        {approved !== null && (
          <Discussion
            onAccept={this.accept}
            requestRef={this.requestRef}
            approved={approved}
          />
        )}
        <RequestProductInformation request={request} />
        {approved === null && (
          <RequestResponseInput
            onAccept={this.accept}
            onDeny={this.deny}
            sending={sending}
            disabled={approved !== null}
            showComment={approved === null}
          />
        )}
        {error && <Typography color="error">{error}</Typography>}
      </div>
    )
  }
}
const enhance = compose(
  connect(state => ({
    user: state.user,
  })),
  withRouter
)
export default enhance(Request)
