import React from 'react'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import { createEmptyProduct } from './product-input/common'
import { withUser } from '../actions'
import { analytics } from '../firebase'

function AddProductButton({
  history,
  variant = 'contained',
  color = 'primary',
  startIcon = null,
  style = {},
  user,
}) {
  const [isLoading, setLoading] = React.useState(false)
  return (
    <Button
      // component={Link}
      // to="/product-add"
      variant={variant}
      color={color}
      disabled={isLoading}
      startIcon={startIcon}
      style={style}
      onClick={() => {
        setLoading(true)
        createEmptyProduct(user).then(productUid => {
          analytics.logEvent('product_create', { productUid })
          history.push(`/product/${productUid}/create/base`)
        })
      }}
    >
      Lägg till produkt
    </Button>
  )
}
export default withUser(withRouter(AddProductButton))
