import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { Field, FieldArray, formValues } from 'redux-form'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import RemoveCircle from '@material-ui/icons/RemoveCircleOutline'
import AddCircle from '@material-ui/icons/AddCircleOutline'
import InsertLink from '@material-ui/icons/InsertLink'
import ArrowUp from '@material-ui/icons/ArrowUpward'
import ArrowDown from '@material-ui/icons/ArrowDownward'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import {
  FormRemovableImage,
  FormImageUpload,
  FormTextField,
  FormCheckbox,
} from './input-components'
import { required, sectionGuides, isUrl } from './common'
import ProductInputCard from './ProductInputCard'
import HelpPopover from '../HelpPopover'
import ProductSectionContent from '../ProductSectionContent'

const renderTextList = ({ fields, meta: { error, dirty }, placeholder }) => (
  <div>
    {fields.map((listItem, index) => {
      // const isLastItem = index === sectionData.list.length - 1
      return (
        <Grid container key={listItem} spacing={3} alignItems="center">
          <Grid item xs={11}>
            <Field
              key={index}
              name={listItem}
              fullWidth
              // helperText={isLastItem ? helperText : ""}
              component={FormTextField}
              variant="outlined"
              placeholder={placeholder || ''}
              multiline
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton edge="start" onClick={() => fields.remove(index)}>
              <RemoveCircle />
            </IconButton>
          </Grid>
        </Grid>
      )
    })}
    {fields.length < 5 && (
      <Button startIcon={<AddCircle />} onClick={() => fields.push('')}>
        Säljargument
      </Button>
    )}
    {dirty && error && <Typography color="error">{error}</Typography>}
  </div>
)

const renderLinkList = ({ fields, disabled }) => (
  <div style={{ paddingTop: 8 }}>
    {fields.map((listItem, index) => {
      return (
        <Grid container key={listItem} spacing={3} justify="space-between">
          <Grid item xs={6}>
            <Field
              key={`${index}-url`}
              name={`${listItem}.url`}
              helperText="URL: tex https://www.fass.se"
              component={FormTextField}
              style={{ marginRight: 8 }}
              validate={[required, isUrl]}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <Field
              key={index}
              name={`${listItem}.label`}
              helperText="Länktext: tex Fass"
              component={FormTextField}
              validate={required}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton edge="start" onClick={() => fields.remove(index)}>
              <RemoveCircle />
            </IconButton>
          </Grid>
        </Grid>
      )
    })}
    <Button
      onClick={() => fields.push({ url: '', name: '' })}
      startIcon={<InsertLink />}
      disabled={Boolean(disabled)}
    >
      Länk
    </Button>
  </div>
)

function ProductSectionInput(props) {
  const {
    title,
    list,
    image,
    requiredSection,
    irrelevant,
    fieldname,
    onMove,
    onRemove,
    isFirstCustom,
    isLast,
    index,
    exampleProduct,
    match: {
      params: { productUid },
    },
  } = props

  return (
    <ProductInputCard
      title={
        requiredSection ? (
          title
        ) : (
          <Field
            name={`${fieldname}.title`}
            label="Rubrik"
            component={FormTextField}
            fullWidth
            style={{ boxSizing: 'border-box', paddingRight: 16 }}
            // validate={required}
          />
        )
      }
      validate={errors => !(errors.sections && errors.sections[index])}
    >
      <CardContent>
        {image && (
          <Field name={`${fieldname}.image`} component={FormRemovableImage} />
        )}
        {list ? (
          <FieldArray
            name={`${fieldname}.list`}
            placeholder="Ange ett säljargument"
            component={renderTextList}
            validate={list =>
              list.every(item => item)
                ? undefined
                : 'Fyll i eller ta bort tomma fält'
            }
          />
        ) : (
          <Field
            name={`${fieldname}.text`}
            component={FormTextField}
            // helperText={requiredSection && sectionGuides[title].helperText}
            placeholder={
              sectionGuides[title] ? sectionGuides[title].helperText : undefined
            }
            disabled={irrelevant}
            title={title}
            rows={3}
            rowsMax={20}
            multiline
            fullWidth
            validate={(value, allValues) =>
              value ||
              (allValues.sections[index] &&
                allValues.sections[index].irrelevant)
                ? undefined
                : 'Obligatorisk'
            }
          />
        )}
        <FieldArray
          name={`${fieldname}.links`}
          component={renderLinkList}
          disabled={irrelevant}
        />
        {requiredSection && (
          <Grid container justify="space-between">
            <Grid item>
              <Field
                name={`${fieldname}.irrelevant`}
                label="Rubrik ej relevant"
                type="checkbox"
                component={FormCheckbox}
                style={{ paddingLeft: 4 }}
              />
            </Grid>
            <Grid item>
              <HelpPopover
                text={
                  sectionGuides[title] ? sectionGuides[title].helperText : ''
                }
              >
                {exampleProduct.sections && (
                  <ProductSectionContent
                    section={exampleProduct.sections[index]}
                  />
                )}
              </HelpPopover>
            </Grid>
          </Grid>
        )}
      </CardContent>

      <CardActions>
        <Field
          name={`${fieldname}.image`}
          storagePath={`products/${productUid}`}
          disabled={irrelevant}
          component={FormImageUpload}
        />
        {/* <SaveCollectionDocButton
          productUid={productUid}
          fieldname={fieldname}
          collection="sections"
        /> */}
        {!requiredSection && (
          <>
            <Button endIcon={<Delete />} variant="outlined" onClick={onRemove}>
              Ta bort
            </Button>
            <IconButton disabled={isFirstCustom} onClick={() => onMove(-1)}>
              <ArrowUp />
            </IconButton>
            <IconButton disabled={isLast} onClick={() => onMove(1)}>
              <ArrowDown />
            </IconButton>
          </>
        )}
      </CardActions>
    </ProductInputCard>
  )
}

const enhance = compose(
  withRouter,
  formValues(({ fieldname }) => ({
    title: `${fieldname}.title`,
    list: `${fieldname}.list`,
    image: `${fieldname}.image`,
    requiredSection: `${fieldname}.required`,
    irrelevant: `${fieldname}.irrelevant`,
  })),
  connect(state => ({
    exampleProduct: state.guide.exampleProduct,
  }))
)
export default enhance(ProductSectionInput)
