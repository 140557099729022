import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import ReactGA from 'react-ga'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import databaseManager from '../../database'
import { serverTimestamp } from '../../firebase'
import { createUpdateAndDeleteDocs } from './common'
import TherapeuticPartInput from './TherapeuticPartInput'
import { loadTherapeuticPart } from '../../actions'

class AddTherapeuticPart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
    }
  }

  componentDidMount() {
    const {
      partData,
      loadTherapeuticPart,
      match: {
        params: { therapeuticUid, partUid },
      },
    } = this.props
    ReactGA.set({ dimension3: therapeuticUid })
    ReactGA.event({
      category: 'Therapeutic',
      action: 'Part Edit Start',
      label: partUid,
    })
    if (partData) {
      this.originalQuiz = partData.quiz.slice()
      this.originalSections = partData.sections.slice()
    } else {
      loadTherapeuticPart(therapeuticUid, partUid)
    }
  }

  componentDidUpdate(prevProps) {
    const { partData } = this.props
    if (!prevProps.partData && partData) {
      this.originalQuiz = partData.quiz.slice()
      this.originalSections = partData.sections.slice()
    }
  }

  componentWillUnmount() {
    ReactGA.set({ dimension3: null })
  }

  savePart = formValues => {
    this.setState({ saving: true })
    const { quiz, sections } = formValues
    const { therapeuticUid, partUid } = this.props.match.params
    const partRef = databaseManager
      .queryTherapeutic(therapeuticUid)
      .collection('parts')
      .doc(partUid)
    const batch = databaseManager.batch()
    const sectionsRef = partRef.collection('sections')
    createUpdateAndDeleteDocs(
      batch,
      sectionsRef,
      sections,
      this.originalSections
    )
    const quizRef = partRef.collection('quiz')
    createUpdateAndDeleteDocs(batch, quizRef, quiz, this.originalQuiz)

    batch.update(partRef, { editDateTime: serverTimestamp() })
    batch.commit().then(() => {
      ReactGA.event({
        category: 'Therapetic',
        action: 'Part Edit Save',
        label: partUid,
      })
      this.props.loadTherapeuticPart(therapeuticUid, partUid)
      this.props.history.push(
        `/therapeutic/${therapeuticUid}/overview/part/${partUid}`
      )
    })
  }

  render() {
    const {
      handleSubmit,
      dirty,
      partData,
      match: {
        params: { therapeuticUid, partUid },
      },
    } = this.props
    const { saving } = this.state
    if (!partData) {
      return <LinearProgress />
    }
    const storagePath = `therapeutics/${therapeuticUid}/parts/${partUid}`
    return (
      <div>
        <Typography variant="h4" color="primary">
          Redigera del
        </Typography>
        <TherapeuticPartInput
          storagePath={storagePath}
          onSave={handleSubmit(this.savePart)}
          disabled={!dirty || saving}
        />
      </div>
    )
  }
}

const enhance = compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const { therapeuticUid, partUid } = ownProps.match.params
      const parts = state.therapeuticParts[therapeuticUid]
      const partsData = parts && parts.partsData
      const partData = partsData && partsData[partUid]
      return {
        initialValues: partData,
        partData,
      }
    },
    { loadTherapeuticPart }
  ),
  reduxForm({
    // a unique name for the form
    form: 'therapeuticPart',
    enableReinitialize: true,
  })
)

export default enhance(AddTherapeuticPart)
